<template>
  <div class="w-100">
    <div class="driver-expenses w-100 align-items-center" :style="cssProps">

      <div class="driver-expenses-options col-12">
        <label class="option basic-plan" :for="'payDriverExpenses[' + index + '][now]'">
          <input checked type="radio" @click="changeSelected('paynow')" :id="'payDriverExpenses[' + index + '][now]'" :name="'payDriverExpenses[' + index + '][now]'" value="paynow" v-model="paytype"  />
          <div class="plan-content">
            <img loading="lazy" src="@/assets/img/paynow.png" alt="Icone de pagamento no ato"/>
            <div class="plan-details">
              <span>+ {{ $store.state.tripMetas.driverExpenses | currency}}</span>
              <p>Quero pagar agora e não me preocupar com isso durante a viagem</p>
            </div>
          </div>
        </label>

        <label class="option complete-plan" :for="'payDriverExpenses[' + index + '][later]'">
          <input type="radio" @click="changeSelected('paylater')" :id="'payDriverExpenses[' + index + '][later]'" :name="'payDriverExpenses[' + index + '][later]'" value="paylater"  v-model="paytype"/>
          <div class="plan-content">
            <img loading="lazy" src="@/assets/img/paylater.png" alt="Icone de pagamento pendente"/>
            <div class="plan-details">
              <span>+ R$ 0,00</span>
              <p>Me responsabilizo por providenciar e pagar durante a viagem</p>
            </div>
          </div>
        </label>
      </div>

      <b-modal
      :style="cssProps"
      id="terms-of-use"
      ref="terms-of-use"
      title="Condições">
        <div v-html="this.$store.state.companyDetails.terms_expense_drive"></div>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end" :style="cssProps">
            <a href="" @click.prevent="agreeTerm()" class="btn btn-primary">
              Aceitar
            </a>
          </div>
        </template>    
      </b-modal>
    </div>

    <div class="col-12 mt-3">
      <label for="agree-driver-expenses" v-if="this.$store.state.tripDetails.driverExpenses.payDriverExpenses == 'paylater'">
        <input type="checkbox" @click="verifyIfDriverExpenses()" name="agree-driver-expenses" id="agree-driver-expenses" :ref="'agree_driver_expenses' + index" v-model="$store.state.agree_driver_expenses">
        Li e concordo com as <a href="javascript:void(0)" @click="showModal('terms-of-use')">condições para esta opção</a>
      </label>
      <div class=" text-left my-2" style="color:red;" v-if="error">
        Aceite os termos antes de prosseguir
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DriverExpenses',
  props: ['error', 'index'],
  data: function () {
    return {
      payDriverExpenses: 'paynow',
      paytype: 'paynow'
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.primaryColor
    }
  },
  watch: {
    'error': function (val) {
      if (val) {
        var test = 'agree_driver_expenses' + this.index
        // console.log(this.$refs)
        this.$refs[test].focus()
      }
    }
  },
  created () {
    this.$store.commit('SET_AGREE_DRIVER_EXPENSES', { value: false })

    this.$store.commit('SET_DRIVER_EXPENSES', {
      value: this.$store.state.tripDetails.driverExpenses.driverExpensesValue,
      payDriverExpenses: 'paylater',
    })
  },
  mounted () {
    this.changeSelected('paynow')
  },
  methods: {
    calculateDriveExpenses: function (value) {

      let origin = this.$store.state.origin.date
      let destination = this.$store.state.destination.date

      let start = this.moment(this.$store.state.origin.date, 'YYYY/MM/DD')
      let end = this.moment(this.$store.state.destination.date, 'YYYY/MM/DD')

      let driverExpensesValue = origin != destination ? Number(this.$store.state.companyDetails.driveExpensesDailyPrice) : 0

      let duration = Math.abs(this.moment.duration(start.diff(end)).asDays())

      duration = duration > 0 ? duration : 1

      return driverExpensesValue * duration
    },
    changeSelected: function (value) {
      this.payDriverExpenses = value
      if (value === 'paynow') {
        this.driverExpensesValue = this.$store.state.tripMetas.driverExpenses
      } else {
        this.driverExpensesValue = 0.00
      }

      this.$store.commit('SET_DRIVER_EXPENSES', {
        payDriverExpenses: this.payDriverExpenses,
        driverExpensesValue: this.driverExpensesValue
      })
      // (this.payDriverExpenses)
    },
    verifyIfDriverExpenses: function () {
      this.$store.commit('SET_AGREE_DRIVER_EXPENSES', { value: !this.$store.state.agree_driver_expenses })
      // if (!this.$store.state.agree_driver_expenses) {
      //   this.changeSelected('paynow')
      // }
    },
    agreeTerm: function(){
      this.$store.state.agree_driver_expenses = true
    },
    hideModal(id) {
      this.$refs[id].hide()
    },
    showModal: function (id) {
      this.$refs[id].show()
      // this.$swal({
      //     title: 'Termo de condições',
      //     html: this.$store.state.companyDetails.terms_expense_drive,
      //     confirmButtonText: 'Ok',
      //     showDenyButton: false,
      //     showConfirmButton: true,
      //     icon: 'info'
      //   })
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "../../assets/scss/components/driver-expenses";

  .btn-outline-primary {
    border-color: var(--default-color);
    color: var(--default-color) !important;
    font-size: 16px !important;
    border: 1px solid;
    border-radius: 4px;
    font-weight: 500 !important;
  }

  .btn-primary {
    border-color: var(--default-color);
    color: white !important;
    background: var(--default-color);
    font-size: 16px !important;
    border: 1px solid;
    font-weight: 500 !important;
    border-radius: 4px;
  }

  input:checked + span {
    color: var(--default-color)!important;
  }

  span {
    &:hover {
      cursor: pointer;
    }

  }

  .activeRadio {
    color: var(--default-color)!important;
  }
 
  .options {

    label {
      display: flex;
      align-items: center;
    }

    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;

      @media only screen and (max-width: 600px){
        flex-direction: column;
        align-items: inherit;
      }

      &.active {
        border-radius: 5px;
        background-color: #e8e8e8;
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      .text {
        text-align: left;
        text-transform: uppercase;
        font-size: 14px;
        flex: 0 0 70%;
        max-width: 70%;
        @media only screen and (max-width: 600px){
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      .mark-wrapper {
        flex: 0 0 30%;
        max-width: 30%;
        @media only screen and (max-width: 600px){
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      .mark {
        border-radius: 5px;
        display: block;
        padding: 8px 26px;
        width: 100%;
        background-color: transparent;
        cursor: pointer;
        text-transform: uppercase;
        transition: all .3s ease-in-out;
        text-align: center;
        color: #131313;
        background-color: #e8e8e8;
        font-weight: bold;

        &:hover,
        &.active {
          background-color: #293c4b;
          color: #fff;
        }
      }
    }
  }

  .agree-and-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;

    @media only screen and (max-width: 600px){
      flex-direction: column;
    }

    label {
      margin-bottom: 0;
      color: #757575!important;
    }

    .price-wrapper {
      text-align: right;

      .price {
        font-weight: bold;
        font-size: 29px;
        color: #131313;
        font-weight: 700;
        text-align: right;

        .value {
          font-size: 18px;
          margin-left: 3px;
          font-weight: 500;
        }
      }
    }
  }
</style>
