<template>
    <div id="vehicle_usage" class="preventCloseModal" :style="cssProps">
        <div class="fields">
            <div class="mb-2">
                <h3>Outros pontos de embarque ou desembarque</h3>
                <component 
                    placeholder="Rua, número, cidade"
                    :is="'Places'"
                    v-for="(point, index) in this.stops_inputs"
                    @send-message="setStopPoint"
                    :key="`point-${index}`"
                    :index="index"
                    classname="form-control input-feedback mb-2 stop-point-input"
                    @delete-data="deleteStopPoint"
                    :value="point.address"
                    :showDeleteButton="true"
                    name="inputs[]"
                >
                </component>
                <button id="addTripStopPoint" @click.prevent="addStopPoint" class="btn mt-3 mb-5 outline-btn btn-block preventCloseModal"><span>+</span> Adicionar outro ponto de embarque ou desembarque</button>
            </div>
            <div class="mb-2">
                <h3>Uso do veículo no destino</h3>
                <div class="row">
                    <b-col cols="12" v-for="(item,index) in $store.state.tripDetails.useVehicle" :key="'item' + index">
                    <use-vehicle-destination :info_data="item"  :index="index" @delete="removeUseAtDestination" />
                    </b-col>
                    <div class="col-md-12 text-left mb-2" style="color:red;" v-if="this.usage_destination_errors.empty_fields">
                        {{usage_destination_errors.empty_fields}}
                    </div>
                </div>

                <button id="addTripStopPoint" @click.prevent="addUseAtDestination" class="btn mt-3 mb-5 outline-btn btn-block preventCloseModal"><span>+</span> Adicionar outro ponto de embarque ou desembarque</button>
            </div>
            
        </div>
    </div>
</template>


<script>
import Places from '@/components/general/Places.vue'
import UseVehicleDestination from '@/components/trip-details/UseVehicleDestination.vue'

export default {
  name: 'SearchFormWrapper',
  props: ['context'],
  components: { Places, UseVehicleDestination },
  watch: {
    '$store.state.serviceType': function (val) {
      if (val === 'diaria' && this.$store.state.daily.quotes.length === 0) {
        this.$store.commit('SET_DAILY', {
          time: '00:00',
          addr_info: {
            address: ''
          },
          date: '',
          quote: 0
        })
      }
    }

  },
  data () {
    return {
      usage_destination_errors: {},
      stops_inputs: [],
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color,
      }
    }
  },
  mounted() {
    // this.stops_inputs.push(Places);
    // this.addUseAtDestination();
  },
  methods: {
    addStopPoint: function () {
      this.stops_inputs.push(Places)
    },
    setStopPoint: function (addressData, addressObject, index) {
      this.$store.commit('SET_STOPS', {
        index,
        addr_info: {
          address: addressObject.formatted_address,
          city: addressObject.address_components[2].long_name,
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng()
        }
      })
    },
    deleteStopPoint: function (prop, index) {
      this.stops_inputs.splice(index, 1)
      this.$store.commit('DELETE_STOPS', {
        index
      })
    },
    addUseAtDestination: function () {
      this.usage_destination_errors = {}

      if (this.$store.state.tripDetails.useVehicle.length > 0) {
        if (this.$store.state.tripDetails.useVehicle[this.$store.state.tripDetails.useVehicle.length - 1].date === '' ||
        this.$store.state.tripDetails.useVehicle[this.$store.state.tripDetails.useVehicle.length - 1].addr_info.address === '' ||
        this.$store.state.tripDetails.useVehicle[this.$store.state.tripDetails.useVehicle.length - 1].quote === '') {
          this.usage_destination_errors.empty_fields = 'Todos campos são obrigatório'
        }
      }

      if (Object.keys(this.usage_destination_errors).length === 0) {
        this.$store.commit('SET_USE_VEHICLE_INFO', {
          value: {
            time: '00:00',
            addr_info: {
              address: ''
            },
            date: '',
            quote: 0
          }
        })
      }
    },
    removeUseAtDestination: function (index) {
      this.$store.commit('DELETE_USE_VEHICLE_INFO', {
        index
      })
    }
  }
}
</script>

<style lang="scss">
#vehicle_usage {

    height: 90%;
    .fields {
      overflow: auto;
      height: 100%;
      padding: 20px;
    }

    .use-vehicle-in-destination {

        .vdp-datepicker  {
            input {
                height: 35px;
            }
        }
        .input-group-prepend {
            border-radius: 150px;
            overflow: hidden;
        }
        .form-control  {
            border: none!important;
            border-radius: 150px;
        }

       
        svg {
            font-size: 105%;
        }
        
    }

    input {
        border-radius: 50px!important;
    }

    .input-group {
        background: white;
    }
    h3 {
        font-size: 16px;
        text-align: left;
        margin-bottom: 20px;
        font-weight: 500;
        color: var(--default-color);
    }

    .outline-btn {
        border-color: var(--default-color);
        color: var(--default-color);
        background-color: transparent;
        font-size: 12px;
        height: 35px;
        font-weight: bold;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        margin-bottom: 0;
        margin-top: 1px;
        border-radius: 40px;
        &.primary {
            background-color: var(--default-color);
            border-color: var(--default-color);
            color: white;
        }
    }
}
</style>