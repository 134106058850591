<template>
  <b-form id="search-form" class="search-form default-bordered" :style="cssProps">
      <b-row>
          <b-col>
            <h3 class="mb-2 mt-sm-3">Observação</h3>
            <h5 class="text-left ">Serviço disponível apenas nos limites do(s) município(s):</h5>
            <div class="mb-4">{{garagens.join(', ')}} </div>
          </b-col>
      </b-row>
    <b-form-row class="row-budget daily" id="1" v-for="(daily, index) in $store.state.daily.quotes" :key="index">

    <b-form-row class="w-100">
      <b-form-group :class="{'col-lg-6 col-12':true, 'has-feedback': (daily.addr_info.address === '' || !isCityInOriginPoint(daily.addr_info.city)) && showErrors}">
          <b-input-group>
              <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"> <b-icon-geo-alt></b-icon-geo-alt></b-input-group-text>
              <div :class="{'form-control form-group p-0':true, }"  style="border:none;">
                <places
                  placeholder="Insira o endereço de origem"
                  @send-message="setAddress"
                  classname="form-control input-feedback"
                  :index="index"
                  :showDeleteButton="false"
                  :value="daily.addr_info.address"
                  :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor, borderRadius: '50px'}"
                  ></places>
              </div>
          </b-input-group>
      </b-form-group>

      <b-form-group :class="{'col-lg-5 col-12':true, 'has-feedback': daily.quote === 0 && showErrors}">
          <b-input-group>
            <b-input-group-text :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }" slot="prepend">
              <b-icon-hourglass></b-icon-hourglass>
            </b-input-group-text>
            <daily-usage-quotes-field  @change="setCommomProp($event, index, 'quote')" :value="$store.state.daily.quotes[index].quote" />
          </b-input-group>
      </b-form-group>
    </b-form-row>

      <b-form-row class="w-100">
        <b-form-group :class="{'col-lg-6 col-12':true, 'has-feedback': daily.date === '' && showErrors}">
            <b-input-group>
                <b-input-group-text  slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><b-icon-calendar3></b-icon-calendar3></b-input-group-text>
                <DatePicker
                    name="destinationDate"
                    ref="destinationDate"
                    class="form-control form-group"
                    placeholder="Data"
                    format="dd/MM/yyyy"
                    :disabled-dates="reservationStartDate"
                    @selected="setCommomProp($event, index, 'date')"
                    :value="$store.state.daily.quotes[index].date"
                    :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
                    :open-date="openDateOrigin()"
                >
                </DatePicker>

            </b-input-group>

        </b-form-group>

        <b-form-group class="col-lg-5 col-12" >
          <b-input-group>
                  <b-input-group-text :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><b-icon-clock></b-icon-clock></b-input-group-text>
                  <time-select-field
                      ref="destinationTime"
                      placeholder="Horário"
                      classes="form-control"
                      v-on:updateschedule="setCommomProp($event, index, 'time')"
                      :value="$store.state.daily.quotes[index].time"
                      :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
                  >
                  </time-select-field>
          </b-input-group>
        </b-form-group>

        <b-form-group class=" button-remove form-group" md="1" >
            <button class="btn btn-primary btn-block" @click.prevent="removeFieldDaily(index)"  :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor, marginTop: '-30px' }">
                  <b-icon-x-lg></b-icon-x-lg>
            </button>
        </b-form-group>

      </b-form-row>
    </b-form-row>

    <b-form-row>
      <div class="w-100">
          <button id="addUsage" @click.prevent="addFieldDaily"  :style="{ backgroundColor: formBackgroundColor, borderColor: primaryColor, color: primaryColor }"  class="w-100 btn btn-block">+ Adicionar diária</button>
      </div>
    </b-form-row>

    <!-- <b-form-row class="mb-5">
        <b-col>
            <h3>Descreva o roteiro pretendido</h3>
            <b-form-group :class="{'has-feedback': $store.state.daily.observations === '' && showErrors}">
                <b-form-textarea
                :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
                id="textarea"
                placeholder=""
                v-model="$store.state.daily.observations"
                rows="3"
                v-on:change="setObservations($event)"
                max-rows="6">
               </b-form-textarea>
            </b-form-group>

        </b-col>
    </b-form-row> -->

    <b-form-row class="mt-3">
        <div class="w-100"><h3>Selecione o veículo</h3></div>
    </b-form-row>
    <b-form-row>
      <b-form-group class="w-100">
        <b-input-group>
          <b-input-group-text :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }" slot="prepend"><img :src="this.busIcon" /></b-input-group-text>
          <vehicle-select :selectedVehicleType="$store.state.selectedVehicleType" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"/>
        </b-input-group>
      </b-form-group>
    </b-form-row>

    <b-form-row>
       <b-form-group class="w-100 btn-wrapper">
        <b-input-group>
          <button type="button" @click.prevent="getResults" class="btn btn-primary btn-block btn-search" :style="{ backgroundColor: primaryColor, borderColor: primaryColor }">Ver Orçamento</button>
        </b-input-group>
      </b-form-group>
    </b-form-row>

  </b-form>
</template>

<script>

import VehicleSelect from '@/components/form/VehicleSelect.vue'
import DatePicker from '@sum.cumo/vue-datepicker'
import TimeSelectField from '@/components/fields/TimeSelectField.vue'
import Places from '@/components/general/Places.vue'
import DailyUsageQuotesField from '@/components/fields/DailyUsageQuotesField.vue'
import utils from '@/components/mixins/utils'

export default {
  name: 'DailyForm',
  data () {
    return {
      reservationStartDate: {
        to: this.setReservationDate()
      },
      obj: [],
      front_end_errors: [],
      showErrors: false,
      busIcon: require('../../assets/icon/bus.svg')
    }
  },
  components: {
    VehicleSelect,
    DatePicker,
    TimeSelectField,
    Places,
    DailyUsageQuotesField
  },
  mixins: [
    utils
  ],
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.body_font_color
    },
    formFontColor () {
      return this.$store.state.companyDetails.whitelabel.form_font_color
    },
    formBackgroundColor () {
      return this.$store.state.companyDetails.whitelabel.form_background_color
    },
    garagens () {
      return this.$store.state.companyDetails.origin_points.filter(element => { return element.city != null }).map(element => { return element.city })
    }
  },
  methods: {
    addFieldDaily () {
      this.$store.commit('SET_DAILY', {
        time: '00:00',
        addr_info: {
          address: ''
        },
        date: '',
        quote: 0
      })
    },
    setReservationDate (date = null) {
      let result = date ? date : new Date()
      result.setDate(result.getDate() + Number(this.$store.state.companyDetails.reservationStartAt))
      return result;
    },
    removeFieldDaily (index) {
      this.$store.commit('DELETE_DAILY', { index })
    },
    getAddressField (data, field) {
      switch (field) {
        case 'state':
            let state = data.filter(item => item.types.includes("administrative_area_level_1"))
            return state.length > 0 ? state[0].long_name.replace('State of ', '') : ''
          break;
        case 'state_short':
            let state_short = data.filter(item => item.types.includes("administrative_area_level_1"))
            return state_short.length > 0 ? state_short[0].short_name : ''
          break;
        case 'route':
            let route = data.filter(item => item.types.includes("route"))
            return route.length > 0 ? route[0].long_name : ''
          break;
        case 'city':
          let city = data.filter(item => item.types.includes("administrative_area_level_2"))
            return city.length > 0 ? city[0].long_name : ''
        case 'district':
          let district = data.filter(item => item.types.includes("sublocality_level_1"))
            return district.length > 0 ? district[0].long_name : ''
        case 'postal_code':
          let postal_code = data.filter(item => item.types.includes("postal_code"))
            return postal_code.length > 0 ? postal_code[0].long_name : ''
        default:
          break;
      }
    },
    getAddressComponent (data) {
      return {
        route: this.getAddressField(data, 'route'),
        state: this.getAddressField(data, 'state'),
        state_short: this.getAddressField(data, 'state_short'),
        city: this.getAddressField(data, 'city'),
        district: this.getAddressField(data, 'district'),
        postal_code: this.getAddressField(data, 'postal_code')
      }
    },
    callErrorAlert(action, message) {
      this.showErrors = true;
      this.$swal({        
        text: message,
        showDenyButton: false,
        showConfirmButton: true,
        iconColor: action === 'error' ? '#e24d41' : this.$store.state.companyDetails.whitelabel.body_font_color,
        confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
        icon: action
      })
    },
    setAddress: function (addressData, addressObject, index) {
      var city = addressObject.address_components.filter(e => { return e.types.filter(t => t === 'administrative_area_level_2').length > 0 })[0].long_name

      let dailyData = {
        address: addressObject.formatted_address,
        address_components: this.getAddressComponent(addressObject.address_components),
        city: city,
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng()
      }
      this.$store.commit('SET_DAILY_PROP', {
        prop: 'addr_info',
        index: index,
        value: dailyData
      })
    },
    setCommomProp (value, index, prop) {
      if (prop === 'date') { value = this.moment(value).format('YYYY/MM/DD') }

      this.$store.commit('SET_DAILY_PROP', {
        prop: prop, value: value, index: index
      })
    },
    setObservations (event) {
      this.$store.commit('SET_DAILY_OBSERVATION', {
        prop: 'observations', value: event
      })
    },
    cleanTripStates(types) {
      types.forEach((type) => {
        switch (type) {
          case 'trip':
            this.$store.commit('CLEAN_TRIP_INFO');
            break;
          case 'daily':
            this.$store.commit('CLEAN_DAILY_INFO');
            break;
          case 'transfer':
            this.$store.commit('CLEAN_TRANSFER_INFO');
            break;
        }
      })
    },
    getResults () {
      this.showErrors = false
    
      this.$store.state.daily.quotes.forEach((quote) => {
        if(quote.addr_info.address === '') {
          this.callErrorAlert('error', 'O endereço da diária é obrigatório');
          return
        }

        if(quote.date === '') {
          this.callErrorAlert('error', 'A data da diária é obrigatório');
          return
        }

        if(quote.quote === 0) {
          this.callErrorAlert('error', 'Você precisa selecionar a franquia');
          return
        }

        if(!this.isCityInOriginPoint(quote.addr_info.city)) {
          this.callErrorAlert('error', 'Não atendemos nessa cidade');
          return
        }
      })
      this.cleanTripStates(['trip', 'transfer']);
      this.$store.commit('REMOVE_DAILY_UPDATED_PRICES', this.$store.state.daily.quotes);
      this.$store.commit('SET_TYPE', 'diaria')

      if (!this.showErrors) {
        if (this.$router.currentRoute.name === 'search-results') {
          this.$router.go()
        } else {
          this.$router.push('search-results')
        }
      }
    },
    getAddressError (index) {
      return this.front_end_errors.filter(element => element.index === index)
    },
    isCityInOriginPoint (city) {
      return this.garagens.indexOf(city) > -1
    }
  }
}
</script>

<style lang="scss">

select {
  border-right: 16px solid transparent!important;
}

</style>
