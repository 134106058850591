var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.error || _vm.emptyResult)?_c('div',{staticClass:"card text-left error-card"},[_c('div',{staticClass:"flex empty-result-content"},[_vm._m(0),_c('div',{staticClass:"col-lg-6 col-12"},[_c('not-found')],1)])]):(_vm.vehicles.length === 0)?_c('div',[_c('content-loader'),_c('content-loader')],1):_vm._e(),(this.$store.state.block_ui)?_c('div',{ref:"login",staticClass:"card checkout-login",staticStyle:{"background":"white"}},[_vm._v("\n    "+_vm._s(this.$store.state.companyDetails.block_results_non_logged_user)+"\n      "),_c('h4',[_vm._v("Você precisa acessar sua conta para ver os resultados")]),_c('div',{staticClass:"btn btn-outline"},[_c('login-modal',{style:({color: this.$store.state.companyDetails.whitelabel.body_font_color}),attrs:{"type":"result-list"},on:{"close":_vm.closeModal}})],1)]):_vm._e(),(_vm.vehicles)?_c('div',{style:(_vm.cssProps),attrs:{"id":"result-list"}},[(_vm.transfers.length > 0 && this.$store.state.transfersGoing.isChecked)?_c('div',{staticClass:"tab_content"},[(_vm.transfer_round_trip)?_c('b-tabs',{staticClass:"transfer-tab",attrs:{"content-class":"mt-2"},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('b-tab',{attrs:{"title":"Selecione a ida","active":_vm.$store.state.transferType=='origin'},on:{"click":function($event){return _vm.updateTransferTab('origin')}}},[(_vm.vehicles.origin.length > 0)?_c('div',{staticClass:"wrapper"},_vm._l((_vm.vehicles.origin),function(vehicle,index){return _c('div',{key:vehicle.id,staticClass:"mb-4 w-100",attrs:{"data-id":vehicle.id}},[_c('search-card-item',{attrs:{"index":index,"vehicle":vehicle,"vehicles":_vm.vehicles,"extra":{
                    transfer: _vm.transfers[0],
                    transfer_round_trip: _vm.transfer_round_trip,
                    trip_info: _vm.tripInfo,
                    redirect: false,
                    showDetails: true,
                    daily: _vm.daily,
                    transfer_state: 'origin',
                    origin_observation: ''
                  }}})],1)}),0):_c('b-card',{staticClass:"wrapper"},[_vm._v("\n                Não foram encontrados veículos nesta data\n              ")])],1),_c('b-tab',{staticClass:"transfer-tab",attrs:{"disabled":!_vm.$store.state.cart.cartData.items.length > 0,"title":"Selecione a volta","active":_vm.$store.state.transferType=='destination'},on:{"click":function($event){return _vm.updateTransferTab('destination')}}},[(_vm.vehicles.destination.length > 0)?_c('div',{staticClass:"wrapper"},_vm._l((_vm.vehicles.destination),function(vehicle,index){return _c('div',{key:vehicle.id,staticClass:"mb-4 w-100",attrs:{"data-id":vehicle.id}},[_c('search-card-item',{attrs:{"index":index,"vehicle":vehicle,"vehicles":_vm.vehicles,"extra":{
                    transfer: _vm.transfer_round_trip,
                    transfer_round_trip: _vm.transfer_round_trip,
                    trip_info: _vm.tripInfo,
                    redirect: false,
                    showDetails: true,
                    daily: _vm.daily,
                    transfer_state: 'destination',
                    destination_observation: ''
                  }}})],1)}),0):_c('b-card',{staticClass:"wrapper"},[_vm._v("\n                Não foram encontrados veículos nesta data\n              ")])],1)],1):_c('div',[_c('div',{staticClass:"wrapper"},_vm._l((_vm.vehicles.origin),function(vehicle,index){return _c('div',{key:vehicle.id,staticClass:"mb-4 w-100",attrs:{"data-id":vehicle.id}},[_c('search-card-item',{attrs:{"index":index,"vehicle":vehicle,"vehicles":_vm.vehicles,"extra":{
                    transfer: _vm.transfers[0],
                    transfer_round_trip: _vm.transfer_round_trip,
                    trip_info: _vm.tripInfo,
                    redirect: false,
                    showDetails: true,
                    daily: _vm.daily
                  }}})],1)}),0)])],1):_vm._l((_vm.vehicles),function(vehicle,index){return _c('div',{key:vehicle.id,staticClass:"mb-4 w-100",attrs:{"data-id":vehicle.id}},[_c('search-card-item',{attrs:{"index":index,"vehicle":vehicle,"vehicles":_vm.vehicles,"extra":{
          transfer: _vm.transfers[0],
          transfer_round_trip: _vm.transfer_round_trip,
          trip_info: _vm.tripInfo,
          showDetails: true,
          daily: _vm.daily,
          transfer_state: 'onlyOrigin'
        }}})],1)})],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 col-12 error-text"},[_vm._v("\n      Parece que nenhum veículo foi encontrado nesta data, "),_c('b',[_vm._v("tente refazer a busca")]),_vm._v(".\n    ")])}]

export { render, staticRenderFns }