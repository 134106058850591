<template>
    <div id="trip-type" :style="cssProps">
        <div class="justify-content-center align-items-center d-none d-lg-flex">
            <div class="wrapper d-flex">
                <button :class="$store.state.tripType == 'roundtrip' ? 'active' : ''" @click.prevent="updateTripType('roundtrip')">Ida e volta</button>
                <button :class="$store.state.tripType == 'onewaytrip' ? 'active' : ''" @click.prevent="updateTripType('onewaytrip')">Somente ida</button>
                <button :class="$store.state.tripType == 'hourtrip' ? 'active' : ''" @click.prevent="updateTripType('hourtrip')">Por hora</button>
                <button :class="$store.state.tripType == 'customtrip' ? 'active' : ''" @click.prevent="updateTripType('customtrip')">Roteiro personalizado</button>
            </div>
        </div>

        <div class="d-flex w-100 flex-column d-lg-none">
            <div class="col-12 justify-content-start align-items-start d-flex flex-column pl-0">
                <p>Selecione o tipo de viagem</p>
                <div class="place-field w-100">
                    <select :value="$store.state.tripType" v-model="$store.state.tripType" @change="updateTripType">
                        <option value="onewaytrip">Ida</option>
                        <option value="roundtrip">Ida e volta</option>
                        <option value="hourtrip">Por hora</option>
                        <option value="customtrip">Roteiro personalizado</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'TripType',
  props: {
    updateStep: {
      type: Function,
      required: false
    }
  },

  data () {
    return {

    }
  },

  mounted () {
    if (this.$store.state.tripType === undefined || this.$store.state.tripType === '') {
      this.$store.commit('UPDATE_TRIP_TYPE', 'roundtrip')
      this.updateStep()
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  },
  methods: {
    updateTripType (type) {
      if (typeof (type) === 'object') {
        type = type.target.value
      }
      if (type === 'customtrip') {
        this.$store.commit('SET_CUSTOM_ROUTER_STEP', 0)
        this.updateStep(0)
      }

      if (type != 'hourtrip') {
        this.$store.commit('SIMPLE_TRIP_PROP', { prop: 'franchise', value: '' })
      }
      if (type != 'roundtrip') {
        this.$store.commit('SIMPLE_ROUND_TRIP_PROP', { prop: 'date', value: '' })
        this.$store.commit('SIMPLE_ROUND_TRIP_PROP', { prop: 'time', value: '00:00' })
        this.$store.commit('SIMPLE_TRIP_PROP', { prop: 'is_round_trip', value: false })
      }
      if (type == 'roundtrip') {
        this.$store.commit('SIMPLE_TRIP_PROP', { prop: 'is_round_trip', value: true })
      }
      this.$store.commit('UPDATE_TRIP_TYPE', type)
      // this.updateStep()
    }

  }
}
</script>

<style lang="scss" scoped>
    #trip-type {
        @media (max-width: 1024px) {
            width: 100%;
        }
        button {
            color: #696969;
            border: none;
            margin-right: 1px;
            border-radius: 0;
            background: #f2f2f3;
            padding: 5px 20px;
            font-size: 14px;
            position: relative;
            overflow: hidden;
            padding-right: 40px;
            padding-left: 40px;

            &:first-child {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }

            &:last-child {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }

            &:before {
                content: '';
                width: 0px;
                height: 2px;
                bottom: 0;
                left: 45%;
                background: transparent;
                z-index: 99;
                display: flex;
                position: absolute;
                transition: .5s ease-in-out;
            }

            &:hover {
                &:before {
                    width: 100%;
                    height: 2px;
                    border-radius: 150px;
                    bottom: 0;
                    left: 0;
                    background: var(--default-color);
                }
            }

            &.active {
                background: var(--default-color);
                color: #ffffff;
            }
        }

        select {
            width: 100%;
            border: none;
        }
    }
</style>
