<template>
  <footer class="no-print pt-5" :style="cssProps">
    <a v-if="company.phone && !$store.state.companyDetails.whitelabel.chats.tawkto && !$store.state.companyDetails.whitelabel.chats.zendesk" :href="'https://'+ this.isMobile() +'.whatsapp.com/send?phone=' +this.slugifyNumber($store.state.companyDetails.whitelabel.chats.whatsapp)" class="whatsapp-button" target="_blank" style="position: fixed;  right: 15px; bottom: 15px;">
      <img src="https://i.ibb.co/VgSspjY/whatsapp-button.png" alt="botão whatsapp">
    </a>
    <div class="footer_bg" :style="{backgroundImage: `url(${require('../../assets/img/footer_test.jpg')})`}"></div>
      <!-- <newsletter-form /> -->

      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="company-name"><strong>{{company.fantasyName}}</strong></p>
          </div>
        </div>
        <div class="row align-items-start info">
          <div class="col-lg-5 col-12 pr-5">
            <p>{{company.address}}</p>
            <p>{{this.getCompanyDocument(company)}}</p>
          </div>
          <div class="col-lg-4 col-12 contact">
            <p> <b-icon-telephone></b-icon-telephone>{{company.telephone}}</p>
            <p> <b-icon-whatsapp></b-icon-whatsapp>{{company.phone}}</p>
            <p> <b-icon-envelope></b-icon-envelope><a :href="'mailto:' +company.email">{{company.email}}</a></p>

          </div>
          <div class="col-lg-3 col-12 social">
            <div class="row">
              <a target="_new" v-if="company.whitelabel.social_facebook" :href="company.whitelabel.social_facebook"><b-icon-facebook></b-icon-facebook></a>
              <a target="_new" v-if="company.whitelabel.social_instagram" :href="company.whitelabel.social_instagram"><b-icon-instagram></b-icon-instagram></a>
              <a target="_new" v-if="company.whitelabel.social_linkedin" :href="company.whitelabel.social_linkedin"><b-icon-linkedin></b-icon-linkedin></a>
              <a target="_new" v-if="company.whitelabel.social_twitter" :href="company.whitelabel.social_twitter"><b-icon-twitter></b-icon-twitter></a>
              <a target="_new" v-if="company.whitelabel.social_youtube" :href="company.whitelabel.social_youtube"><b-icon-youtube></b-icon-youtube></a>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="row row-company-info">
        <div class="container">
          <div class="col-12 flex-column col-company-info text-center">
            <p>{{ identification }}</p>
            <p>{{ address }}</p>
            <p>{{ phone }} {{ phone && email ? '|' : ''}} {{ email }}</p>
          </div>
        </div> -->
        <!-- <div class="col-12 col-lg-4">
          <payment-methods />
        </div>
      </div> -->
      <!-- <div class="row whitelabel pt-4 pb-2">
        <div class="container">
          <div class="col-12 text-center">
              <a class="nav-link" href="https://fretatech.com.br" target="_blank">
                <span class="d-block powered-by">Powered by</span>
                <img src="@/assets/img/fretatech-logo-color.png" alt="Fretatech Logo" class="fretatech-logo">
              </a>
            </div>
        </div>
      </div> -->
  </footer>
</template>

<script>
// import PaymentMethods from '@/components/general/PaymentMethods.vue'

export default {
  name: 'Footer',
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    company () {
      return this.$store.state.companyDetails
    },
    logo () {
      return this.$store.state.companyDetails.logo
    },
    phone () {
      return this.$store.state.companyDetails.phone || ''
    },
    email () {
      return this.$store.state.companyDetails.email || ''
    },
    address () {
      return this.$store.state.companyDetails.address || ''
    },
    identification () {
      return this.$store.state.companyDetails.identification || ''
    },
    backgroundColor () {
      return this.$store.state.companyDetails.whitelabel.header_background_color || ''
    }
  },
  methods: {
    getCompanyDocument (company) {
      return company.identification.replace(company.name + ' - ', 'CNPJ: ')
    },
    slugifyNumber (phone) {
      if (!phone) {
        return ''
      }
      let result = phone.replace(/\s/g, '')
      result = result.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')

      return result
    },
    isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return 'api'
      } else {
        return 'web'
      }
    }
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/components/_footer.scss";

  footer {
    background-color: var(--default-color);
    padding: 10px 0;
    border-top: 4px solid #c7c7c7;
    position: relative;
    padding-bottom: 60px;

    @media (max-width: 980px){
      padding-top: 40px!important;
    }

    .whatsapp-button {
      transition: .5s ease-in-out;
      z-index: 99999;
      &:hover {
        transform: scale(1.1);
        opacity: 1;
      }
    }

    .footer_bg {
      background-image: url(/img/footer_test.6089b0a3.jpg);
      width: 100%;
      height: 320px;
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center;
      opacity: .2;

      @media(max-width: 980px) {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
         height: 500px;
      }
    }

    .social {

      @media(max-width: 980px) {
        margin-left: 15px;
      }

      a {
        font-size: 26px;
        margin-right: 10px;
      }
    }

    svg {
      margin-right: 5px;
    }

    a {
      color: white;

      &:hover {
        opacity: .6;
        color: white!important;
        text-decoration: none;
      }
    }

    p {
      margin-bottom: 10px;
    }
    .company-name {
      font-size: 18px
    }
  }

</style>
