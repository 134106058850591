import axios from 'axios'

export default {
  namespaced: true,

  state: {
    userData: null,
    tmpUserData: {
      name: null,
      email: null,
      phone: null
    }
  },

  getters: {
    user: state => state.userData
  },

  mutations: {
    setUserData (state, user) {
      state.userData = user
    },
    tmpUserData (state, data) {
      state.tmpUserData[data.key] = data.value

      if (state.userData) {
        state.tmpUserData.name = state.userData.name
        state.tmpUserData.email = state.userData.email
        state.tmpUserData.phone = state.userData.phone
      }
    }
  },

  actions: {
    getUserData ({ commit }) {
      axios
        .get(process.env.API_URL + 'user')
        .then(response => {
          commit('setUserData', response.data)
        })
        .catch(() => {
          localStorage.removeItem('authToken')
        })
    },
    sendLoginRequest ({ commit }, data) {
      commit('setErrors', {}, { root: true })
      return axios
        .post(process.env.API_URL + 'login', data)
        .then(response => {
          commit('setUserData', response.data.customer)
          localStorage.setItem('authToken', response.data.access_token)

          return response
        })
        .catch(response => {

        })
    },
    sendRegisterRequest ({ commit }, data) {
      commit('setErrors', {}, { root: true })
      return axios
        .post(process.env.API_URL + 'register', data)
        .then(response => {
          if (response !== undefined) {
            commit('setUserData', response.data.customer)
            localStorage.setItem('authToken', response.data.access_token)

            return response
          }
        })
    },
    async convertLeadToCustomer ({ commit }, data) {
      commit('setErrors', {}, { root: true })
      return axios
        .post(process.env.API_URL + 'lead/register', data)
        .then(response => {
          if (response !== undefined) {
            if (response.data.should_login) {
              commit('setUserData', response.data.customer)
              localStorage.setItem('authToken', response.data.access_token)
            }
          }

          return response
        })
    },
    sendLogoutRequest ({ commit }, data) {
      axios.post(process.env.API_URL + 'logout', data).then((response) => {
        window.location = '/'
        commit('setUserData', null)
        localStorage.removeItem('authToken')
      })
    },
    sendVerifyResendRequest () {
      return axios.get(process.env.API_URL + 'email/resend')
    },
    sendVerifyRequest ({ dispatch }, hash) {
      return axios
        .get(process.env.API_URL + 'email/verify/' + hash)
        .then(() => {
          dispatch('getUserData')
        })
    }
  }
}
