<template>
  <div v-if="isLoading" class="spinner-wrapper bg-white">
    <lottie-animation
        :width=300
        :height=300
        path="animation/freta-loading.json"
      ></lottie-animation>
  </div>
  <div v-else :style="cssProps" class="no-print" id="save-print">
    <!-- <a v-if="this.quoted" class="print-budget-btn" @click="print()">Imprimir Orçamento</a> -->
    <div v-if="this.vehicle.totalAvailable > 0">
      <a class="print-budget-btn" @click="saveAndPrintVehicle(true)">Salvar orçamento</a>
    </div>
    <div v-else>
      <p class="not-available-vehicle">Veículo sem disponibilidade nesta data</p>
      <a class="print-budget-btn d-flex justify-content-center" @click="saveAndPrintVehicle(false)">Solicitar disponibilidade</a>
    </div>  

    <b-modal
    ref="save-or-print"
    :title="!successSearch ? 'O que você deseja fazer?' : ''"
    content-class=""
    v-model="show"
    @hide="closeModal"
    hide-footer
    >
      <form class="login100-form validate-form" v-on:submit.prevent="login" v-if="!this.user && !showRegisterForm">
        <h4>Sou cadastrado</h4>

        <b-alert v-model="hasError" variant="danger" dismissible>
          {{ errorMessage }}
        </b-alert>

        <div :class="{'form-group':true, 'has-feedback': errors.email}">
            <div class="wrap-input-card validate-input" data-validate="Enter username">
                <input
                    class="input-card form-control input-form"
                    type="text"
                    name="username"
                    placeholder="Login"
                    v-model="credentials.email"
                />
            </div>
            <div class="invalid-feedback " v-if="errors.email">
            {{ errors.email[0] }}
            </div>
        </div>
        <div :class="{'form-group':true, 'has-feedback': errors.password}">
            <div
            class="wrap-input-card validate-input"
            data-validate="Enter password"
            >
            <input
                class="input-card form-control input-form"
                type="password"
                name="pass"
                placeholder="Senha"
                v-model="credentials.password"
            />
            </div>

            <div class="invalid-feedback " v-if="errors.password">
            {{ errors.password[0] }}
            </div>
        </div>
        <div class="login-actions">
            <div class="container-login100-form-btn d-flex justify-content-between" style="gap: 2rem;">
              <button class="btn btn-block btn-fretatech" :style="{background: primaryColor, border: primaryColor}">ENTRAR</button>
              <button class="btn btn-block btn-fretatech m-0" :style="{background: primaryColor, border: primaryColor}" @click.prevent="registerForm()">CADASTRAR</button>
            </div>

            <div class="pt-3 text-center" >
              <router-link to="/recuperar-senha">ESQUECEU SUA SENHA? CLIQUE AQUI! </router-link>
            </div>
        </div>
      </form>
      <div v-else-if="showRegisterForm">
        <form class="login100-form validate-form" v-on:submit.prevent="register">

          <div :class="{'form-group':true, 'has-feedback': errors.name}">
            <div class="wrap-input100 validate-input" data-validate="Nome é obrigatório">
                <input
                class="form-control"
                type="text"
                name="name"
                v-model="userForm.name"
                placeholder="Nome"
                />

            </div>

            <div class="invalid-feedback " v-if="errors.name">
                {{ errors.name[0] }}
            </div>
          </div>

          <div :class="{'form-group':true, 'has-feedback': errors.email}">
            <div
                class="wrap-input100 validate-input"
                data-validate="E-mail é obrigatório"
            >
                <input
                class="form-control"
                type="email"
                name="email"
                v-model="userForm.email"
                placeholder="E-mail"
                />

            </div>

            <div class="invalid-feedback " v-if="errors.email">
              {{ errors.email[0] }}
            </div>
          </div>

          <div :class="{'form-group':true, 'has-feedback': errors.password}">
            <div
                class="wrap-input100 validate-input"
                data-validate="Senha é obrigatório"
            >
                <input
                class="form-control"
                type="password"
                v-model="userForm.password"
                name="pass"
                placeholder="Senha"
                />

            </div>

            <div class="invalid-feedback " v-if="errors.password">
                {{ errors.password[0] }}
            </div>
          </div>

          <div class="login-actions">
            <div class="container-login100-form-btn">
              <button class="btn " :style="{background: primaryColor, border: primaryColor}">Enviar</button>
            </div>
          </div>

        </form>
      </div>
      <div v-else-if="successSearch" id="save-success" :style="cssProps">
        <h3>Pesquisa salva com sucesso.</h3>
        <div>Lembre-se que os valores estão sujeito a mudanças.</div>
        <footer>
          <router-link to="/minha-conta#orcamentos" tag="li"  class="btn-default"><a>Ver minhas pesquisas</a></router-link>
        </footer>
      </div>
      <div v-else class="actions-wrapper row justify-content-between px-3">
        <div class="col-6">
          <button class="btn w-100" :style="{ background: 'transparent', color: primaryColor, border: '1px solid', borderColor: primaryColor }" @click.prevent="save()">Salvar</button>
        </div>
        <div class="col-6">
          <button class="btn w-100" :style="{ background: 'transparent', color: primaryColor, border: '1px solid', borderColor: primaryColor }" @click="print()">Imprimir</button>
        </div>
      </div>

      <template #modal-footer="{ ok }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <button class="btn w-100" @click="ok()" :style="{ background: primaryColor, color: 'white' }">Fechar</button>
    </template>

    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import FileSaver from 'file-saver'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: 'PrintOrSaveModal',
  props: ['vehicle', 'setSelectedVehicle', 'index'],
  data () {
    return {
      isLoading: false,
      show: false,
      showRegisterForm: false,
      successSearch: false,
      hasError: false,
      quoted: false,
      paymentHighestDate: false,
      paymentHighestDateFormated: false,
      bank_slip_installments: [],
      emailDate: false, 
      actualDate: false,
      startDate: false,
      endDate: false,
      differenceDays: 0,
      errorMessage: '',
      credentials: {
        email: '',
        password: ''
      },
      userForm: {
        name: '',
        email: '',
        password: '',
        status: 1
      }
    }
  },
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters(['errors']),
    ...mapGetters('auth', ['user']),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.body_font_color
    }
  },
  mounted () {
    // this.$store.commit('quote/clear', {})
  },
  methods: {
    ...mapActions('auth', ['sendLoginRequest']),
    ...mapActions('auth', ['sendRegisterRequest']),
    login () {
      this.sendLoginRequest(this.credentials).then((response) => {
        if(response.data.status_code !== 200) {
          this.hasError = true;
          this.errorMessage = response.data.message
        }
      })
    },
    saveAndPrintVehicle ( stock = true ) {
      this.$store.commit('SET_LOADING_STATE', {value: true, text: 'Salvando pesquisa...'})
      if (!this.user) {
        this.$store.commit('SET_LOADING_STATE', {value: false})
          this.$swal({
            title: 'Erro!',
            timer: 2500,
            text: stock ? 'É necessário está logado para salvar orçamentos' : 'É necessário está logado para salvar disponibilidade',
            showDenyButton: false,
            showConfirmButton: false,
            icon: 'error'
          })
          return false
        }
      this.setSelectedVehicle(this.index, 'quote')
      this.save(stock)
    },
    roundTripInfo (){
      let isRoundTrip = this.$store.state.serviceType == 'viagem' ? this.$store.state.is_round_trip : ''

      return isRoundTrip
    },
    getVehicleType (type) {
      switch (type) {
        case 'onibus':
          return 'bus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro'
        case 'carro' :
          return 'car'
        default:
          return 'bus'
      }
    },
    register () {
      this.sendRegisterRequest(this.userForm).then((response) => {
        if (response !== null && response.status === 200) {
          this.showRegisterForm = false
        }
      })
    },
    registerForm () {
      this.showRegisterForm = true
    },
    closeModal () {
      this.$emit('close', false)
    },
    showModal () {
      this.show = !this.show
    },
    slugify (str) {
      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()
      var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
      var to = 'aaaaeeeeiiiioooouuuunc------'
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')

      return str
    },
    usageVehicleType(type) {
      switch (type) {
        case 'onibus':
          return 'onibus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro_onibus'
        case 'carro' :
          return 'carro'
        default:
          return 'onibus'
      }
    },
    sumVehicleUsageTotals(){

      let totals = 0;
      if(this.$store.state.tripDetails.useVehicle){ 
        let $scope = this;
        this.$store.state.tripDetails.useVehicle.map(function(quote){
          let vehicleType = $scope.usageVehicleType($scope.vehicle.type);
          totals += $scope.$store.state.companyDetails.vehicleUsageQuotes[quote.selectedIndex][vehicleType] * $scope.vehicle.factor_usage;
        })
      }

      return totals;
    },
    generatePayload () {
      let total = 0;  
      let infos = this.quoteInfos()
      let tripOrigin = {}
      let tripDestination = {}
      let isRoundTrip = ''
      let prices = []
      let $scope = this
      this.$store.state.quote.quote.items.forEach(function(item , index){
        if(item.type == 'diaria'){
          item.selectedVehicle.dailyDetails = infos
          prices[index] = parseFloat(item.daily.quote[$scope.getVehicleType(item.selectedVehicle.type)])
        }else if(item.type == 'transfer'){
          item.selectedVehicle.extra.transferDetails = infos
          prices[index] = parseFloat(item.transfer[$scope.getVehicleType(item.selectedVehicle.type) + '_price'])
        }else{
          item.selectedVehicle.tripInfos = infos
          isRoundTrip = item.destination.date != '' ? true : false
          prices[index] = item.selectedVehicle.priceCents
          tripOrigin[index] = item.origin
          tripDestination[index] = item.destination
        }
      })
      let payload = {
        items: [
            {
              selectedVehicle: this.$store.state.selectedVehicle,
              type: this.$store.state.serviceType,
              origin: this.$store.state.serviceType == 'viagem' ? tripOrigin : this.$store.state.origin,
              destination: (this.$store.state.serviceType == 'viagem') ? tripDestination : (this.$store.state.serviceType == 'diaria') ?  this.$store.state.daily.quotes[0].addr_info.address: this.$store.state.destination ,
              tripDetails: this.$store.state.tripDetails,
              is_round_trip: isRoundTrip
            }
          ],
          quoteExpiryDate: this.$store.state.companyDetails.quote_valid_days,
          user_id: this.$store.state.auth.userData.id,
          quoteDate: this.moment().format('DD/MM/YYYY'),
          companyDetails: {
            logo: this.$store.state.companyDetails.logo,
            phone: this.$store.state.companyDetails.phone,
            email: this.$store.state.hasOwnProperty.email,
            address: this.$store.state.companyDetails.address,
            identification: this.$store.state.companyDetails.identification,
            whatsapp: 'api.whatsapp.com/send?phone=' + this.$store.state.companyDetails.cellphone + '&text=' + encodeURI('Olá! Gostaria de fazer um orçamento.')
          },
          paymentDetails: {
            two_times_bankslip_upfront_payment : this.$store.state.companyDetails.two_times_bankslip_upfront_payment,
            paymentHighestDate : this.paymentHighestDateFormated,
            differenceDays: this.differenceDays,
            installments : Object.keys(this.bank_slip_installments).length
          }
          
      }
      let vehicle = this.$store.state.selectedVehicle
      switch (this.$store.state.serviceType) {
        case 'transfer':
          this.$store.state.quote.quote.items.forEach(function(item , index){
              prices[index] = parseFloat(item.transfer[$scope.getVehicleType(item.selectedVehicle.type) + '_price'])
          })
          for(var i =0; i<prices.length; i++){
            total += prices[i];
          }
          payload.items[0]['transfer'] = vehicle.extra.transfer
          break
        case 'diaria':
          this.$store.state.daily.quotes.forEach((q , index) => {
            prices[index] = parseFloat(q.quote[this.getVehicleType(this.vehicle.type)])
          })
          for(var i =0; i<prices.length; i++){
            total += prices[i];
          }
          break
        default:
          let subtotal = 0
          this.$store.state.quote.quote.items.forEach((item , index) => {
            prices[index] = parseFloat(item.selectedVehicle.priceCents)
          })
          for(var i =0; i<prices.length; i++){
            subtotal += prices[i] + parseFloat(this.$store.state.tripDetails.driverExpenses.driverExpensesValue)
          }
          total = subtotal + this.sumVehicleUsageTotals()
          payload.items[0]['selectedVehicle'] = this.vehicle
          break
     }
      payload.items[0]['total'] = total
      payload['total'] = total
      payload['subtotal'] = total

      return payload
    },
    getTripStartDate() {
      let serviceType = this.$store.state.serviceType;
      switch (serviceType) {
        case 'transfer':
           return this.$store.state.tripInfo.transfer_date
          break;
        case 'diaria':
          return this.$store.state.daily.quotes[0].date
          break;
      
        default:
          return this.$store.state.origin.date
          break;
      }
    },
    quoteInfos(){
      let data = {}
      let $scope = this
      this.$store.state.quote.quote.items.forEach(function(item , i){
        if(item.type == 'diaria'){
          data[i] = {
            address: item.daily.addr_info.address,
            franchise_km: item.daily.quote.franchise_km,
            daily_hours: item.daily.quote.daily_hours,
            daily_date: item.daily.date,
            daily_time: item.daily.time,
            total: parseFloat(item.daily.quote[$scope.getVehicleType(item.selectedVehicle.type)])
          }
        }else if(item.type == 'transfer'){
          data[i] = {
              transfer_state: item.transferDetails.transfer_state,
              transfer_date: item.transferDetails.transfer_date,
              transfer_time: item.transferDetails.transfer_time,
              transfer_round_date: item.transferDetails.transfer_round_date,
              transfer_round_time: item.transferDetails.transfer_round_time,
          }
        }else{
          data[i] = {
            origin: item.origin,
            destination: item.destination
          }
        }
      })
      return data
    },
    vehicleTitle(){
      let title = this.$store.state.serviceType != 'transfer' ? this.vehicle.title : this.$store.state.selectedVehicle.title
      return title
    },
    dayBeforeTrip () {
      let actualDate = this.moment()
      let two_times_bankslip_due_days = this.$store.state.companyDetails.two_times_bankslip_due_days;
      let tripStartDate = this.getTripStartDate();
      let finalDate = this.moment(tripStartDate);
      let daysBeforeTrip = finalDate.diff(actualDate, 'days');

      this.differenceDays = daysBeforeTrip;
      this.paymentHighestDate = finalDate.subtract(two_times_bankslip_due_days, "days");
      this.paymentHighestDateFormated = finalDate.format('DD/MM/YYYY');
    },
    async getBankSlipInstallments(data) {
      await axios
        .post(process.env.API_URL + "cart/bank-slip/installments", data)
        .then((response) => {
          this.bank_slip_installments = response.data;
        })
        .catch(() => {});
    },
    async print () {
      this.$store.commit('SET_LOADING_STATE', {value: true})
      this.dayBeforeTrip()
      await this.getBankSlipInstallments({
        origin_date: this.paymentHighestDate.format('YYYY/MM/DD'),
        total: this.$store.state.quote.quote.total,
        items: this.$store.state.quote.quote.items,
      });
      let payload = this.generatePayload()
      let vehicleTitle = this.vehicleTitle()
      axios({
        url: process.env.API_URL + 'quote-pdf', // download url
        method: 'get',
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          mode: 'no-cors'
        },
        params: payload
      })
        .then(response => {
          var d = new Date()
          let hours = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear() + ' ' +
          d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
          FileSaver.saveAs(response.data, this.slugify(vehicleTitle) + '-' + this.slugify(hours))
          // this.closeModal()
          this.$store.commit('SET_LOADING_STATE', { value: false })
          this.$swal({
              title: 'Sucesso!',
              timer: 2500,
              text: 'Seu orçamento foi gerado',
              showDenyButton: false,
              showConfirmButton: false,
              iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
              icon: 'success'
            })
        })
    },
    dailyItems(){
      let data = {}
      if(this.$store.state.serviceType == 'diaria'){
        this.$store.state.daily.quotes.forEach((quote , index) => {
          data[index] = {
            total: parseFloat(this.$store.state.daily.quotes[0].quote[this.getVehicleType(this.$store.state.selectedVehicle.type)]) * this.$store.state.daily.quotes.length,
            daily: quote,
            type: this.$store.state.serviceType,
            selectedVehicle: this.$store.state.selectedVehicle
          }
        })
      }
      return data
    },
    getQuoteData(){
      let quoteItems = ''
      if(this.$store.state.serviceType == 'diaria'){
        quoteItems = this.dailyItems()
      }else{
        quoteItems = this.$store.state.quote.quote.items.filter(function (el) {
          return el != null
        })
      }
      
      this.$store.state.quote.quote.items = quoteItems

      let quoteData = {
        customer: {
          name: this.$store.state.auth.userData.name,
          company: this.$store.state.auth.userData.company,
          document: this.$store.state.auth.userData.cnpj_cpf,
          email: this.$store.state.auth.userData.email
        },
        customer_id: this.$store.state.auth.userData.id,
        ...this.$store.state.quote.quote
      }

      return quoteData;
    },
    save (stock = true) {
     
      let quoteData = {
        availability: stock,
        customer: {
          name: this.$store.state.auth.userData.name,
          company: this.$store.state.auth.userData.company,
          document: this.$store.state.auth.userData.cnpj_cpf,
          email: this.$store.state.auth.userData.email
        },
        customer_id: this.$store.state.auth.userData.id,
        ...this.$store.state.quote.quote
        }

      let store = this.$store
      axios.post(process.env.API_URL + 'quote/customer-save', quoteData).then((response) => {
        if (response.status === 200) {
          this.quoted = true
          return new Promise((resolve, reject) => {
            this.$store.commit('SET_LOADING_STATE', {value: false})
            this.$swal({
              title: stock ? 'Orçamento salvo com sucesso!' : 'Solicitação enviada com sucesso!',
              text: stock ? 'Visite sua conta para ver mais detalhes' : 'Em breve entraremos em contato com você',
              showDenyButton: true,
              confirmButtonText: 'Ver meus orçamentos',
              confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
              iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
              denyButtonColor: '#b5b5b5',
              denyButtonText: `Fechar`,
              icon: 'info'
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/minha-conta/orcamentos')
              }
              resolve(result.isConfirmed)
            })
          })
        }

      })
    }
    // save () {
    //   axios.post(process.env.API_URL + 'user/save-search', {
    //     search: {
    //       origin: this.$store.state.origin,
    //       destination: this.$store.state.destination,
    //       selectedVehicleType: this.$store.state.selectedVehicleType
    //     }
    //   }).then((response) => {
    //     if (response.status === 200) {

    //       let total = 0

    //       let responseItem = {
    //         selectedVehicle: this.$store.state.selectedVehicle,
    //         type: this.$store.state.serviceType,
    //         origin: this.$store.state.origin,
    //         destination: this.$store.state.destination,
    //         tripDetails: this.$store.state.tripDetails
    //       }
    //       if (this.$store.state.selectedVehicle.hasOwnProperty('extra')) {
    //         let vehicleType = this.getVehicleType(this.$store.state.selectedVehicle.type)
    //         this.transferPrice = Number(this.$store.state.selectedVehicle.extra.transfer[vehicleType + '_price'])
    //         this.transferRoundTripPrice = Number((this.$store.state.selectedVehicle.extra.transfer_round_trip != null ? this.$store.state.selectedVehicle.extra.transfer_round_trip[vehicleType + '_price'] : 0))
    //         total = this.transferPrice + this.transferRoundTripPrice + parseFloat(this.$store.state.tripDetails.driverExpenses.driverExpensesValue)
    //         total = total / parseFloat(this.$store.state.selectedVehicle.capacity)
    //         responseItem['transfer'] = this.$store.state.selectedVehicle.extra.transfer
    //         responseItem['transferDetails'] = this.$store.state.transfersGoing
    //       } else {
    //         total = this.vehicle.priceCents / parseFloat(this.vehicle.capacity)
    //       }
    //       if(responseItem['type'] != 'transfer'){
    //         responseItem['selectedVehicle']['id'] = this.vehicle.id
    //       }
    //       responseItem['total'] = total
    //       responseItem['subtotal'] = total

    //       // axios.post(process.env.API_URL + 'user/order', {
    //       //   items: [
    //       //     responseItem
    //       //   ],
    //       //   quoteExpiryDate: this.$store.state.companyDetails.quote_valid_days,
    //       //   user_id: this.$store.state.auth.userData.id,
    //       //   total: total,
    //       //   subtotal: total,
    //       //   search_id: response.data.data.id
    //       // })

    //       this.successSearch = true
    //     }
    //   })
    // }
  }
}
</script>

<style lang="scss">

  #save-success {
    footer {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;

      .btn-default {
         a {
          color: white;

          &:hover {
            text-decoration: none;
          }
         }
          border-radius: 6px;
          color: #fff !important;
          text-decoration: none;
          background-color: #293c4b;
          padding: 10px 12px;
          text-transform: uppercase;
          display: inline-block;
          width: 100%;
          background-color: var(--default-color);
          border-color: var(--default-color);
          transition: .5s ease-in-out;
          width: fit-content;
          border: 1px solid;

          &:hover {
            border-color: var(--default-color);
            background: transparent;

            a {
              color: var(--default-color)!important;
            }
          }
      }
    }
  }

  #search-results {
    #result-list {
      .result-item {
        .row-actions {
          .col-actions {
            a {
                border-color: var(--default-color);
                color: var(--default-color)!important;
                font-size: 16px!important;
                border: 1px solid;
                border-radius: 4px;
                font-weight: 500!important;

                @media (max-width: 1024px) {
                  &.print-budget-btn {
                    font-size: 12px!important;
                    align-items: center!important;
                    line-height: 24px;
                  }
                }

                &:hover {
                  background-color: var(--default-color)!important;
                  color: white!important;
                }
            }
          }
        }
      }
    }
  }

  .modal-footer,.modal-header  {
    border: none !important;
  }

  .modal-title {
    color: #007bff;
    font-size: 13px;
  }

  @media (min-width: 576px) {
    .modal-dialog {
        max-width: 690px !important;
        margin: 1.75rem auto;
    }
  }
</style>
