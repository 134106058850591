<template>
  <div style="position: relative;" class="preventCloseModal place-field d-flex align-items-center" :style="cssProps">
    <div class="icon mr-3">
      <b-icon-circle v-if="!isLastStep && type != 'stop-point'"/>
      <svg 
      v-else-if="type == 'stop-point'"
      class="stop-point-icon"
      version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="187.000000pt" height="206.000000pt" viewBox="0 0 187.000000 206.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,206.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M1122 2039 c-130 -65 -130 -254 0 -320 32 -16 54 -20 94 -17 98 8
          164 79 164 177 0 138 -134 221 -258 160z"/>
          <path d="M982 1630 c-39 -24 -65 -71 -43 -78 7 -2 -33 -30 -90 -61 l-103 -56
          -73 -175 c-40 -96 -73 -184 -73 -196 0 -39 37 -74 79 -74 20 0 45 6 54 13 9 6
          44 77 77 157 34 80 67 150 73 156 7 7 44 28 82 49 l70 37 -53 -47 c-29 -26
          -58 -57 -64 -69 -23 -41 -37 -336 -26 -537 l10 -186 -101 -220 c-99 -216 -111
          -255 -91 -294 13 -24 61 -49 94 -49 49 0 80 47 191 288 l107 233 -6 127 c-4
          70 -9 156 -13 190 -5 63 -5 64 18 60 21 -3 30 -21 80 -168 l56 -165 22 -249
          c12 -136 26 -257 32 -267 12 -24 60 -49 93 -49 14 0 40 13 58 29 38 34 38 37
          8 366 -18 207 -22 228 -63 350 l-44 130 12 175 c7 96 13 176 14 178 1 1 13 -6
          29 -17 26 -19 289 -91 332 -91 32 0 70 44 70 81 0 56 -28 74 -174 110 l-131
          32 -57 66 c-51 57 -58 70 -58 107 0 51 -34 98 -83 113 -18 5 -88 12 -157 16
          -115 5 -128 4 -158 -15z"/>
          <path d="M545 824 c-32 -68 -62 -124 -67 -124 -5 0 -26 9 -48 20 -45 23 -79
          25 -102 8 -21 -17 -258 -532 -258 -562 0 -22 33 -66 50 -66 5 0 7 -14 3 -30
          -12 -60 60 -96 92 -46 14 22 19 23 50 13 59 -18 69 -3 204 293 l125 273 -17
          30 c-18 30 -18 30 41 157 l59 128 -37 15 -38 15 -57 -124z"/>
        </g>
      </svg>
      <svg
        v-else
        data-v-41be6633="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="geo alt fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="destination-icon bi-geo-alt-fill mx-auto b-icon bi">
        <g data-v-41be6633="">
          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
        </g>
      </svg>
    </div>
    <div class="content w-100">
      <input 
        v-on:focus="clearErrors" 
        v-on:blur="validateAddress" 
        type="text" :id="this.prop" 
        ref="autocomplete" 
        :value="this.value" 
        :class="this.classname" 
        :placeholder="this.placeholder"
        >
        <!-- <button 
          v-if="showDeleteButton ? true : this.value && this.value.length > 0" 
          tabindex="-1" 
          type="button" 
          @click="buttonCallback" 
          >
          <font-awesome-icon class="preventCloseModal" :icon="['fas','times']"></font-awesome-icon>
        </button> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String
    },
    label: {
      type: String
    },
    isLastStep: {
      type: Boolean
    },
    showDeleteButton: {
      type: Boolean,
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    classname: {
      type: String
    },
    prop: {
      type: String
    },
    index: {
      type: Number
    },
    id: {
      type: String
    }
  },
  data: () => ({
    autocomplete: null
  }),
  computed: {
      cssProps () {
          return {
              '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
          }
      },
  },
  methods: {
    MapsInit () {
      this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs.autocomplete, { country: 'br' })
      this.autocomplete.setComponentRestrictions({
        country: ['br']
      })
      this.autocomplete.addListener('place_changed', () => {
        this.callback()
      })
    },
    callback () {
      this.$emit('send-message', this.id, this.autocomplete.getPlace(), this.index)
    },
    buttonCallback () {
      this.$emit('delete-data', this.prop, this.index)
    },
    validateAddress () {
      this.$emit('validate-data', this.prop)
    },
    clearErrors () {
      this.$emit('clear-errors', this.prop)
    }
  },
  mounted () {
    this.MapsInit()
  }
}
</script>

<style lang="scss">

  .place-field {
    font-family: 'Inter', sans-serif;
    border: 1px solid #ced4da;
    border-radius: 150px;
    padding: 8px 15px;
  }

  .stop-point-icon {
     * {
      fill: var(--default-color);
    }
    height: 16px;
    width: 16px;
  }
    
  .destination-icon {
    font-size: 20px;
  }

  input{
      border: none!important;
      padding: 0;
      font-size: 16px;
  }

  .icon, .icon * {
    color: var(--default-color);
  }
    
  ::-webkit-input-placeholder {
    color: #A6A5A7;
    font-size: 14px;
  }

  :-ms-input-placeholder {
    color: #A6A5A7;
    font-size: 14px;
  }

  ::placeholder {
    color: #A6A5A7;
    font-size: 14px;
  }

</style>
