<template>
  <b-form id="search-form" class="search-form default-bordered trip-form">
  <b-row class="row-trip-info">
    <b-col lg="6" md="12">
      <h3>Origem</h3>
      <b-form-row>
        <b-form-group :class="{'input-select col-12':true, 'has-feedback': $store.state.transfersGoing.address.origin === '' && showErrors}" >
          <b-input-group>
            <b-input-group-text :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"> <b-icon-geo-alt></b-icon-geo-alt></b-input-group-text>
              <b-form-select :value="$store.state.transfersGoing.address.origin" class="form-control"
              @change="setTransfersGoingOriginProp($event, 'origin'), getDestinyById($event), deleteDestinationGoing()">
                  <option v-for="origin in origins" v-bind:key="origin.id" :value="origin.id">{{origin.address}}</option>
              </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group :class="{'col-lg-6':true, 'has-feedback': $store.state.transfersGoing.date === '' && showErrors}">
          <b-input-group>
            <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><b-icon-calendar3></b-icon-calendar3></b-input-group-text>
            <DatePicker
              name="destinationDate"
              ref="destinationDate"
              class="form-control form-group input-feedback"
              :value="$store.state.transfersGoing.date"
              @selected="setTransfersGoingOriginProp($event, 'date')"
              placeholder="Data"
              format="dd/MM/yyyy"
              :disabled-dates="reservationStartDate"
              :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
              :open-date="transferGoingTripOpenDate"
            >
            </DatePicker>
          </b-input-group>
        </b-form-group>

        <b-form-group class="col-lg-6 time">
          <b-input-group>
            <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><b-icon-clock></b-icon-clock></b-input-group-text>
            <time-select-field
              ref="destinationTime"
              placeholder="Horário"
              classes="form-control"
              v-on:updateschedule="setTransfersGoingOriginProp($event, 'time')"
              :value="$store.state.transfersGoing.time"
              :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
            >
            </time-select-field>
          </b-input-group>
        </b-form-group>
      </b-form-row>
    </b-col>

    <b-col lg="6" md="12">
      <h3>Destino</h3>
      <b-form-row>
        <b-form-group :class="{'col-12 destination input-select':true, 'has-feedback': $store.state.transfersGoing.address.destination === '' && showErrors}"  >
          <b-input-group>
            <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"> <b-icon-geo-alt></b-icon-geo-alt></b-input-group-text>
              <b-form-select :value="$store.state.transfersGoing.address.destination" class="form-control" @change="setTransfersGoingOriginProp($event, 'destination')">
                  <option v-for="destiny in destinationsGoing" v-bind:key="destiny.id" :value="destiny.id">{{destiny.address}}</option>
              </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-form-row>
      <b-form-row >
        <!-- <b-form-group style="padding: 10px" :class="{'col-lg-12':true, 'has-feedback': $store.state.transfersGoing.date === '' && showErrors}" >
          <span style="margin-right:10px">SOMENTE IDA</span>
            <label for="">
              <b-form-checkbox
                  name="check-button"
                  size="lg"
                  @change="setTransfersGoingOriginProp($event, 'isChecked')"
                  switch
                  v-model="$store.state.transfersGoing.isChecked"
              >
              </b-form-checkbox>
            </label>

          <span>IDA E VOLTA</span>
        </b-form-group>
-->
        <div class="d-flex align-content-center col-lg-12 trip-type" :style="cssProps">
          <label for="round-trip">
            <input type="radio" @click="setTransfersGoingOriginProp(true, 'isChecked')" id="round-trip" name="duration" value="roundtrip" :checked="this.$store.state.transfersGoing.isChecked">
            <span>Ida e Volta</span>
          </label>
          <label for="one-way-trip">
            <input type="radio" @click="setTransfersGoingOriginProp(false, 'isChecked')" id="one-way-trip" name="duration" value="onewaytrip" :checked="!this.$store.state.transfersGoing.isChecked">
            <span>Somente Ida</span>
          </label>
        </div>

      </b-form-row>

    </b-col>

  </b-row>
  <!-- <b-form-row class="mb-5">
    <b-col>
      <h3>Observações da Ida</h3>
      <b-form-group :class="{'has-feedback': $store.state.transfersGoing.observations === '' && showErrors}">
          <b-form-textarea
          :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
          id="textarea"
          placeholder=""
          v-model="$store.state.transfersGoing.observations"
          rows="3"
          v-on:change="setTransfersGoingOriginProp($event, 'observations')"
          max-rows="6">
          </b-form-textarea>
      </b-form-group>
    </b-col>
  </b-form-row> -->
  <transition name="fade">

  <b-row v-show="$store.state.transfersGoing.isChecked" class="row-trip-info">
      <b-col>
      <h3>Origem</h3>
      <b-form-row ref="roundTrip">
        <b-form-group :class="{'col-12':true, 'has-feedback': $store.state.transfersRoundTrip.address.origin === '' && showErrors}" >
          <b-input-group>
            <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"> <b-icon-geo-alt></b-icon-geo-alt></b-input-group-text>
            <b-form-select
                  class="form-control"
                  :value="$store.state.transfersRoundTrip.address.origin"
                  @change="setTransfersRoundTripProp($event, 'origin'), getDestinyById($event, true), deleteDestinationRoundTrip()"
              >
                  <option v-for="origin in origins" v-bind:key="origin.id" :value="origin.id">{{origin.address}}</option>
              </b-form-select>

          </b-input-group>
        </b-form-group>
      </b-form-row>

      <b-form-row>
          <b-form-group :class="{'col-lg-6':true, 'has-feedback': $store.state.transfersRoundTrip.date === '' && showErrors}" >
          <b-input-group>
            <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><b-icon-calendar3></b-icon-calendar3></b-input-group-text>
            <DatePicker
              name="destinationDate"
              ref="destinationDate"
              class="form-control input-feedback"
              placeholder="Data"
              format="dd/MM/yyyy"
              :value="$store.state.transfersRoundTrip.date"
              @selected="setTransfersRoundTripProp($event, 'date')"
              :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
              :disabled-dates="$store.state.transferBackDisabledDates"
              :open-date="transferRoundTripOpenDate"
            >
            </DatePicker>
          </b-input-group>
        </b-form-group>

          <b-form-group :class="{'col-lg-6 time':true, 'has-feedback': $store.state.transfersRoundTrip.time === '' && showErrors}">
          <b-input-group>
            <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><b-icon-clock></b-icon-clock></b-input-group-text>
            <time-select-field
              ref="destinationTime"
              placeholder="Horário"
              v-on:updateschedule="setTransfersRoundTripProp($event, 'time')"
              :value="$store.state.transfersRoundTrip.time"
              classes="form-control"
              :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
            >
            </time-select-field>

          </b-input-group>
        </b-form-group>
      </b-form-row>
    </b-col>

      <b-col>
      <h3>Destino</h3>
      <b-form-row>
        <b-form-group :class="{'col-lg-12 destination ':true, 'has-feedback': $store.state.transfersRoundTrip.address.destination === '' && showErrors}">
          <b-input-group>
            <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"> <b-icon-geo-alt></b-icon-geo-alt></b-input-group-text>
              <b-form-select class="form-control" :value="this.$store.state.transfersRoundTrip.address.destination" @change="setTransfersRoundTripProp($event, 'destination')">
                <option v-if="this.destinationsRoundTrip.length == 0" :value=0 >Sem transfer para a origem</option>
                <option v-else v-for="destiny in destinationsRoundTrip" v-bind:key="destiny.id" :value="destiny.id">{{destiny.address}}</option>
              </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-form-row>
    </b-col>
    <!-- <b-col cols="12">
      <h3>Observações da Volta</h3>
      <b-form-group :class="{'has-feedback': $store.state.transfersRoundTrip.observations === '' && showErrors}">
          <b-form-textarea
          :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
          id="textarea"
          placeholder=""
          v-model="$store.state.transfersRoundTrip.observations"
          rows="3"
          v-on:change="setTransfersRoundTripProp($event, 'observations')"
          max-rows="6">
          </b-form-textarea>
      </b-form-group>
    </b-col> -->
  </b-row>

  </transition>
  <b-form-row class="row-budget">
    <b-form-group class="col-12 vehicle">
        <h3>Selecione o veículo</h3>
      <b-input-group>
        <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><img :src="this.busIcon" /></b-input-group-text>
        <vehicle-select :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }" />
      </b-input-group>
    </b-form-group>

  </b-form-row>

  <b-form-row>
    <b-form-group class="col-12 mt-3 btn-wrapper">
      <b-input-group>
        <button
          :style="{ backgroundColor: primaryColor, borderColor: primaryColor }"
          type="button"
          class="btn btn-primary btn-block btn-search"
          @click="viewOrc()"
        >
          Ver Orçamento
        </button>
      </b-input-group>
    </b-form-group>
  </b-form-row>
</b-form>
</template>

<script>
import DatePicker from '@sum.cumo/vue-datepicker'
import TimeSelectField from '@/components/fields/TimeSelectField.vue'
import VehicleSelect from '@/components/form/VehicleSelect.vue'
import utils from '@/components/mixins/utils'

export default {
  name: 'TransferForm',
  props: ['redirect'],
  data () {
    return {
      reservationStartDate: {
        to: this.setReservationDate()
      },
      transferRoundTripOpenDate: this.roundTripOpenDate(),
      transferGoingTripOpenDate: this.goingTripOpenDate(),
      origins: [],
      originsRoundTrip: [],
      destinationsGoing: [],
      destinationsRoundTrip: [],
      showErrors: false,
      busIcon: require('../../assets/icon/bus.svg')
    }
  },
  components: {
    DatePicker,
    TimeSelectField,
    VehicleSelect
  },
  mixins: [
    utils
  ],
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.body_font_color
    },
    formFontColor () {
      return this.$store.state.companyDetails.whitelabel.form_font_color
    },
    formBackgroundColor () {
      return this.$store.state.companyDetails.whitelabel.form_background_color
    }
  },
  methods: {
    roundTripOpenDate () {
      let date = this.$store.state.transferBackDisabledDates.to ? this.$store.state.transferBackDisabledDates.to : this.openDateOrigin()
      return date
    },
    goingTripOpenDate () {
      let date = this.$store.state.transfersGoing.date ? this.$store.state.transfersGoing.date : this.openDateOrigin()
      return date
    },
    setReservationDate (date = null) {
      let result = date || new Date()
      result.setDate(result.getDate() + Number(this.$store.state.companyDetails.reservationStartAt))
      return result
    },
    tawkToEvent (event, data) {
      if (this.$store.state.companyDetails.whitelabel.chats.tawkto !== null) {
        window.Tawk_API.addEvent(event, data, function (error) {
        })
      }
    },
    async getAllOrigin () {
      const allOrigin = await this.$http.get(process.env.API_URL + 'point/origin')
      allOrigin.data.points.forEach(item => {
        this.origins.push(item)
      })
    },
    async getDestinyById (id, roundTrip) {
      let destinyByOrigin = await this.$http.get(process.env.API_URL + `transfer/${id}`)
      if (roundTrip) {
        destinyByOrigin.data.transfers.forEach(item => {
          this.destinationsRoundTrip.push(item.point_destination)
        })
      } else {
        this.$store.commit('SET_TRANSFER_DESTINATION', destinyByOrigin)
        destinyByOrigin.data.transfers.forEach(item => {
          this.destinationsGoing.push(item.point_destination)
        })
      }
    },
    deleteDestinationGoing () {
      while (this.destinationsGoing.length > 0) {
        this.destinationsGoing.pop()
      }
    },
    deleteDestinationRoundTrip () {
      while (this.destinationsRoundTrip.length > 0) {
        this.destinationsRoundTrip.pop()
      }
    },
    callErrorAlert (action, message) {
      this.showErrors = true
      this.$swal({
        text: message,
        showDenyButton: false,
        showConfirmButton: true,
        iconColor: action === 'error' ? '#e24d41' : this.$store.state.companyDetails.whitelabel.body_font_color,
        confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
        icon: action
      })
    },
    cleanTripStates (types) {
      types.forEach((type) => {
        switch (type) {
          case 'trip':
            this.$store.commit('CLEAN_TRIP_INFO')
            break
          case 'daily':
            this.$store.commit('CLEAN_DAILY_INFO')
            break
          case 'transfer':
            this.$store.commit('CLEAN_TRANSFER_INFO')
            break
        }
      })
    },
    viewOrc () {
      this.showErrors = false

      if (this.$store.state.transfersGoing.address.origin === '') {
        this.callErrorAlert('error', 'Local de partida é obrigatório')
        return
      }

      if (this.$store.state.transfersGoing.address.destination === '') {
        this.callErrorAlert('error', 'Local de destino é obrigatório')
        return
      }

      if (this.$store.state.transfersGoing.date === '') {
        this.callErrorAlert('error', 'Data de partida é obrigatório')
        return
      }

      if (this.$store.state.transfersGoing.isChecked) {
        if (this.$store.state.transfersRoundTrip.address.origin === '' || this.$store.state.transfersRoundTrip.address.origin == 0) {
          this.callErrorAlert('error', 'Local de partida do retorno é obrigatório')
          return
        }

        if (!this.destinationsRoundTrip.length > 0 || this.$store.state.transfersRoundTrip.address.destination === '') {
          this.callErrorAlert('error', 'Local de destino do retorno é obrigatório')
          return
        }

        if (this.$store.state.transfersRoundTrip.date === '') {
          this.callErrorAlert('error', 'Data do retorno é obrigatório')
          return
        }

        if (this.$store.state.transfersRoundTrip.data === '') {
          this.callErrorAlert('error', 'Data de partida do retorno é obrigatório')
          return
        }
      }

      this.cleanTripStates(['daily', 'trip'])

      this.$store.commit('SET_DESTINATION_DETAILS', {
        address: '',
        city: '',
        lat: 0,
        lng: 0,
        date: '',
        time: '00:00'
      })
      this.$store.commit('SET_TYPE', 'transfer')

      if (!this.showErrors) {
        if (this.$router.currentRoute.name === 'search-results') {
          this.$router.go()
        } else {
          let serviceTrip = this.$store.state.transfersGoing.isChecked ? ' ida e volta' : 'somente ida'

          let eventData = {
            'servico': this.$store.state.serviceType + ' - ' + serviceTrip
          }

          eventData['origem-ida'] = this.$store.state.transferDestination.point_origin.address
          eventData['data-ida'] = this.moment(this.$store.state.transfersGoing.date).format('DD/MM/YYYY')
          eventData['hora-ida'] = this.$store.state.transfersGoing.time
          eventData['destino-ida'] = this.$store.state.transferDestination.point_destination.address

          if (this.$store.state.transfersGoing.isChecked) {
            eventData['origem-volta'] = this.$store.state.transferDestination.point_destination.address
            eventData['data-volta'] = this.moment(this.$store.state.transfersRoundTrip.date).format('DD/MM/YYYY')
            eventData['hora-volta'] = this.$store.state.transfersRoundTrip.time
            eventData['destino-volta'] = this.$store.state.transferDestination.point_origin.address
          }

          this.moment(this.$store.state.origin.date).format('DD/MM/YYYY')
          if (this.$store.state.companyDetails.whitelabel.chats.tawkto !== null) {
            this.tawkToEvent('nova-pesquisa', eventData)
          }

          this.$router.push({ path: '/search-results', replace: true })
        }
      }
    },
    updateDatepickerUI () {
      this.transferRoundTripOpenDate = this.roundTripOpenDate()
      this.transferGoingTripOpenDate = this.goingTripOpenDate()
    },
    setTransfersRoundTripProp: function (value, prop) {
      if (prop === 'date') {
        value = this.moment(value).format('YYYY/MM/DD')
      }

      this.updateDatepickerUI()
      this.$store.commit('SET_TRANSFER_ROUND_TRIP_PROP', { prop: prop, value: value })
    },
    checkDiffBetweenDates (origin, destination) {
      let originDate = this.moment(origin)
      let destinationDate = this.moment(destination)
      return destinationDate.diff(originDate, 'days') < 0
    },
    setTransfersGoingOriginProp: function (value, prop) {
      if (prop === 'date') {
        let year = this.moment(value).format('YYYY')
        let month = this.moment(value).format('MM')
        let day = this.moment(value).format('DD')
        let date = new Date(year, (month - 1 < 0 ? 0 : month - 1), day)

        this.$store.commit('setTransferBackDisabledDates', date)
        value = this.moment(value).format('YYYY/MM/DD')
      }
      if (prop === 'destination') {
        this.setTransfersRoundTripProp(value, 'origin')
        this.getDestinyById(value, true)
      }

      if (prop === 'origin') {
        this.setTransfersRoundTripProp(value, 'destination')

        this.$store.commit('SET_TRANSFER_ROUND_TRIP_PROP', { prop: 'origin', value: {} })
      }

      this.updateDatepickerUI()
      this.$store.commit('SET_TRANSFER_GOING_PROP', { prop: prop, value: value })

      // Remove round trip date, if going date is higher
      if (this.checkDiffBetweenDates(this.$store.state.transfersGoing.date, this.$store.state.transfersRoundTrip.date)) {
        this.setTransfersRoundTripProp('', 'date')
      }
    }
  },
  beforeMount () {
    this.getAllOrigin()
    if (this.$store.state.transfersGoing.address.origin !== '') {
      this.getDestinyById(this.$store.state.transfersGoing.address.origin)
    }

    // if (this.$store.state.transfersRoundTrip.address.origin !== '') {
    //   this.getDestinyById(this.$store.state.transfersRoundTrip.address.origin, true)
    // }
  },
  mounted () {
    let transferBackDisabledDates = this.$store.state.transfersGoing.date
    if (transferBackDisabledDates) {
      let year = this.moment(transferBackDisabledDates).format('YYYY')
      let month = this.moment(transferBackDisabledDates).format('MM')
      let day = this.moment(transferBackDisabledDates).format('DD')
      let date = new Date(year, (month - 1 < 0 ? 0 : month - 1), day)

      this.$store.commit('setTransferBackDisabledDates', date)
    }

    setTimeout(() => {
      var randomicAtomic = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
      const inputArrays = Array.from(document.querySelectorAll('.form-control'))
      inputArrays.forEach(inputElement => {
        inputElement.setAttribute('autocomplete', randomicAtomic)
      })
    }, 1000)
  }
}
</script>

<style scoped>

@import '~@sum.cumo/vue-datepicker/dist/Datepicker.css';
input:checked + span {
  color: var(--default-color)!important;
}
</style>
