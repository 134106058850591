import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import WhiteLabel from './views/WhiteLabel.vue'

Vue.use(Router)

const auth = (to, from, next) => {
  let authRoutes = ['minha-conta']

  if (localStorage.getItem('authToken') === null && authRoutes.includes(to.name)) {
    return next('/login')
  } else {
    return next()
  }
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'white-label',
      title: 'White Label',
      component: WhiteLabel,
      props: true
    },
    {
      path: '/search-results',
      name: 'search-results',
      title: 'Resultados da busca',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/SearchResults.vue')
    },
    {
      path: '/minha-conta',
      name: 'minha-conta',
      title: 'Minha conta',
      beforeEnter: auth,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/MyAccount/MyAccount.vue')
    },
    {
      path: '/minha-conta/orcamentos',
      name: 'orcamentos',
      title: 'Meus orçamentos',
      beforeEnter: auth,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/MyAccount/MyQuotes.vue')
    },
    {
      path: '/minha-conta/reservas',
      name: 'reservas',
      title: 'Minhas reservas',
      beforeEnter: auth,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/MyAccount/MyOrders.vue')
    },
    {
      path: '/login',
      name: 'login',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Login.vue')
    },
    {
      path: '/cadastrar',
      name: 'cadastrar',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Register.vue')
    },
    {
      path: '/checkout',
      name: 'checkout',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Checkout.vue')
    },
    {
      path: '/aceitar-termos/:id',
      name: 'aceitar-termos',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Terms.vue')
    },
    {
      path: '/pedido-realizado/:slug',
      name: 'pedido-realizado',
      // beforeEnter: order,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/OrderReceived.vue')
    },
    {
      name: 'order',
      path: '/pedidos/:slug',
      component: () => import('@/views/Order'),
      props: true
    },
    {
      name: 'ver-orcamento',
      path: '/ver-orcamento'
      // component:() => import(/* webpackChunkName: "about" */ './views/ViewOrc.vue')
    }
  ]
})

router.afterEach((to, from) => {
  store.commit('SET_LOADING_STATE', { value: false })
})

export default router;