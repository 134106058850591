<template>
    <div id="hour-trip-select" :class="classes">
        <select name="hour-trip" id="hour-trip" v-model="selectedFranchise" :class="[selectedFranchise == '' ? 'not-selected' : '']"  @change="changeFranchise">
            <option value="" v-if="placeholder">{{ placeholder }}</option>
            <option v-for="option in franchise"  :value="option">{{ option }} {{  option === 1 ? 'Hora' : 'Horas' }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'FrontendHourTripSelect',
    props: ['placeholder', 'classes'],
    data() {
        return {
            franchise: this.$store.state.companyDetails.hourtripQuotes,
            selectedFranchise: this.$store.state.simpleTrip.franchise
        };
    },

    methods: {
        changeFranchise: function (value) {
            this.$emit('updatefranchise', this.selectedFranchise)
        }
    },
    mounted () {
    }
};
</script>

<style lang="scss" scoped>
    #hour-trip{
        width: 100%;
        border: none;
        padding: 0;
        border-right: 0!important;
        font-size: 14px!important;
        &.not-selected {
            color: #a6a5a7;
        }
    }
</style>