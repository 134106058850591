<template>
  <b-form id="search-form" v-on:submit.prevent="sendSolicitation()" enctype="multipart/form-data" class="chartering-form search-form" >
      <b-row >
          <b-col md="6" class="mb-3">
            <div class="form-control mb-4">
              <h3>Empresa/condomínio</h3>
                  <b-form-group>

                    <b-form-input :state="verifyPropDataError('company')" v-model="company"  :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"  ></b-form-input>
                    <b-form-invalid-feedback>{{errors['company']}}</b-form-invalid-feedback>
                  </b-form-group>
                 <!-- <span v-if="errors['company']">{{ errors['company'] }}</span> -->
              </div>
          </b-col>
          <b-col md="6" class="mb-3">
            <div class="form-control mb-4">
              <h3>Responsável</h3>
              <b-form-group>
                <b-form-input :state="verifyPropDataError('responsible')" v-model="responsible" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"  ></b-form-input>
                <b-form-invalid-feedback>{{errors['responsible']}}</b-form-invalid-feedback>
              </b-form-group>
          </div>
          </b-col>
      </b-row>

      <b-row>
          <b-col md="6" class="mb-3">
            <div class="form-control mb-4">
              <h3>Email</h3>
              <b-form-group>
                <b-form-input :state="verifyPropDataError('email')" v-model="email" type="email"  :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"  ></b-form-input>
                <b-form-invalid-feedback>{{errors['email']}}</b-form-invalid-feedback>
              </b-form-group>
          </div>
          </b-col>
          <b-col md="6" class="mb-3">
            <div class="form-control mb-4">
              <h3>Telefone/celular</h3>
              <b-form-group>
                <the-mask :mask="['(##) ####-####', '(##) #####-####']"
                    class=" form-control"
                    v-model="tel"
                    :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
                     />
                <b-form-invalid-feedback>{{errors['tel']}}</b-form-invalid-feedback>
              </b-form-group>
          </div>
          </b-col>
      </b-row>

      <b-row>
          <b-col md="6" class="align-input-process mb-3">
            <div class="form-control mb-4">
              <h3>Tipo de processo</h3>

                <b-form-input :state="verifyPropDataError('typeProcess')" v-model="typeProcess" type="text"  :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"  ></b-form-input>
                <b-form-invalid-feedback>{{errors['typeProcess']}}</b-form-invalid-feedback>

            </div>
          </b-col>
          <b-col md="6" class="mb-3">
            <div class="form-control mb-4">
              <h3>Data limite para envio da proposta</h3>
              <b-form>
                <!-- <b-form-datepicker :state="verifyPropDataError('dateLimit')" v-model="dateLimit" type="date" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }" ></b-form-datepicker>  -->
                <b-form-input onfocus="this.showPicker()" :state="verifyPropDataError('dateLimit')" v-model="dateLimit" type="date" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor, padding: '0 20px'}"  ></b-form-input>
                <b-form-invalid-feedback>{{errors['dateLimit']}}</b-form-invalid-feedback>
              </b-form>
          </div>
          </b-col>
      </b-row>

      <b-row>
          <b-col md="12" class="mb-3">
            <div class="form-control mb-4">
              <h3>Observações Gerais</h3>
              <b-form-textarea
                :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
                v-model="generalObservation"
                rows="3"
                max-rows="6">
               </b-form-textarea>
            </div>
          </b-col>
      </b-row>

      <b-row>
          <b-col class="mb-3 mt-5">
            <div class="form-control mb-4">
                  <input v-on:change="setPathDoc" type="file" ref="file" id="input" class="input-file w-100" placeholder="testando" >
                  <label for="input" class="label-input-file form" :style="{ backgroundColor: 'transparent', borderColor: primaryColor, border: '1px solid', color: primaryColor }">
                     <b-icon-paperclip font-scale="1.4"></b-icon-paperclip> {{fileName != '' ? fileName : "Anexar documentos (carta convite, etc)"}}
                  </label>
              </div>
          </b-col>
      </b-row>

      <b-row >
          <b-col class="col-orc mb-2" cols="12">
              <button type="submit" :style="{ backgroundColor: primaryColor, borderColor: primaryColor }" class="btn btn-primary btn-search w-100">Enviar Solicitação</button>
          </b-col>
          <b-col class="col-orc mb-2" v-show="success">
            <div class="alert alert-success">
              Mensagem enviada com sucesso!
            </div>
          </b-col>
      </b-row>
  </b-form>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CharteringForm',
  data () {
    return {
      company: '',
      responsible: '',
      email: '',
      tel: '',
      typeProcess: '',
      dateLimit: '',
      generalObservation: '',
      pathDoc: '',
      fileName: '',
      errors: {},
      success: false,
      showErrors: false
    }
  },
  computed: {
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.body_font_color
    },
    formFontColor () {
      return this.$store.state.companyDetails.whitelabel.form_font_color
    },
    formBackgroundColor () {
      return this.$store.state.companyDetails.whitelabel.form_background_color
    }

  },
  methods: {
    callErrorAlert(action, message) {
    this.showErrors = true;
      this.$swal({        
        text: message,
        showDenyButton: false,
        showConfirmButton: true,
        iconColor: action === 'error' ? '#e24d41' : this.$store.state.companyDetails.whitelabel.body_font_color,
        confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
        icon: action
      })
    },
    sendSolicitation (e) {
      this.showErrors = false;

      if (this.company === '') {
        this.callErrorAlert('error', 'Campo empresa é obrigatório');
        return;
      }

      if (this.responsible === '') {
        this.callErrorAlert('error', 'Campo responsável é obrigatório');
        return;
      }

      if (this.email === '') {
        this.callErrorAlert('error', 'Campo email é obrigatório');
        return;
      }

      if (this.tel === '') {
        this.callErrorAlert('error', 'Campo telefone é obrigatório');
        return;
      }

      if (this.typeProcess === '') {
        this.callErrorAlert('error', 'Campo tipo de processo é obrigatório');
        return;
      }

      if (this.dateLimit === '') {
        this.callErrorAlert('error', 'Campo tipo de processo é obrigatório');
        return;
      }

      if (this.typeProcess === '') {
        this.callErrorAlert('error', 'Campo tipo de data limite para envio da proposta é obrigatório');
        return;
      }
      
      let router = this.$router;
      this.$store.commit('SET_LOADING_STATE', {value: true})
      const formData = new FormData()
      formData.append('file', this.$refs.file.files[0])
      formData.append('company', this.company)
      formData.append('responsible', this.responsible)
      formData.append('email', this.email)
      formData.append('phone', this.tel)
      formData.append('process', this.typeProcess)
      formData.append('limit_date', this.dateLimit)
      formData.append('observation', this.generalObservation)
      formData.append('file', this.pathDoc)


      new Promise((resolve, reject) => {
        this.$http.post(process.env.API_URL + 'company/create-charter-continous', formData).then((r) => {
          this.$store.commit('SET_LOADING_STATE', { value: false })
          this.$swal({
            title: 'Seu pedido de fretamento foi gerado!',
            text: 'Entraremos em contato em breve.',
            showDenyButton: false,
            showConfirmButton: true,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            icon: 'success'
          }).then(async (result) => {
            router.go()
          })

        })
      })
    },
    setPathDoc (event) {
      this.fileName = event.target.files[0].name;
      this.pathDoc = ''
      this.pathDoc = event.target.files
    },
    setErrors (errors) {
      this.errors = []
      for (let error in errors) {
        this.errors[error] = errors[error][0]
      }
    },
    verifyPropDataError (prop) {
      return !this.errors[prop] ? null : false
    }
  }
}
</script>

<style lang="scss">

  #search-form-wrapper {
    .chartering-form {
      textarea::-webkit-scrollbar {
        display: none;
      }
      input, textarea {
        border: 2px solid #d6d6d6!important;
        border-radius: 40px;
      }

      textarea {
        border-radius: 25px;
      }
      h3, .form-group {
        margin-bottom: 10px;
      }
    }
  }

  .form-control {
    border: 2px solid #d6d6d6!important;
    border-radius: 40px;
    border-radius: 40px;
  }

    #search-form {
      text-align: left;
    }

    .col-orc {
        display: flex;
        justify-content: flex-end;
    }

    .orc-button {
        background-color: #293c4b!important;
        width: 350px;
        padding: 10px 0;
    }

    .align-input-process {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .input-file {
        display:none
    }

    .label-input-file {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-align: center;
        border-radius: 50px;
    }

</style>
