<template>
  <div :style="cssProps">

    <div id="fixed-trip-details" :class="modalOpen ? 'active' : ''">
      <div class="content">
        <vehicle-usage></vehicle-usage>
        <div class="footer">
          <button @click.prevent="modalOpen = false" class="btn outline-btn primary btn-block preventCloseModal">Concluir</button>
        </div>
      </div>
    </div>
    <div id="search-form-wrapper" :class="context">
      <b-tabs content-class="mt-2" v-if="mobile === false">
        <b-tab @click="setType('viagem')" title="Viagem" :active="$store.state.serviceType=='viagem'">
          <trip-search-form
            @change="openFixedTripDetails($event)"
          ></trip-search-form>
        </b-tab>
        <b-tab @click="setType('transfer')" title="Transfer" :active="$store.state.serviceType=='transfer'">
          <transfer-form></transfer-form>
        </b-tab>
        <b-tab @click="setType('diaria')" title="Diária" :active="$store.state.serviceType=='diaria'">
          <daily-form></daily-form>
        </b-tab>
        <b-tab @click="setType('fretamento')" title="Fretamento Contínuo" :active="$store.state.serviceType=='fretamento'">
          <chartering-form></chartering-form>
        </b-tab>
      </b-tabs>
      <b-row  v-else>

      
        <div class="col-12 mobile-tabs pb-3">
          <b-form-row>
            <b-form-group class="col-12">
              <h3>Selecione o tipo de serviço</h3>
              <b-input-group>
                <div class="form-control p-0" style="border:none;">
                  <select name="" id="" @change="mobileChangeTab($event)">
                    <option value="0">Viagem</option>
                    <option value="1">Transfer</option>
                    <option value="2">Diária</option>
                    <option value="3">Fretamento Contínuo</option>
                  </select>
                </div>
              </b-input-group>
            </b-form-group>
          </b-form-row>
        </div>

        <b-tabs content-class="mt-2" v-model="step">
          <b-tab @click="setType('viagem')" title="Viagem" :active="$store.state.serviceType=='viagem'">
            <trip-search-form></trip-search-form>
          </b-tab>
          <b-tab @click="setType('transfer')" title="Transfer" :active="$store.state.serviceType=='transfer'">
            <transfer-form></transfer-form>
          </b-tab>
          <b-tab @click="setType('diaria')" title="Diária" :active="$store.state.serviceType=='diaria'">
            <daily-form></daily-form>
          </b-tab>
          <b-tab @click="setType('fretamento')" title="Fretamento Contínuo" :active="$store.state.serviceType=='fretamento'">
            <chartering-form></chartering-form>
          </b-tab>
        </b-tabs>

      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TripSearchForm from '@/components/forms/TripSearchForm.vue'
import DailyForm from '@/components/forms/DailyForm.vue'
import CharteringForm from '@/components/forms/CharteringForm.vue'
import TransferForm from '@/components/forms/TransferForm'
import VehicleUsage from '@/components/general/fixedModal/VehicleUsage.vue';

export default {
  name: 'SearchFormWrapper',
  props: ['context'],
  components: { TripSearchForm, VehicleUsage, DailyForm, CharteringForm, TransferForm },
  watch: {
    '$store.state.serviceType': function (val) {
      if (val === 'diaria' && this.$store.state.daily.quotes.length === 0) {
        this.$store.commit('SET_DAILY', {
          time: '00:00',
          addr_info: {
            address: ''
          },
          date: '',
          quote: 0
        })
      }
    }

  },
  data () {
    return {
      step: 0,
      mobile: false,
      modalOpen: false,
      usage_destination_errors: {},
      stops_inputs: [],
    }
  },
  created () {
    let $scope = this;

    document.addEventListener('click', function (event) {

      if (!event.target.matches('#fixed-trip-details')) return;
      event.preventDefault();

      $scope.modalOpen = false;
      document.getElementsByTagName('body')[0].style.overflow = '';

    }, false);
    this.isMobile()
  },
  destroyed () {
    window.removeEventListener('resize', this.checkDevice)
  },
  computed: {
    ...mapGetters('auth', ['user']),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color,
      }
    },
    backgroundColor () {
      return this.$store.state.companyDetails.whitelabel.header_background_color || ''
    }
  },
  methods: {
    addStopPoint: function () {
      this.stops_inputs.push(Places)
    },
    setStopPoint: function (addressData, addressObject, index) {
      this.$store.commit('SET_STOPS', {
        index,
        addr_info: {
          address: addressObject.formatted_address,
          city: addressObject.address_components[2].long_name,
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng()
        }
      })
    },
    deleteStopPoint: function (prop, index) {
      this.stops_inputs.splice(index, 1)
      this.$store.commit('DELETE_STOPS', {
        index
      })
    },
    mobileChangeTab: function (event) {
      this.step = event.target.value
    },
    checkDevice (e) {
      this.isMobile()
    },
    isMobile () {
      if ((window.innerWidth <= 990)) {
        this.mobile = true
      } else {
        this.mobile = false
      }
    },
    setType (type) {
      let duration = (type == 'transfer' || type == 'viagem') ? true : false;
      this.$store.commit('SET_DURATION', duration)
      this.$store.commit('SET_TYPE', type)
    },
    openFixedTripDetails(e){
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
      this.modalOpen = true;
    }
  }
}
</script>

<style lang="scss">
  @import "../../assets/scss/components/_search-form.scss";

  #fixed-trip-details {
    position: fixed;
    right: 0;
    opacity: 0;
    background: transparent;
    z-index: -10;
    height: 100vh;
    width: 100vw;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    align-items: center;

    .outline-btn {
        border-color: var(--default-color);
        color: var(--default-color);
        background-color: transparent;
        font-size: 12px;
        height: 35px;
        font-weight: bold;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        margin-bottom: 0;
        margin-top: 1px;
        border-radius: 40px;
        &.primary {
            background-color: var(--default-color);
            border-color: var(--default-color);
            color: white;
        }
    }

    .footer {
      background: #f4f4f4;
      position: sticky;
      bottom: 00px;
      padding: 10px 0;
    }

    .content {
      background: #f4f4f4;
      border-left: 2px solid #303650;
      padding: 20px;
      height: 90vh;
      width: 50vw;
      right: -100vw;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      box-shadow: 0 0 18px 3px #5d5d5d69;
      position: relative;
      transition: .5s ease-in-out;
    }

    &.active {
      background: #00000052;
      z-index: 99999;
      opacity: 1;

      .content {
        right: 0;
       
      }
    }
  }

  #search-form-wrapper {

    overflow: visible;
    z-index: 9;
    position: relative;

    input, .form-group, .form-control {
      border: none!important;

      select {
        border-right: 8px solid transparent!important;
      }
      
      &.time {
        .input-group {
          padding-right: 10px;
          border-radius: 50px;
          overflow: hidden;
        }
      }
    }

   

    .mobile-tabs{
      width: 100%;
      background: white;
      padding: 0 25px;
      padding-top: 20px;

      .form-control {
        border-radius: 50px;

        select {
          background: transparent;
          width: 100%;
          border-radius: 50px;
          border: none;
          // border-radius: 15px solid transparent !important;
        }

      }

      h3 {
        font-size: 16px;
        text-align: left;
        margin-bottom: 20px;
        color: #3c3f50 !important;
        font-weight: 500;
      }
    }

    @media(max-width: 980px) {
      width: 100%;

      select {
        padding: 10px 20px;
      }

    }

    &:checked + label {
        font-weight: bold;
        color: red!important;
    }
    label {
      color: #3c3f50
    }
    .nav-tabs {
      border: none;
      justify-content: space-around;
      margin-bottom: 0;
      padding: 15px 0 0;
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 1px;
      @media only screen and (max-width: 990px){
        display: none!important;
      }
    }

    .nav-item {
      margin-left: 0;
      @media only screen and (max-width: 600px){
        margin-bottom: 5px;
      }

      a {
        background-color: #fff;
        color: #ababab;
        padding: 10px 10px;
        border-radius: 0;
        border: none;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        position: relative;
        z-index: 2;

        &.active,
        &:hover {
          color: var(--default-color);
        }
        &.active {
          background-color: #ffffff;
          color: var(--default-color);
          border: none;
          border-bottom: 3px solid;
          border-color: var(--default-color);
          margin-bottom: -2px;
        }
      }
    }

    .tab-content {
      border-radius: 6px;
      filter: drop-shadow(-2.347px 14.815px 13.5px rgba(22,21,23,0.09));
      padding: 20px 25px;
      margin-top: 0 !important;
      border: none;
      border-radius: 20px!important;
      border-top-right-radius: 0!important;
      border-top-left-radius: 0!important;

      @media(max-width: 980px) {
        border-top: 0;
        padding-top: 5px;
      }
    }
  }
</style>
