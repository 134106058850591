import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import auth from './store/auth'
import cart from './store/cart'
import quote from './store/quote'

Vue.use(Vuex)
const getDefaultState = () => {
  return {
    searchData: {
      id: '',
      uuid: ''
    },
    companyGroup: {
      isGroup: false,
      options: {}
    },
    callRegisterModal: false,
    mustSaveSearch: true,
    tripMetas: {},
    tripType: 'roundtrip',
    ss: [],
    address: '',
    origin: {
      address: '',
      city: '',
      lat: 0,
      lng: 0,
      date: '',
      time: '00:00',
      address_component: ''
    },
    destination: {
      address: '',
      city: '',
      lat: 0,
      lng: 0,
      date: '',
      time: '00:00',
      address_component: ''
    },
    tripDetails: {
      ss: [],
      useVehicle: [],
      driverExpenses: {
        payDriverExpenses: 'paynow',
        driverExpensesValue: 0.00
      },
      observations: '',
      transfer: {
        observations: {
          origin: '',
          destination: ''
        }
      }
    },
    selectedVehicle: {
      priceCents: 0.00
    },
    serviceType: 'viagem',
    is_round_trip: true,
    errors: [],
    responseMessage: {},
    selectedVehicleType: 'all',
    daily: {
      observations: '',
      quotes: []
    },
    destinationDisabledDates: { to: new Date() },
    originDisabledDates: { to: new Date() },
    transferBackDisabledDates: { to: new Date() },
    point_origin: '',
    destination_point: '',
    reservationStartAtParsed: null
  }
}

export default new Vuex.Store({
  state: {
    searchData: {
      id: '',
      uuid: ''
    },
    hasCompanySelected: false,
    callRegisterModal: false,
    loginToViewResults: false,
    mustSaveSearch: true,
    tripMetas: {},
    agree_driver_expenses: false,
    budgets: [],
    block_ui: false,
    config: {
      fields: {
        date: {
          disabledDates: {
            to: new Date()
          }
        }
      }
    },
    companyDetails: {},
    address: '',
    origin: {
      address: '',
      city: '',
      lat: 0,
      lng: 0,
      date: '',
      time: '00:00',
      address_component: ''
    },
    destination: {
      address: '',
      city: '',
      lat: 0,
      lng: 0,
      date: '',
      time: '00:00',
      address_component: ''
    },
    simpleTrip: {
      origin: {
        address: '',
        city: '',
        lat: 0,
        lng: 0,
        date: '',
        time: '00:00',
        address_component: ''
      },
      destination: {
        address: '',
        city: '',
        lat: 0,
        lng: 0,
        date: '',
        time: '00:00',
        address_component: ''
      },
      is_round_trip: false,
      stop_points: [],
      franchise: ''
    },
    customRouter: {
      hideDestination: false,
      step: 0,
      disabledDates: {
        to: new Date()
      },
      tripDays: 0,
      initialDate: '',
      endDate: '',
      days: [],
      routes: [],
      status: true
    },
    orderSummary: {
      customRouter: {}
    },
    transfersGoing: {
      address: {
        origin: '',
        destination: ''
      },
      date: '',
      time: '00:00',
      isChecked: false,
      observations: ''
    },
    transfersRoundTrip: {
      address: {
        origin: '',
        destination: ''
      },
      date: '',
      time: '00:00',
      observations: ''
    },
    tripDetails: {
      stop_points: [],
      useVehicle: [],
      driverExpenses: {
        payDriverExpenses: 'paynow',
        driverExpensesValue: 0.00
      },
      observations: '',
      transfer: {
        observations: {
          origin: '',
          destination: ''
        }
      }
    },
    selectedVehicle: {
      priceCents: 0.00
    },
    serviceType: 'viagem',
    is_round_trip: true,
    errors: [],
    responseMessage: {},
    selectedVehicleType: 'all',
    daily: {
      vehicleType: '',
      observations: '',
      quotes: []
    },
    destinationDisabledDates: { to: new Date() },
    originDisabledDates: { to: new Date() },
    transferBackDisabledDates: { to: new Date() },
    reservationStartAtParsed: null,
    isLoading: true,
    loadingMessage: '',
    resetContent: true
  },
  mutations: {
    ORIGIN_SIMPLE_TRIP_PROP (state, payload) {
      state.simpleTrip.origin[payload.prop] = payload.value
    },
    SIMPLE_ROUND_TRIP_PROP (state, payload) {
      state.simpleTrip.destination[payload.prop] = payload.value
    },
    SIMPLE_TRIP_PROP (state, payload) {
      state.simpleTrip[payload.prop] = payload.value
    },
    UPDATE_COMPANY_GROUP (state, data) {
      state.companyGroup[data.prop] = data.value
    },
    UPDATE_COMPANY_GROUP_OPTIONS (state, data) {
      state.companyGroup.options = data
    },
    UPDATE_COMPANY_GROUP_STATUS (state, data) {
      state.hasCompanySelected = data
    },
    UPDATE_TRIP_TYPE (state, type) {
      state.tripType = type
    },
    UPDATE_TRIP_METAS (state, metas) {
      state.tripMetas = metas
    },
    // CLEANER
    CLEAN_TRIP_INFO (state) {
      state.origin = {
        address: '',
        city: '',
        lat: 0,
        lng: 0,
        date: '',
        time: '00:00',
        address_component: ''
      }
      state.destination = {
        address: '',
        city: '',
        lat: 0,
        lng: 0,
        date: '',
        time: '00:00',
        address_component: ''
      }
    },
    CLEAN_DAILY_INFO (state) {
      state.daily = {
        observations: '',
        quotes: []
      }
    },
    CLEAN_TRANSFER_INFO (state) {
      state.transfersGoing = {
        address: {
          origin: '',
          destination: ''
        },
        date: '',
        time: '00:00',
        isChecked: false,
        observations: ''
      }
      state.transfersRoundTrip = {
        address: {
          origin: '',
          destination: ''
        },
        date: '',
        time: '00:00',
        observations: ''
      }
    },
    updateServiceTerms (state, data) {
      state.companyDetails.service_terms = data
    },
    updateLoading(state, value){
      state.isLoading = value
    },
    resetState (state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState())
    },
    RECALCULATE_CUSTOM_ROUTER_DAYS (state) {
      var days = []
      var count = 0
      var startDate = Vue.prototype.moment(state.customRouter.initialDate)
      var now = startDate.clone(); var dates = []
      var endDate = new Date(state.customRouter.endDate)
      while (now.isSameOrBefore(endDate)) {
        count = days.length

        if (state.customRouter.days[count] && state.customRouter.days[count].routes.length > 0) {
          days.push({
            date: now.format('DD/MM/YYYY'),
            routes: state.customRouter.days[count].routes,
            active: true
          })
        } else {
          days.push({
            date: now.format('DD/MM/YYYY'),
            routes: [],
            active: true
          })
        }
        now.add(1, 'days')
      }

      state.customRouter.days = days
      state.customRouter.tripDays = days.length
    },
    SET_CUSTOM_ROUTER_STEP (state, step) {
      state.customRouter.step = step
    },
    START_CUSTOM_ROUTER_STEP (state) {
      state.customRouter.step = 1
    },
    CUSTOM_ROUTER_NEXT_STEP (state) {
      state.customRouter.step = state.customRouter.step + 1
    },
    CUSTOM_ROUTER_PREV_STEP (state) {
      state.customRouter.step = state.customRouter.step - 1
    },
    CUSTOM_ROUTER_UPDATE_STEP (state) {
      state.customRouter.step = state.customRouter.step + 1
      state.customRouter.step = state.customRouter.step - 1
    },
    HIDE_DESTINATION (state, data) {
      state.customRouter.days[data.day - 1].routes[data.route].hideDestination = true
    },
    SHOW_DESTINATION (state, data) {
      state.customRouter.days[data.day - 1].routes[data.route].hideDestination = false
    },
    SET_STOP_POINT (state, data) {
      state.stop_points[data.point] = data.value
    },
    SET_SIMPLE_TRIP_DAY_STOP_POINT (state, data) {
      state.simpleTrip.stop_points[data.index] = data.value
    },
    REMOVE_STOP_POINT (state, index) {
      state.simpleTrip.stop_points.splice(index, 1)
    },
    ADD_STOP_POINT (state) {
      state.simpleTrip.stop_points.push({
        address: '',
        city: '',
        lat: 0,
        lng: 0
      })
    },
    RESET_SIMPLE_ROUTES (state) {
      state.simpleTrip = {
        origin: {
          address: '',
          city: '',
          lat: 0,
          lng: 0,
          date: '',
          time: '00:00',
          address_component: ''
        },
        destination: {
          address: '',
          city: '',
          lat: 0,
          lng: 0,
          date: '',
          time: '00:00',
          address_component: ''
        },
        is_round_trip: false,
        stop_points: [],
        franchise: false
      }
    },
    RESET_CUSTOM_ROUTES (state) {
      state.customRouter = {
        hideDestination: false,
        step: 1,
        disabledDates: {
          to: new Date()
        },
        tripDays: 0,
        initialDate: '',
        endDate: '',
        days: [],
        routes: [],
        active: true
      }
    },
    CLEAN_CUSTOM_ROUTES (state) {
      state.customRouter.routes = []
      state.customRouter.days = []
    },
    ADD_CUSTOM_ROUTE (state, data) {
      var date = state.customRouter.days[data.day].date
      var payload = {
        time: '00:00',
        hideDestination: false,
        stop_points: [],
        date: date,
        origin: {
          address: '',
          city: '',
          lat: 0,
          lng: 0,
          date: '',
          time: '00:00',
          address_component: ''
        },
        destination: {
          address: '',
          city: '',
          lat: 0,
          lng: 0,
          date: '',
          time: '00:00',
          address_component: ''
        }
      }
      state.customRouter.days[data.day].routes.push(payload)
    },
    REMOVE_CUSTOM_ROUTE (state, value) {
      state.customRouter.days[value.date - 1].routes.splice(value.index, 1)
    },
    ADD_CUSTOM_ROUTE_DAY (state, days) {
      for (var count = 0; count < days; count++) {
        var routeDate = Vue.prototype.moment(state.customRouter.initialDate).add(count, 'days').format('DD/MM/YYYY')
        if (!state.customRouter.days.filter((day) => day.date === routeDate).length > 0) {
          state.customRouter.days[count] = {
            active: true,
            date: routeDate,
            routes: [
              {
                stop_points: [],
                time: '00:00',
                date: routeDate,
                origin: {
                  address: '',
                  city: '',
                  lat: 0,
                  lng: 0,
                  date: '',
                  time: '00:00',
                  address_component: ''
                },
                destination: {
                  address: '',
                  city: '',
                  lat: 0,
                  lng: 0,
                  date: '',
                  time: '00:00',
                  address_component: ''
                }
              }
            ]
          }
        }
      }

      if (state.customRouter.days.length > days) {
        for (var count = 0; count <= state.customRouter.days.length - days; count++) {
          state.customRouter.days.splice(days, count)
        }
      }
    },
    UPDATE_CUSTOM_ROUTE_DAY_STATUS (state, data) {
      state.customRouter.days[data.index].active = data.status
    },
    SET_CUSTOM_ROUTER_PROP (state, data) {
      if (data.prop === 'disabledDates') {
        state.customRouter.disabledDates = {
          to: data.value
        }
      } else {
        state.customRouter[data.prop] = data.value
      }
    },
    SET_CUSTOM_ROUTER_DAY_PROP (state, data) {
      state.customRouter.days[data.day - 1].routes[data.route][data.prop] = data.value
    },
    REMOVE_CUSTOM_ROUTER_DAY_STOP_POINT (state, data) {
      state.customRouter.days[data.day - 1].routes[data.route].stop_points.splice(data.index, 1)
    },
    SET_CUSTOM_ROUTER_DAY_STOP_POINT (state, data) {
      state.customRouter.days[data.day - 1].routes[data.route].stop_points[data.point] = data.value
    },
    ADD_CUSTOM_ROUTER_DAY_STOP_POINT (state, data) {
      state.customRouter.days[data.day - 1].routes[data.route].stop_points.push({
        address: '',
        city: '',
        lat: 0,
        lng: 0
      })
    },
    SET_TRANSFER_TYPE (state, type) {
      state.transferType = type
    },
    SET_LOADING_STATE (state, value, text = null) {
      state.isLoading = value
      state.loadingMessage = text ? text : false
    },
    TOGGLE_RESET_FORM(state, value) {
      state.resetContent = value
    },
    SET_TRIP_INFO (state, info) {
      state.tripInfo = info
    },
    SET_BLOCK_UI (state, value) {
      state.block_ui = value
    },
    SET_COMPANY_DETAILS (state, data) {
      state.companyDetails = data
    },
    LAST_LOGIN_EVENT (state, data) {
      state.lastLoginEvent = data
    },
    UPDATE_USER_DATA (state, data) {
      state.auth.userData[data.field] = data.value
    },
    SET_TRANSFER_DESTINATION (state, data) {
      state.transferDestination = data.data.transfers[0]
    },
    SET_PLACE (state, data) {
      state[data.prop]['address'] = data.addr_info.address
      state[data.prop]['city'] = data.addr_info.city
      state[data.prop]['lat'] = data.addr_info.lat
      state[data.prop]['lng'] = data.addr_info.lng
      state[data.prop]['address_component'] = data.addr_info.address_components
    },
    SET_VEHICLE_TYPE (state, slug) {
      state.selectedVehicleType = slug
    },
    SET_TYPE (state, type) {
      state.serviceType = type
    },
    SET_TIME (state, data) {
      state[data.prop].time = data.time
    },
    SET_DATE (state, data) {
      state[data.prop].date = data.date
    },
    SET_DURATION (state, duration) {
      state.is_round_trip = duration
    },
    SET_CHECKED (state, data) {
      state[data.prop].date = data.date
    },
    SET_SELECTED_VEHICLE (state, data) {
      state.selectedVehicle = data
    },
    SET_DRIVER_EXPENSES (state, data) {
      state.tripDetails.driverExpenses = data
    },
    SET_USE_VEHICLE_INFO (state, data) {
      if (state.tripDetails.useVehicle[data.index] != null) {
        Object.assign(state.tripDetails.useVehicle[data.index], data.value)
      } else {
        state.tripDetails.useVehicle.push(data.value)
      }
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    setUserData (state, data) {
      state.userData = data
    },
    setResponseMessage (state, responseMessage) {
      state.responseMessage = responseMessage
    },
    SAVE_SEARCH_DATA (state, data) {
      state.searchData.id = data.id
      state.searchData.uuid = data.uuid
    },
    SAVE_QUOTE_ID (state, uuid) {
      state.quoteID = uuid
    },
    MUST_SAVE_SEARCH (state, value) {
      state.mustSaveSearch = value
    },
    CALL_REGISTER_MODAL (state, value) {
      state.callRegisterModal = value
    },
    SET_STOPS (state, data) {
      if (state.tripDetails.stop_points[data.index] != null) {
        Object.assign(state.tripDetails.stop_points[data.index], data.addr_info)
      } else {
        state.tripDetails.stop_points.push(data.addr_info)
      }
    },
    DELETE_STOPS (state, data) {
      state.tripDetails.stop_points.splice(data.index, 1)
    },
    DELETE_USE_VEHICLE_INFO (state, data) {
      state.tripDetails.useVehicle.splice(data.index, 1)
    },
    SET_USAGE_DESTINATION_PROP (state, data) {
      state.tripDetails.useVehicle[data.index][data.prop] = data[data.prop]
    },
    UPDATE_VEHICLE_USAGE_PRICE (state, data) {
      state.tripDetails.useVehicle[data.index]['price'] = data.price
    },
    SET_AGREE_DRIVER_EXPENSES (state, data) {
      state.agree_driver_expenses = data.value
    },
    LOGIN_TO_VIEW_RESULTS (state, data) {
      state.loginToViewResults = data
    },
    SET_DAILY (state, data) {
      if (state.daily.quotes[data.index] != null) {
        Object.assign(state.daily.quotes[data.index], data)
      } else {
        state.daily.quotes.push(data)
      }
    },
    REMOVE_DAILY_UPDATED_PRICES (state, data) {
      state.daily.quotes = data.map((item) => {
        delete item['updated']
        return item
      })
    },
    SET_DAILY_OBJECT (state, data) {
      state.daily = data
    },
    SET_DAILY_QUOTES (state, data) {
      state.daily.quotes = data
    },
    DELETE_DAILY (state, data) {
      state.daily.quotes.splice(data, 1)
    },
    SET_DAILY_PROP (state, data) {
      if (state.daily.quotes[data.index]) {
        state.daily.quotes[data.index][data.prop] = data.value
      } else {
        state.daily.quotes[data.index] = []
        state.daily.quotes[data.index][data.prop] = data.value
      }
    },
    SET_ORDER_SUMMARY(state, data) {
      state.orderSummary[data.key] = data.value
    },
    SET_DAILY_OBSERVATION (state, data) {
      state.daily.observations = data.value
    },
    setOriginDisabledDates (state, data) {
      state.originDisabledDates.to = data
    },
    setDestinationDisabledDates (state, data) {
      state.destinationDisabledDates.to = data
    },
    SET_TRANSFER_GOING_PROP (state, data) {
      if (data.prop === 'origin' || data.prop === 'destination') {
        state.transfersGoing.address[data.prop] = data.value
      } else {
        state.transfersGoing[data.prop] = data.value
      }
    },
    SET_TRANSFER_ROUND_TRIP_PROP (state, data) {
      if (data.prop === 'origin' || data.prop === 'destination') {
        state.transfersRoundTrip.address[data.prop] = data.value
      } else {
        state.transfersRoundTrip[data.prop] = data.value
      }
    },
    SET_TRANSFER_SEARCH (state, data, roundTrip) {
      if (roundTrip) {
        state.transfersGoing = {
          date: data.roundTrip.date,
          address: data.roundTrip.address,
          time: data.roundTrip.time
        }
        state.transfersRoundTrip = {
          date: data.address
        }
      } else {
        state.transfersGoing = {
          date: data.date,
          address: data.address,
          time: data.time
        }
      }
    },
    SET_TRANSFER_PRICE (state, data) {
      state.transfers = data
    },
    SET_DESTINATION_DETAILS (state, data) {
      state.destination = data
    },
    SET_ORIGIN_DETAILS (state, data) {
      state.destination = data
    },
    SET_TRIP_DETAILS (state, data) {
      if (data.prop === 'destination') {
        state.destination = data.value
      } else {
        state.origin = data.value
      }
    },
    RESET_SEARCH_FORM_FIELDS (state, type) {
      if (type === 'trip') {
        state.transfersGoing = {
          address: {
            origin: '',
            destination: ''
          },
          date: '',
          time: '00:00',
          isChecked: false,
          observations: ''
        }
        state.transfersRoundTrip = {
          address: {
            origin: '',
            destination: ''
          },
          date: '',
          time: '00:00',
          observations: ''
        }
      }
    },
    setReservationDateParsed (state, data) {
      state.reservationStartAtParsed = data
    },
    setTransferBackDisabledDates (state, data) {
      state.transferBackDisabledDates.to = data
    }
  },
  getters: {
    errors: state => state.errors,
    responseMessage: state => state.responseMessage,
    customRouterStep: state => state.customRouter.step,
    getOrderSummary: state => state.orderSummary,
  },
  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },
    updateLoading ({ commit }, value) {
      commit('SET_LOADING_STATE', value)
    },
    customRouterNextStep ({ commit }) {
      commit('CUSTOM_ROUTER_NEXT_STEP')
    },
    customRouterNextStep ({ commit }) {
      commit('CUSTOM_ROUTER_PREV_STEP')
    },
    updateRouterNextStep ({ commit }) {
      commit('CUSTOM_ROUTER_UPDATE_STEP')
    },
    get_result ({ commit, state }) {
      /* eslint-disable no-new */
      new Promise((resolve, reject) => {
        axios.post(process.env.API_URL + 'calculate', {
          origin: state.origin,
          destination: state.destination,
          vehicles: state.companyDetails.vehicles,
          is_round_trip: state.is_round_trip,
          stop_points: [],
          vehicle_type: this.$store.state.selectedVehicleType,
          customerId: this.$store.state.auth.userData.id
        }).then((r) => {
          return r.data
        })
      })
    }
  },
  modules: {
    auth,
    cart,
    quote,
  },
  plugins: [
    createPersistedState()
  ]
})
