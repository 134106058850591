<template>
    <div class="content-loader" :style="cssProps">
      <svg class="d-none d-lg-flex" version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1141 170" width="1141" height="170">
          <defs>
            <linearGradient id="myGradient" gradientTransform="rotate(20)">
            <stop offset="5%"  stop-color="#eee">
                <animate attributeName="stop-color" values="#b7b7b7; #cbcbcb; #b7b7b7" dur="1.2s" repeatCount="indefinite"></animate>
            </stop>
            <stop offset="95%" stop-color="#aaa">
                <animate attributeName="stop-color" values="#b7b7b7; #DDDDDD; #b7b7b7" dur="2s" repeatCount="indefinite"></animate>
            </stop>
            </linearGradient>
          </defs>
          
          <path id=" " class="background" d="m12 14c0-2.8 2.2-5 5-5h1100c2.8 0 5 2.2 5 5v138c0 2.8-2.2 5-5 5h-1100c-2.8 0-5-2.2-5-5z"/>
          <path id="Shape 2" href="#myGradient" fill="url('#myGradient')" d="m892 101c0-2.8 2.2-5 5-5h207c2.8 0 5 2.2 5 5v34c0 2.8-2.2 5-5 5h-207c-2.8 0-5-2.2-5-5z"/>
          <path id="Shape 12" href="#myGradient" fill="url('#myGradient')" d="m27 27c0-2.8 2.2-5 5-5h196c2.8 0 5 2.2 5 5v111c0 2.8-2.2 5-5 5h-196c-2.8 0-5-2.2-5-5z"/>
          <path id="Shape 3" href="#myGradient" fill="url('#myGradient')" d="m892 72c0-2.8 2.2-5 5-5h147c2.8 0 5 2.2 5 5v4c0 2.8-2.2 5-5 5h-147c-2.8 0-5-2.2-5-5z"/>
          <path id="Shape 4" href="#myGradient" fill="url('#myGradient')" d="m892 35c0-2.8 2.2-5 5-5h142c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5h-142c-2.8 0-5-2.2-5-5z"/>
          <path id="Shape 5" href="#myGradient" fill="url('#myGradient')" d="m253 31c0-2.8 2.2-5 5-5h351c2.8 0 5 2.2 5 5v20c0 2.8-2.2 5-5 5h-351c-2.8 0-5-2.2-5-5z"/>
          <path id="Shape 6" href="#myGradient" fill="url('#myGradient')" d="m253 87c0-2.8 2.2-5 5-5h104c2.8 0 5 2.2 5 5v5c0 2.8-2.2 5-5 5h-104c-2.8 0-5-2.2-5-5z"/>
          <path id="Shape 7" href="#myGradient" fill="url('#myGradient')" d="m253 114c0-2.8 2.2-5 5-5h33c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5h-33c-2.8 0-5-2.2-5-5z"/>
          <path id="Shape 8" href="#myGradient" fill="url('#myGradient')" d="m300 114c0-2.8 2.2-5 5-5h94c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5h-94c-2.8 0-5-2.2-5-5z"/>
          <path id="Shape 9" href="#myGradient" fill="url('#myGradient')" d="m408 114c0-2.8 2.2-5 5-5h82c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5h-82c-2.8 0-5-2.2-5-5z"/>
          <path id="Shape 10" href="#myGradient" fill="url('#myGradient')" d="m506 115c0-2.8 2.2-5 5-5h72c2.8 0 5 2.2 5 5v13c0 2.8-2.2 5-5 5h-72c-2.8 0-5-2.2-5-5z"/>
          <path id="Shape 11" href="#myGradient" fill="url('#myGradient')" d="m592 114c0-2.8 2.2-5 5-5h61c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5h-61c-2.8 0-5-2.2-5-5z"/>
      </svg>

      <svg class="d-flex d-lg-none" version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560 520" width="560" height="420">

          <defs>
            <linearGradient id="mobileGradient" gradientTransform="rotate(20)">
            <stop offset="5%"  stop-color="#eee">
                <animate attributeName="stop-color" values="#b7b7b7; #cbcbcb; #b7b7b7" dur="1.2s" repeatCount="indefinite"></animate>
            </stop>
            <stop offset="95%" stop-color="#aaa">
                <animate attributeName="stop-color" values="#b7b7b7; #DDDDDD; #b7b7b7" dur="2s" repeatCount="indefinite"></animate>
            </stop>
            </linearGradient>
        </defs>
        <path  href="#mobileGradient" fill="url('#mobileGradient')" d="m19.9 624c0-2.8 2.3-5 5-5h510c2.8 0 5 2.2 5 5v57c0 2.8-2.2 5-5 5h-510c-2.7 0-5-2.2-5-5z"/>
        <path  href="#mobileGradient" fill="url('#mobileGradient')" d="m20 20c0-2.8 2.2-5 5-5h510c2.8 0 5 2.2 5 5v214c0 2.8-2.2 5-5 5h-510c-2.8 0-5-2.2-5-5z"/>
        <path  href="#mobileGradient" fill="url('#mobileGradient')" d="m21.4 291c0-2.8 2.3-5 5-5h508.6c2.8 0 5 2.2 5 5v34.2c0 2.7-2.2 5-5 5h-508.6c-2.7 0-5-2.3-5-5z"/>
        <path  href="#mobileGradient" fill="url('#mobileGradient')" d="m20 360.9c0-2.7 2.2-5 5-5h250c2.8 0 5 2.3 5 5v35.1c0 2.8-2.2 5-5 5h-250c-2.8 0-5-2.2-5-5z"/>
        <path  href="#mobileGradient" fill="url('#mobileGradient')" d="m20 435.7c0-2.8 2.2-5 5-5h51.8c2.7 0 5 2.2 5 5v25.3c0 2.8-2.3 5-5 5h-51.8c-2.8 0-5-2.2-5-5z"/>
        <path  href="#mobileGradient" fill="url('#mobileGradient')" d="m87.5 435.7c0-2.8 2.3-5 5-5h139.4c2.8 0 5 2.2 5 5v25.3c0 2.8-2.2 5-5 5h-139.4c-2.7 0-5-2.2-5-5z"/>
        <path  href="#mobileGradient" fill="url('#mobileGradient')" d="m242.7 435.7c0-2.8 2.2-5 5-5h122.1c2.8 0 5 2.2 5 5v25.3c0 2.8-2.2 5-5 5h-122.1c-2.8 0-5-2.2-5-5z"/>
        <path  href="#mobileGradient" fill="url('#mobileGradient')" d="m383.4 437.1c0-2.7 2.3-5 5-5h107.8c2.8 0 5 2.3 5 5v23.9c0 2.8-2.2 5-5 5h-107.8c-2.7 0-5-2.2-5-5z"/>
      </svg>
        
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContentLoader',
    computed: {
      cssProps () {
        return {
          '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
        }
      }
    }
  }
  </script>
  
  <style>

    .content-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-radius: 5px;
        -webkit-box-shadow: 0px 1px 6px rgb(61 67 76 / 15%);
        box-shadow: 0px 1px 6px rgb(61 67 76 / 15%);
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 1.5rem;
        position: relative;
        padding:0;
    }

    .content-loader svg {
        background-color: transparent;
    }

    .content-loader .background { fill: #ebebeb } 
    .content-loader .content { fill: #c5c5c5 } 


</style>
  