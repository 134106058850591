<template>
    <div>
        <div class="heading mb-3 ">
            <h4>Qual o período do seu roteiro?</h4>
        </div>
        <div class="content">
            <b-row>
                <b-col xl="6" xs="12">
                    <div class="input-with-icon place-field d-flex">
                        <div class="icon">
                            <b-icon-calendar class="mr-2"></b-icon-calendar>
                        </div>
                        <div class="input ml-3 w-100">
                            <date-picker
                                icon-calendar=""
                                format="DD/MM/YYYY"
                                value-type="YYYY/MM/DD"
                                type="date"
                                placeholder="Data da ida"
                                :disabled-date="notBeforeToday"
                                v-model="$store.state.customRouter.initialDate"
                                @change="setProp($event, 'initialDate')"
                            ></date-picker>
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" xs="12">
                    <div class="input-with-icon place-field d-flex">
                        <div class="icon">
                            <b-icon-calendar class="mr-2"></b-icon-calendar>
                        </div>
                        <div class="input ml-3 w-100">
                            <date-picker
                                icon-calendar=""
                                format="DD/MM/YYYY"
                                value-type="YYYY/MM/DD"
                                type="date"
                                placeholder="Data da volta"
                                :disabled-date="excludeOriginDates"
                                v-model="$store.state.customRouter.endDate"
                                :default-value="originDate"
                                @change="setProp($event, 'endDate')"
                            ></date-picker>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-row class="mb-3 trip-information" v-if="(!$store.state.auth.userData || !$store.state.auth.userData.name || !$store.state.auth.userData.tel || !$store.state.auth.userData.email)">
                <b-col xl="12" xs="12">
                    <div class="heading mb-1 mt-4">
                        <h6>Informe seus dados para prosseguir</h6>
                    </div>
                </b-col>
                <b-col xl="6" xs="12" class="mt-2">
                    <div class="input-with-icon user-field d-flex">
                    <div class="icon">
                        <b-icon-person></b-icon-person>
                    </div>
                    <div class="input ml-3 w-100">
                        <input type="text" v-model="user.name" @change="($event) => updateUserField($event, 'name')" placeholder="Seu nome" name="user-name" id="">
                    </div>
                    </div>
                </b-col>

                <b-col class="mt-2" xl="6" xs="12">
                    <div class="input-with-icon user-field d-flex">
                    <div class="icon">
                        <b-icon-envelope></b-icon-envelope>
                    </div>
                    <div class="input ml-3 w-100">
                        <input type="text" v-model="user.email" placeholder="Seu email" name="user-email" id="">
                    </div>
                    </div>
                </b-col>

                <b-col class="mt-2" xl="12" xs="12">
                    <div class="input-with-icon user-field d-flex">
                    <div class="icon">
                        <b-icon-whatsapp></b-icon-whatsapp>
                    </div>
                    <div class="input ml-3 w-100">
                        <input type="text" placeholder="Seu telefone" v-model="user.tel" name="user-phone" id="">
                    </div>
                    </div>
                </b-col>

            </b-row>
                
        </div>
    </div>
</template>

<script>
// import DatePicker from '@sum.cumo/vue-datepicker'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/pt-br';

export default {
    name: 'StepOne',
    components: { DatePicker },
    data() {
        return {
            user: this.$store.state.auth.tmpUserData ? this.$store.state.auth.tmpUserData : {
                name: null,
                email: null,
                tel: null
            },
            originDate: '',
            destinationDate: '',
            reservationStartAt: parseInt(this.$store.state.companyDetails.reservationStartAt)
        };
    },

    mounted() {
       
    },

    methods: {
        excludeOriginDates(date, max) {
            return date < new Date(this.$store.state.customRouter.initialDate);
        },
        notBeforeToday(date) {
            return date < new Date().setDate(new Date().getDate() + this.reservationStartAt);
        },
        setProp (value, prop) {
            if(prop === 'initialDate' || prop === 'endDate') {
                this.$store.commit('SET_CUSTOM_ROUTER_PROP', {
                    value: this.moment(value).format('YYYY/MM/DD'),
                    prop: prop
                })
            }else{
                this.$store.commit('SET_CUSTOM_ROUTER_PROP', {
                    value: value,
                    prop: prop
                })
            }
            
            // this.$store.commit('RECALCULATE_CUSTOM_ROUTER_DAYS');
            this.$forceUpdate();

        }
    },
};
</script>

<style lang="scss" scoped>

.place-field {
    border: 1px solid #ced4da;
    border-radius: 150px;
    padding: 8px 15px;
}

</style>