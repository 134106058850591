<template>
  <b-form-group class="col-sm-12 trip-type">
    <div class="d-flex align-content-center col-lg-12 trip-type" :style="cssProps">
      <label for="round-trip-type">
        <input type="radio" @click="setTripDuration(true)" id="round-trip-type" name="duration" value="roundtrip" :checked="roundTripChecked()">
        <span>Ida e Volta</span>
      </label>
      <label for="one-way-trip-type">
        <input type="radio" @click="setTripDuration(false)" id="one-way-trip-type" name="duration" value="onewaytrip" :checked="oneWayTripChecked()">
        <span>Somente Ida</span>
      </label>
    </div>
   </b-form-group>
</template>

<script>
export default {
  name: 'TripType',
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  },
  methods: {
    setTripDuration (duration) {
      this.$store.commit('SET_DURATION', duration)
    },
    roundTripChecked () {
      let checked = this.$store.state.is_round_trip ? 'checked' : ''
      return checked
    },
    oneWayTripChecked () {
      let checked = !this.$store.state.is_round_trip ? 'checked' : ''
      return checked
    }
  }
}
</script>

<style lang="scss" scoped>

input:checked + span {
  color: var(--default-color)!important;
}

.trip-type {
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  height: 45px;

  div {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 0;
  }

  label {

    margin-right: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    &:hover {
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
