<template>
  <select required v-model="selectedVehicleType" v-on:change="updateVehicleType" class="form-control">
    <option value="all">Todos tipos de veículo</option>
    <option v-for="vehicleType in vehicleTypes" :key="vehicleType.slug" :value="vehicleType.slug">{{vehicleType.name}}</option>
  </select>
</template>

<script>
export default {
  name: 'VehicleSelect',
  data: function () {
    return {
      selectedVehicleType: ''
    }
  },
  computed: {
    vehicleTypes () {
      return this.$store.state.companyDetails.vehicleTypes
    }
  },
  methods: {
    updateVehicleType: function () {
      this.$store.commit('SET_VEHICLE_TYPE', this.selectedVehicleType)
    }
  },
  mounted () {
    var commitedVehicleType = this.$store.state.selectedVehicleType

    this.selectedVehicleType = commitedVehicleType || 'onibus'
  }
}
</script>
