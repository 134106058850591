<template>
  <div :class="{
    'result-item': template !== 'order',
    'order-item': template === 'order',
    'result-expanded': vehicle.visible,
    'block-ui': !this.user && Boolean(parseInt(this.$store.state.companyDetails.whitelabel.block_results_non_logged_user)),
    }" :style="cssProps" :id="'vehicle-'+vehicle.id">

    <div class="first-row row m-0 w-100">
      <div class="col-12 d-flex flex-row align-items-center vehicle-card">
        <div class="col-image" v-if="template !== 'order'">
          <img  :src="vehicle.image || vehicle.thumbnail" class="vehicle-image">
        </div>
        <div class="col-info">
          <h3 class="title">{{ formatTitleVehicle(vehicle.title || vehicle.name) }}</h3>
          <div class="configurations-wrapper" v-if="(template !== 'order' && vehicle.configurations.length > 0 || vehicle.air_conditioning || vehicle.bathroom)">
            <p class="font-weight-bold">Configurações:</p>
            <p class="configurations">
              <span v-if="vehicle.air_conditioning">
                Ar condicionado
              </span>
              <span v-if="vehicle.bathroom">
                Banheiro
              </span>

              <span v-for="(config, index) in vehicle.configurations" :key="index">
                {{config}}
              </span>
            </p>
          </div>

          <trip-address :vehicle="vehicle" :extra="extra" v-if="!vehicle.usingTwoTrips && template === 'order'"/>

        </div>
        <div class="col-price">
          <div class="price" v-if="!vehicle.visible">
            <span class="value">{{ get_vehicle_price(vehicle) | currency }}</span>
          </div>
          <div class="pricePerSeat" v-if="!vehicle.visible && pricePerSeat(vehicle)">
            <span class="value">{{ pricePerSeat(vehicle) | currency }} por assento</span>
          </div>
          <div class="more-info" v-if="this.extra.showDetails">
          <a @click="showVehicleDetails(index, vehicle.id)">
            <span >{{vehicle.visible ? 'Ver menos' : 'Ver detalhes'}}
              <b-icon-arrow-up-short v-if="vehicle.visible"></b-icon-arrow-up-short>
              <b-icon-arrow-down-short v-else></b-icon-arrow-down-short>
            </span>
          </a>
          <!-- <a v-else @click="hideVehicleDetails(index)">
              <span >Ver Menos</span>
          </a> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row-trip-info m-0 w-100" v-if="vehicle.visible">
      <div class="img-container">
        <div class="col-image"  @click="showMultiple(index)" v-for="(img, index) in gallery" :key="index">
          <img style="cursor:pointer" :src="img" class="img-fluid">
        </div>
        <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="img"
        :index="indexImg"
        @hide="handleHide"
        >
        </vue-easy-lightbox>
      </div>
    </div>
    <transition name="fade" v-if="extra.showDetails">
      <div class="slide-out" v-show="vehicle.visible" style="width:100%">

        <trip-address :vehicle="vehicle" :extra="extra" v-if="!vehicle.usingTwoTrips"/>
        <split-trip-address v-else :vehicle="vehicle"/>

        <div class="row row-trip-info">
          <div class="col-md-12" v-if="getStopPoints.length > 0 && this.$store.state.serviceType == 'viagem'">
            <div class="usage-item align-items-start d-flex mb-4 p-4 w-100 flex-column">
              <h4 class="trip-info-title">Outros pontos de embarque ou desembarque</h4>
              <div v-for="(stop, index) in getStopPoints" :key="'stop' + index">
                <b-icon-geo-alt></b-icon-geo-alt> Ponto {{index +1}} : {{stop.address}}
              </div>
            </div>
          </div>

          <div class="col-md-12 m-0" v-if="getUsageDestination.length > 0 && this.$store.state.serviceType == 'viagem'">
            <h4 class="trip-info-title">Uso de {{ getUsageDestination.length > 1 ? 'veículos' : 'veículo' }} no destino</h4>
            <div v-for="(use, index) in getUsageDestination" :key="'use' + index" style="width: 100%;" :class="getUsageDestination.length > 1 ? 'mb-3' : 'mb-0'">
              <div v-if="use" class="row usage-item mb-4">
                <div class="col-md-6 col-12 m-0 pr-5">
                  <div class="trip-info">
                    <b-icon-geo-alt></b-icon-geo-alt>
                    {{ use.addr_info.address }}
                  </div>
                  <div class="trip-info">
                    <b-icon-calendar3></b-icon-calendar3>
                    {{ use.date | date_result_list}}
                  </div>
                  <div class="trip-info">
                    <b-icon-clock></b-icon-clock>
                    {{ use.time }}
                  </div>
                  <div class="trip-info">
                    <b-icon-clock></b-icon-clock>
                    {{ use.franchise }}
                  </div>
                </div>
                <div class="col-md-6 col-12 m-0 d-flex justify-content-center align-items-end">
                  <div class="price-wrapper">
                  <div class="price">
                    <span class="value">{{ getUsageVehiclePrice(vehicle, use.selectedIndex, index) | currency }}</span>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="this.$store.state.tripMetas.driverExpenses != 0 && !this.vehicle.usingTwoTrips ">
            <div class="col-md-12 mt-2">
              <h4 class="trip-info-title">Contratar despesas de hospedagem e alimentação do(s) motorista(s)?</h4>
            </div>
            <driver-expenses :index="index" :error="show_agree_error" />
          </div>

          <div class="col-md-12 mt-0">
            <h4 class="trip-info-title">
              Observações adicionais
              <span v-if="this.$store.state.serviceType != 'viagem'" class="required-badge">Obrigatório</span>
            </h4>
            <div class="w-100">
              <div class="row usage-item w-100 p-0">
                  <div class="col-12">

                    <textarea v-if="this.$store.state.serviceType !== 'transfer'" v-model="$store.state.tripDetails.observations" class="additional-info" :name="'additional_info_' + index" :id="'additional_info_' + index" cols="30" rows="3" style="resize: none" placeholder="Escreva aqui observações sobre a sua viagem"></textarea>

                    <textarea v-else v-model="$store.state.tripDetails.transfer.observations[$store.state.transferType]" class="additional-info" :name="'additional_info_' + index" :id="'additional_info_' + index" cols="30" rows="3" style="resize: none" placeholder="Escreva aqui observações sobre a sua viagem"></textarea>

                  </div>
                </div>
            </div>
          </div>

          <div class="col-md-12 mt-4" v-if="vehicle.totalAvailable > 0">
            <h4 class="trip-info-title">
              Quantos veículos você precisa para essa viagem?
            </h4>
            <div class="w-100">
              <div class="row usage-item border-dark w-100 p-0">
                <div class="d-flex d-xl-none total-available w-100">
                  <select name="" id="" :v-model="vehicleQuantity" v-on:change="updateVehicleQuantity($event)">
                    <option v-for="index in parseInt(totalAvailable)" :key="index"  :value="index">
                    {{index == 1 ? index + ' Veículo' : index + ' Veículos'}}
                    </option>
                  </select>
                </div>
                <div class="d-none d-xl-flex total-available w-100">
                  <select name="" id="" :v-model="vehicleQuantity" v-on:change="updateVehicleQuantity($event)">
                    <option v-for="index in parseInt(totalAvailable)" :key="index"  :value="index">
                    {{index == 1 ? index + ' Veículo' : index + ' Veículos'}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row-actions align-items-center">
          <div class="col-extended-price">
            <p class="price-label">Valor Total:</p>
            <div class="price">
              <span class="value">{{ this.get_vehicle_price(vehicle) | currency }}</span>

              <span class="price-per-seat">{{ pricePerSeat(vehicle) | currency }} por assento</span>
            </div>
          </div>
          <div class="col-actions col-xl-6 col-12 pl-xl-0 align-items-center justify-content-end">
            <div v-if="this.extra.passagengerList">
               <div class="col-12 order-item-actions">
                <a href="#" @click.prevent="openPassagengerList(item)" class="order-item-action send-list">Enviar lista de passageiros</a>
                <a href="#" @click.prevent="downloadContract(item)" class="btn btn-outline-primary order-item-action generate-contract mt-3">Baixar contrato</a>
                <!-- <a target="_blank" v-if="this.order.payment.method === 'bank_slip'" :href="this.order.payment_link" class="btn btn-order-item-action"><span><font-awesome-icon class="icon-action" icon="file-invoice-dollar"></font-awesome-icon> VER BOLETO</span></a> -->
              </div>
            </div>

            <div v-else>

              <div class="d-flex justify-content-xl-end justify-content-between flex-column flex-xl-row d-xl-flex">
                <save-or-print-modal :quote="this.$store.state.quote.quote.items[vehicle.id]" :index="index" :vehicle="this.vehicles[index]" :setSelectedVehicle="(vehicleIndex, redirect) => setSelectedVehicle(vehicleIndex, 'quote')"/>
                <div class="book-button-wrappper" v-if="vehicle.totalAvailable > 0">
                  <a v-on:click="setSelectedVehicle(index, true)" class="book-button">Reservar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>
 
<script>
import { mapGetters } from 'vuex'
import DriverExpenses from '@/components/trip-details/DriverExpenses.vue'
import SaveOrPrintModal from '@/components/modals/SaveOrPrintModal.vue'
import VueEasyLightbox from 'vue-easy-lightbox'
import utils from '@/components/mixins/utils'
import TripAddress from './TripDetails/TripAddress.vue'
import SplitTripAddress from './TripDetails/SplitTripAddress.vue'

export default {
  name: 'ViagemResultItem',
  props: ['vehicle', 'index', 'vehicles', 'extra', 'template'],
  data () {
    return {
      blockUI: !this.user && Boolean(parseInt(this.$store.state.companyDetails.whitelabel.block_results_non_logged_user)),
      isVisible: false,
      visible: false,
      gallery: [],
      img: '',
      indexImg: 0,
      stop_points: [],
      show_agree_error: false,
      vehicleType: this.getVehicleType(this.vehicle.type),
      vehicleQuantity: 1,
      totalAvailable: 1,
      quote: this.$store.state.quote.items,
      vehicleUsageQuotes: this.$store.state.companyDetails.vehicleUsageQuotes,
      vehicleUsagePrice: this.sumVehicleUsageTotals()
    }
  },
  components: { DriverExpenses, SaveOrPrintModal, VueEasyLightbox, TripAddress, SplitTripAddress },
  mixins: [
    utils
  ],
  computed: {
    ...mapGetters('auth', ['user']),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.primaryColor
    },
    secondaryColor () {
      return this.$store.state.companyDetails.whitelabel.secondaryColor
    },
    origin () {
      let origin = ''

      if (this.$store.state.tripTrip === 'customRouter') {
        origin = this.$store.state.simpleTrip.origin.address
      } else {
        origin = this.$store.state.simpleTrip.origin.address
      }

      return origin
    },
    originTime () {
      let originTime = ''

      if (this.$store.state.tripTrip === 'customRouter') {
        originTime = this.$store.state.simpleTrip.origin.time
      } else {
        originTime = this.$store.state.simpleTrip.origin.time
      }

      return originTime
    },
    originDate () {
      let originDate = ''

      if (this.$store.state.tripTrip === 'customRouter') {
        originDate = this.$store.state.simpleTrip.origin.date
      } else {
        originDate = this.$store.state.simpleTrip.origin.date
      }

      return originDate
    },
    destination () {
      let destination = ''

      if (this.$store.state.tripTrip === 'customRouter') {
        destination = this.$store.state.simpleTrip.destination.address
      } else {
        destination = this.$store.state.simpleTrip.destination.address
      }

      return destination
    },
    destinationTime () {
      let destinationTime = ''

      if (this.$store.state.tripTrip === 'customRouter') {
        destinationTime = this.$store.state.simpleTrip.destination.time
      } else {
        destinationTime = this.$store.state.simpleTrip.destination.time
      }

      return destinationTime
    },
    destinationDate () {
      let destinationDate = ''

      if (this.$store.state.tripTrip === 'customRouter') {
        destinationDate = this.$store.state.simpleTrip.destination.date
      } else {
        destinationDate = this.$store.state.simpleTrip.destination.date
      }

      return destinationDate
    },
    getStopPoints () {
      return this.$store.state.simpleTrip.stop_points
    },
    getUsageDestination () {
      return this.$store.state.tripDetails.useVehicle
    }
  },
  created () {
    this.$store.commit('SET_AGREE_DRIVER_EXPENSES', { value: false })
    this.$store.commit('SET_DRIVER_EXPENSES', {
      value: this.$store.state.tripDetails.driverExpenses.driverExpensesValue,
      payDriverExpenses: 'paylater',
    })
  },
  methods: {
    openPassagengerList (item) {
      this.passengers = []
      this.editing_item = item
      this.list_passanger_title = item.vehicle.name
      this.passengers = item.trip.passengers ? item.trip.passengers.value : []

      this.$emit('open-modal', this.passengers)
    },
    usageVehicleType (type) {
      switch (type) {
        case 'onibus':
          return 'onibus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro_onibus'
        case 'carro' :
          return 'carro'
        default:
          return 'onibus'
      }
    },
    sumVehicleUsageTotals () {
      let totals = 0
      if (this.$store.state.tripDetails.useVehicle) {
        let $scope = this
        this.$store.state.tripDetails.useVehicle.map(function (quote) {
          let vehicleType = $scope.usageVehicleType($scope.vehicle.type)
          totals += $scope.$store.state.companyDetails.vehicleUsageQuotes[quote.selectedIndex][vehicleType] * $scope.vehicle.factor_usage
        })
      }

      return totals
    },
    getUsageVehiclePrice (vehicle, quote, index) {
      let usagePrice = this.vehicleUsageQuotes[quote][this.usageVehicleType(vehicle.type)] * vehicle.factor_usage
      // this.$store.commit('UPDATE_VEHICLE_USAGE_PRICE', {index: index, price: usagePrice});
      return usagePrice
    },
    get_vehicle_price (vehicle) {
        var extraCost = 0;
        var vehiclePrice = 0;
        if(!vehicle.usingTwoTrips) {
          if(this.$store.state.tripDetails.driverExpenses.payDriverExpenses == 'paynow') {
            extraCost = this.$store.state.tripDetails.driverExpenses.driverExpensesValue;
          }
          
          vehiclePrice = vehicle.priceCents;

          return vehiclePrice + extraCost;
        }
        
        return vehicle.metas.priceCents;
    },
    updateVehicleQuantity: function (event) {
      this.vehicleQuantity = event.target.value === '' ? 1 : parseInt(event.target.value)
    },
    total (vehiclePrice, index) {
      let coefficient = 1
      let vehicleType = this.getVehicleType(this.vehicle.type)
      let total = 0

      if (this.$store.state.serviceType === 'transfer') {
        this.transferPrice = Number(this.extra.transfer[vehicleType + '_price'])
        this.transferRoundTripPrice = Number((this.extra.transfer_round_trip != null ? this.extra.transfer_round_trip[vehicleType + '_price'] : 0))
        total = this.extra.transfer_state === 'origin' || this.extra.transfer_state === 'onlyOrigin' ? vehiclePrice : this.transferRoundTripPrice
        if (total == 0) {
          total = Number(vehiclePrice)
        }
        coefficient = this.vehicle.factor_transfer
      } else if (this.$store.state.serviceType === 'diaria') {
        let prices = []
        this.$store.state.daily.quotes.forEach((q, index) => {
          prices[index] = parseFloat(q.quote[this.getVehicleType(this.vehicle.type)])
        })
        for (var i = 0; i < prices.length; i++) {
          total += prices[i]
        }
        coefficient = this.vehicle.factor_daily
      } else {
        total = parseFloat(vehiclePrice) + parseFloat(this.$store.state.tripDetails.driverExpenses.driverExpensesValue)
      }
      return (total * coefficient + this.vehicleUsagePrice) * this.vehicleQuantity
    },
    pricePerSeat (vehicle) {
      if (this.extra.transfer) {
        let vehicleType = this.getVehicleType(this.vehicle.type)
        let total = 0
        this.transferPrice = Number(this.extra.transfer[vehicleType + '_price'])
        this.transferRoundTripPrice = Number((this.extra.transfer_round_trip != null ? this.extra.transfer_round_trip[vehicleType + '_price'] : 0))
        total = this.transferPrice + this.transferRoundTripPrice + parseFloat(this.$store.state.tripDetails.driverExpenses.driverExpensesValue)

        return total / parseFloat(vehicle.capacity)
      } else if (this.extra.daily) {
        return vehicle ? this.get_vehicle_price(vehicle) / vehicle.capacity : 0
      } else {
        return this.get_vehicle_price(vehicle) / parseFloat(vehicle.capacity)
      }
    },
    setSelectedVehicle (index, type) {
      let $scope = this
      if (type === 'quote') {
        this.$store.commit('quote/clear', {})
      }

      var hasDriverExpensesValue = this.$store.state.tripDetails.driverExpenses.driverExpensesValue || this.$store.state.tripDetails.driverExpenses.driverExpensesValue == 0 ? true : false;
      if (this.$store.state.tripDetails.driverExpenses.payDriverExpenses == 'paylater' && hasDriverExpensesValue && !this.$store.state.agree_driver_expenses && this.$store.state.tripType === 'roundtrip' && type != 'quote') {
        this.$swal({
          title: 'Erro!',
          timer: 2500,
          text: 'É obrigatório concordar com as condições da opção selecionada',
          showDenyButton: false,
          showConfirmButton: false,
          icon: 'error'
        })
        return false
      }

      let tripItems = []
      for (var tripQuantity = 1; tripQuantity <= this.vehicleQuantity; tripQuantity++) {
        let tripData = ''
        var driverExpensesObject = this.$store.state.tripDetails.driverExpenses;
        if(!this.$store.state.tripMetas.driverExpenses > 0) {
          driverExpensesObject = {
            enable: false,
            driverExpensesValue: 0,
            payDriverExpenses: 'paylater'
          }
        }
        if (this.$store.state.tripType == 'customtrip') {
          tripData = {
            search_id: this.$store.state.searchData.id,
            search_uuid: this.$store.state.searchData.uuid,
            selectedVehicle: this.vehicle,
            type: this.$store.state.tripType,
            customRouter: this.$store.state.customRouter,
            driverExpenses: {
              enable: this.$store.state.tripMetas.driverExpenses > 0,
              ...driverExpensesObject
            },
            metas: {
              ...this.$store.state.tripMetas,
              ...this.vehicle.metas
            },
          }
        } else {
          tripData = {
            search_id: this.$store.state.searchData.id,
            search_uuid: this.$store.state.searchData.uuid,
            selectedVehicle: this.vehicle,
            type: this.$store.state.tripType,
            isRoundTrip: this.$store.state.simpleTrip.is_round_trip,
            origin: this.$store.state.simpleTrip.origin,
            destination: this.$store.state.simpleTrip.destination,
            stop_points: this.$store.state.simpleTrip.stop_points,
            driverExpenses: {
              enable: this.$store.state.tripMetas.driverExpenses > 0,
              ...this.$store.state.tripDetails.driverExpenses
            },
            metas: {
              ...this.$store.state.tripMetas,
              ...this.vehicle.metas
            }
          }
          if (this.$store.state.simpleTrip.franchise) {
            tripData.franchise = this.$store.state.simpleTrip.franchise
          }
        }

        if(this.$store.state.tripDetails.observations){
          tripData['observations'] = this.$store.state.tripDetails.observations
        }

        if (type === 'quote') {
          this.$store.commit('quote/setQuoteItem', {
            index: this.vehicle.id,
            data: tripData,
            companyDetails: this.$store.state.companyDetails
          })
        } else {

          this.$store.commit('quote/setQuoteItem', {
            index: this.vehicle.id,
            data: tripData,
            companyDetails: this.$store.state.companyDetails
          })
          
          let tawkTripData = {}
          let tripDetail = 'Viagem - ' + (this.$store.state.simpleTrip.is_round_trip ? ' Ida e volta' : 'Somente ida')
          tawkTripData['veiculo'] = tripData.selectedVehicle.title
          tawkTripData['valor'] = (this.vehicle.priceCents + this.$store.state.tripDetails.driverExpenses.driverExpensesValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
          tawkTripData['servico'] = tripDetail
          if (this.$store.state.simpleTrip.is_round_trip) {
            tawkTripData['endereco-ida'] = this.$store.state.simpleTrip.origin.address
            tawkTripData['data-ida'] = this.moment(this.$store.state.simpleTrip.origin.date).format('DD/MM/YYYY')
            tawkTripData['hora-ida'] = this.$store.state.simpleTrip.origin.time
            tawkTripData['endereco-volta'] = this.$store.state.simpleTrip.destination.address
            tawkTripData['data-volta'] = this.moment(this.$store.state.simpleTrip.destination.date).format('DD/MM/YYYY')
            tawkTripData['hora-volta'] = this.$store.state.simpleTrip.destination.time
          } else {
            tawkTripData['endereco-ida'] = this.$store.state.simpleTrip.origin.address
            tawkTripData['data-ida'] = this.moment(this.$store.state.simpleTrip.origin.date).format('DD/MM/YYYY')
            tawkTripData['hora-ida'] = this.$store.state.simpleTrip.origin.time
          }

          if (this.$store.state.companyDetails.whitelabel.chats.tawkto !== null) {
            window.Tawk_API.addEvent('adicionou-ao-carrinho', tawkTripData, function (error) {
              console.log(error)
            })
          }

          this.$store.commit('cart/setCartItem', tripData)
        }
        if (!this.lodash.includes(tripItems, tripData)) {
          tripItems.push(tripData)
        }
      }
      if (type === 'quote') {
        tripItems.forEach(function (item, index) {
          $scope.$store.commit('quote/setQuoteItem', {
            index: index,
            data: item
          })
        })
      }

      this.$store.commit('cart/calculateCartTotal', { data: this.$store.state })

      if (type !== 'quote') {
        this.$router.push({ path: '/checkout' })
      }
    },
    showVehicleDetails (index, vehicleID) {
      if (this.extra.transfer && this.$store.state.serviceType === 'transfer') {
        let transferType = this.$store.state.transferType

        let vehiclesObject = this.vehicles.hasOwnProperty(transferType) ? this.vehicles[transferType] : this.vehicles

        vehiclesObject.forEach((element, loopIndex) => {
          if (loopIndex !== index) {
            vehiclesObject[loopIndex].visible = false
          }
        })

        vehiclesObject[index].visible = !vehiclesObject[index].visible
        let selectedVehicle = vehiclesObject[index]
        selectedVehicle['extra'] = this.extra

        this.$store.state.selectedVehicle = selectedVehicle
        this.$store.state.simpleTrip.origin = this.$store.state.selectedVehicle.extra.transfer.point_origin
        this.$store.state.simpleTrip.destination = this.$store.state.selectedVehicle.extra.transfer.point_destination
      } else {
        if (this.vehicles.length) {
          this.vehicles.forEach((element, loopIndex) => {
            if (loopIndex !== index) {
              this.vehicles[loopIndex].visible = false
            }
          })
        } else {
          Object.values(this.vehicles).forEach((element, loopIndex) => {
            if (loopIndex != index && this.vehicles[loopIndex]) {
              this.vehicles[loopIndex].visible = false
            }
          })
        }
        this.vehicles[index].visible = !this.vehicles[index].visible
      }

      if (this.$store.state.companyDetails.whitelabel.chats.tawkto !== null) {
        if (this.vehicles[index].visible) {
          window.Tawk_API.addEvent('visualizou-detalhes-do-veiculo', {
            'veiculo': this.vehicles[index].title,
            'valor': this.vehicles[index].priceCents.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
          }, function (error) {
          })
        } else {
          window.Tawk_API.addEvent('escondeu-detalhes-do-veiculo', {
            'veiculo': this.vehicles[index].title,
            'valor': this.vehicles[index].priceCents.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
          }, function (error) {
          })
        }
      }
      // var reqId = 'vehicle-' + vehicleID
      // var element = document.getElementById(reqId)

      // var box = element.getBoundingClientRect()
      // window.scrollTo(1000, box.top)
    },
    hideVehicleDetails (index) {
      this.vehicles[index].visible = false
    },
    setImgGalery () {
      if (this.vehicle.hasOwnProperty('gallery')) {
        this.vehicle.gallery.forEach(img => {
          this.gallery.push(img.src)
        })
      }
    },
    show () {
      this.visible = true
    },
    handleHide () {
      this.visible = false
    },
    showSingle (index) {
      this.img = this.gallery[index]
      this.show()
    },
    showMultiple (index) {
      this.img = this.gallery

      this.indexImg = index
      this.show()
    },
    formatTitleVehicle (title) {
      // return title ? title.split(' ').slice(1).join(' ') : ''
      return title
    }
  },
  beforeMount () {
    this.setImgGalery()
    this.$store.commit('quote/clear', {})
  },
  mounted () {
    this.totalAvailable = parseInt(this.vehicle.totalAvailable) > 100 ? 100 : parseInt(this.vehicle.totalAvailable)
  }
}
</script>

<style lang="scss" scoped>
  .img-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin: 0 20px 5px;
    max-width: 100%;
    width: 100%;
  }

  #search-results  {
    #result-list  {

      .vehicle-card {
        @media screen and (max-width: 980px) {
          flex-direction: column!important;

          * {
            flex: auto;
          }

          .col-image {
            img {
              height: 150px;
            }
          }

          .col-price {
            margin-bottom: 30px;
            margin-top: 20px;
            width: 100%;
          }
        }
      }
      .result-item  {

        .usage-item {
          padding: 15px 10px;
          border-radius: 5px;
          margin: 0;
          margin-bottom: 0px;
          background: #ffffff;
          border: 1px solid #e0e0e0;
        }

        .row-actions {
          .col-actions {
            .book-button {
                border-color: var(--default-color);
                color: white!important;
                background-color: var(--default-color);
                border-radius: 4px;
            }
          }
        }

        .row-stop-points {
          .col-md-6 {

          }
        }

        .col-info {
          .configurations-wrapper {
            padding-top: 5px;

            span {
              background: transparent;
              color: var(--default-color);
              margin-right: 5px;
              padding: 0 5px;
              margin-bottom: 5px;
              display: flex;
              border-color: var(--default-color);
              border: 1px solid;
              border-radius: 5px;
            }
            p {
              text-align: left;
              margin-bottom: 0;
              font-size: 14px;
              color: #000;
              text-transform: capitalize;
              display: flex;
              flex-wrap: wrap;
            }
            .configurations {
              padding-top: 10px;
            }
          }
        }
        .configurations {
          span {

          }
        }
        .col-price  {
          .pricePerSeat {
            span {
              font-size: 14px;
              font-weight: 500;
              text-align: center;
            }
          }
          .more-info  {
            a {
              background-color: var(--default-color);
              border-color: var(--default-color);
              color: white;
            }
          }
        }
      }
    }
  }

</style>
