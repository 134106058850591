<template>
  <select class="form-control" :v-model="value" v-on:change="updateQuote($event)">
    <option value="">Selecionar diária</option>
    <option :value="index" v-for="(quote, index) in quotes" :key="quote.id">{{ quote.daily_hours }} Horas e {{ parseInt(quote.franchise_km) }} Quilômetros</option>
  </select>
</template>

<script>

export default {
  name: 'DailyUsageQuotesField',
  props: {
    selectedQuote: {
      default: '0'
    }
  },
  data: function () {
    return {
      quotes: this.$store.state.companyDetails.dailyUsageQuotes,
      selectedVehicleType: this.$store.state.selectedVehicleType,
      value: this.selectedQuote
    }
  },
  methods: {
    updateQuote: function (event) {
      this.$emit('change', this.$store.state.companyDetails.dailyUsageQuotes[event.target.value])
    }
  },
  created () {

  }
}
</script>

<style scoped>

</style>
