import axios from 'axios'

var form = {
  user_id: 0,
  items: [],
  discount: null,
  subtotal: 0,
  total: 0.00,
  coupon: null,
  tripDetails: {},
  hash: '',
  payment_method: 'bank_slip',
  installments: null,
  cpf: '',
  email: '',
  quote_id: null,
  order_id: null,
  source: '',
}

function calculateVehicleUsageTotals(vehicleType, trip, usage) {
  var total = 0;
  if(usage) {
    Object.entries(trip.useVehicle).forEach(entry => {
      const [key, value] = entry;
      total += parseFloat(usage[value.selectedIndex][vehicleType])
    });
  }
  return total;
}

export default {
  namespaced: true,

  state: {
    cartData: form,
    response: null
  },

  getters: {
    response: state => state.response
  },

  mutations: {
    setClearCart (state, form) {
      state.cartData = {
        user_id: 0,
        items: [],
        discount: null,
        subtotal: 0,
        total: 0.00,
        coupon: null,
        tripDetails: {},
        hash: '',
        payment_method: 'bank_slip',
        installments: null,
        cpf: '',
        quote_id: null,
        email: '',
        order_id: null,
        source: '',
      }
      state.response = null
    },
    setPaymentMethod (state, paymentMethod) {
      state.cartData.payment_method = paymentMethod
    },
    setCoupon (state, data) {
      state.cartData.subtotal = state.cartData.total
      state.cartData.total = data.discounted_total
      state.cartData.discount = data.discount
      state.cartData.coupon = data.coupon
    },
    removeCoupon (state, coupon) {
      state.cartData.total = state.cartData.total + state.cartData.discount
      state.cartData.subtotal = state.cartData.total
      state.cartData.discount = 0
      state.cartData.coupon = null
    },
    setCartProp (state, data) {
      state.cartData[data.prop] = data.value
    },
    setCartItem (state, data) {
      if (state.cartData.items.length === 0 || Object.keys(state.cartData.items).length === 0) {
        state.cartData.items = []
      }
      state.cartData.items.push(data)
    },
    removeItem (state, data) {
      state.cartData.items.splice(data, 1)
    },
    defineCartSource (state, value) {
      state.cartData.source = value
    },
    setQuoteID (state, value) {
      state.cartData.quote_id = value
      state.cartData.items.forEach((el, index) => {
        el.quote_id = value
      })
    },
    setDiscount (state, value) {
      state.cartData.discount = parseFloat(value)
    },
    setAddition (state, value) {
      state.cartData.addition = parseFloat(value)
    },
    calculateCartTotal (state, details) {
      let subtotal = 0;
      let extraCost = 0;
      let discount = 0;
     
      state.cartData.items.forEach((el, index) => {
        if(el.driverExpenses && el.driverExpenses.enable && !el.selectedVehicle.usingTwoTrips){
          extraCost += el.driverExpenses.driverExpensesValue;
        }

        if(el.tripDetails && el.tripDetails.driverExpenses && !el.selectedVehicle.usingTwoTrips){
          extraCost += el.tripDetails.driverExpenses.driverExpensesValue;
        }

        let trip = el.tripDetails;
        let vehicle = el.selectedVehicle;
        
        if (el.type === 'customtrip') {
          // extraCost += calculateVehicleUsageTotals( vehicle.type, el.tripDetails, details.data.companyDetails.vehicleUsageQuotes ) + trip.driverExpenses.driverExpensesValue;
          subtotal += parseFloat(el.selectedVehicle.priceCents)
        } else {

          if(!el.selectedVehicle.usingTwoTrips) {
            subtotal += parseFloat(el.selectedVehicle.priceCents);
          }else{
            subtotal += parseFloat(el.selectedVehicle.metas.priceCents);
          }
          
        }
      })

      extraCost += state.cartData.addition;
      subtotal = parseFloat(subtotal)
      extraCost = extraCost && !isNaN(extraCost) ? parseFloat(extraCost) : 0
      discount = state.cartData.discount && !isNaN(state.cartData.discount) ? parseFloat(state.cartData.discount) : 0

      extraCost = isNaN(extraCost) || !extraCost ? 0 : extraCost;
      discount = isNaN(state.cartData.discount) || !state.cartData.discount ? 0 : state.cartData.discount;

      state.cartData.subtotal = subtotal
      state.cartData.total = subtotal - discount + extraCost

    }
  },

  actions: {
    clearCart ({ commit }) {
      commit('setClearCart', {
        cartData: form,
        response: null
      })
    },
    createOrder ({ commit }, data) {
      commit('setErrors', {}, { root: true })
      return axios
        .post(process.env.API_URL + 'order/create', data)
        .then(response => {
          return response
        })
        .catch(response => {

        })
    }
  }
}
