<template inline-template >
  <nav :class="`${!this.user ? 'not-logged-in' : 'user-logged-in' } navbar navbar-expand-lg navbar-light`" v-bind:style=" { background: backgroundColor}">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img :src="logoColor" :alt="this.$store.state.companyDetails.name">
      </a>

      <div class="d-flex align-items-center" id="">
        <ul class="navbar-nav ml-auto align-items-center" :style="cssProps">
          <b-nav-item-dropdown right v-if="user" id="navbarSupportedContent">
            <!-- Using 'button-content' slot -->
            <template #button-content :style=" {  } ">
              <span class="d-none d-sm-block">Olá, <span>{{ userFirstName }}</span> <br></span>
               <button class="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <!-- <img src="../../assets/img/user-icon.jpg" class="profile-thumb" alt=""> -->

              <b-icon-person-circle class="profile-thumb"></b-icon-person-circle>

              <span v-if="$store.state.cart.cartData.items.length > 0" class="card-counter">{{$store.state.cart.cartData.items.length}}</span>

            </template>
             <router-link to="/checkout" tag="li" class="dropdown-item" :style=" {  } ">
             <a>Fechar Orçamento</a>
          </router-link>
            <router-link to="/minha-conta/reservas" tag="li" class="dropdown-item" :style=" {  } "><a>Minhas reservas</a></router-link>
            <router-link to="/minha-conta/orcamentos" tag="li" class="dropdown-item" :style=" {  } "><a>Meus orçamentos</a></router-link>
            <router-link to="/minha-conta" tag="li" class="dropdown-item" :style=" {  } "><a>Meus dados</a></router-link>
            <router-link to="/" @click.native="logout" tag="li" class="dropdown-item" :style=" {  } "><a>Sair</a></router-link>
          </b-nav-item-dropdown>

          <li class="nav-item" v-else>
            <login-modal :style="{}" :show="show" :showRegister="false" @close="closeModal" />
          </li>
        </ul>

        <!-- <ul class="navbar-nav ml-auto align-items-center" v-if="user"> -->

        <!-- </ul> -->
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoginModal from '@/components/modals/LoginModal.vue'

export default {
  name: 'Navbar',
  components: { LoginModal },
  data: function () {
    return {
      show: false
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    logoColor () {
      return this.$store.state.companyDetails.logo
    },
    backgroundColor () {
      return this.$store.state.companyDetails.whitelabel.header_background_color || ''
    },
    userFirstName () {
      let name = this.user.name

      let firstName = name.split(' ')[0]

      return firstName
    }
  },
  methods: {
    showModal: function () {
      this.show = true
    },
    closeModal: function () {
      this.show = false
    },
    ...mapActions('auth', ['sendLogoutRequest']),
    logout () {
      this.sendLogoutRequest({ email: this.user.email }).then((response) => {
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
  @import "../../assets/scss/components/_navbar.scss";

  .profile-thumb {
    height: 35px;
    width: 35px;
    border-radius: 150px;
    margin-left: 10px;

    @media (max-width: 990px) {
      margin-right: 20px;
    }

    * {
      fill: var(--default-color)
    }
  }

  .card-counter {
    background-color: #e24d41!important;

    @media (max-width: 990px) {
        right: 10px;
        top: -5px;
    }
  }

  .dropdown-toggle::after {
    display: none!important;
  }

  .navbar-toggler  {
    @media (min-width: 600px) {
      display: none;
    }
  }

  .nav-item {
    list-style: none;

    .dropdown-item {
      &:active {
        color: white!important;
      }
    }

    .nav-link {
      @media (max-width: 990px) {

        .navbar-toggler {
          margin-left: 10px;
        }
      }
    }

    a {
      padding: 0;
      display: flex!important;
      align-items: center;
    }

    &.nav-shopping-cart {
      a {
        font-size: 30px;
      }
    }
  }

  .your-account {
    @media (max-width: 600px) {
      display: none;
    }
  }

.dropdown-toggle::after {
  @media (max-width: 600px) {
    display: none !important;
  }
}

.navbar-brand {
  img {
    @media (max-width: 600px) {
      max-height: 45px;
    }
  }
}
</style>
