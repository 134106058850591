<template>
  <select class="form-control pl-3" v-model="value" v-on:change="updateQuote">
    <!-- {{ quotes }} -->

    <option value="" selected>Selecione a franquia</option>
    <option :value="quote.id" v-for="(quote, index) in quotes" :key="quote.km" :indexSelected="index">{{ quote.hours }} Horas e {{ quote.km }} Quilômetros</option>
  </select>
</template>

<script>

export default {
  name: 'UsageQuotes',
  props: {
    selectedQuote: {
      default: ''
    }
  },
  data: function () {
    return {
      quotes: this.$store.state.companyDetails.vehicleUsageQuotes,
      selectedVehicleType: this.$store.state.selectedVehicleType,
      value: this.selectedQuote
    }
  },
  methods: {
    updateQuote: function (e) {
      let indexSelected = 0
      let quoteIndex = 0
      let selectedFranchise = ''
      var options = e.target.options

      if (options.selectedIndex > -1) {
        indexSelected = options[options.selectedIndex].getAttribute('indexSelected')
      }

      selectedFranchise = this.quotes[indexSelected];

      let franchiseHours = selectedFranchise.hours
      let franchiseKm = selectedFranchise.km


      this.$emit('change', { 'value': this.value, 'franchise': franchiseHours + ' Horas e ' + franchiseKm + ' Quilômetros', selectedIndex: indexSelected  })
    }
  }
}
</script>
