<template>
  <div id="maintenance-mode" :style="cssProps">
    <div class="content">
      <img :src="company.logo" alt="">
      <h1>Estamos em manutenção</h1>
      <h3>Voltamos em breve</h3>
      <footer>
        <div class="row">
          <div class="col-lg-6 col-12">
            {{ company.phone }}
          </div>
          <div class="col-lg-6 col-12">
            {{ company.telephone }}
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
  props: ['company'],
  data: function () {
    return {
      show: false
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.company.whitelabel.body_font_color,
        '--mask-background': this.company.whitelabel.body_font_color + 'de',
        '--background-image': 'url(' + this.company.whitelabel.banner_img + ')'
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
  #maintenance-mode {
    background-image: var(--background-image);
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;
    color: white;
    
    footer {
      padding-top: 30px;
      border-top: 1px solid #e0e0e0;
      margin-top: 30px;
      color: black;
    }

    .content {
      z-index: 9;
      position: relative;
      background: white;
      padding: 40px;
      border-radius: 5px;

      img {
        height: 50px;
        margin-bottom: 30px;
      }

      h1 {
        font-size: 30px;
        color: var(--default-color);
        margin: 0;
      }

      h3 {
        font-size: 22px;
        color: var(--default-color);
      }

    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--mask-background);
    }
  }
</style>
