<template>
    <div :class="[
        true ? 'new-route' : '',
        index == 0 ? 'first-route' : '',
        data.hideDestination && index != 0  ? 'hide-destination' : '' ||
        index != 0 &&
        index === $store.state.customRouter.days[$store.getters.customRouterStep].routes.length - 1 ? 'hide-destination' : '']">
        <div class="row mb-0 mb-lg-3">
            <b-col xl="3" xs="12" class="pr-0">
                <div class="input-with-icon place-field d-flex" v-if="index == 0 || index > 0 && index != $store.state.customRouter.days[$store.getters.customRouterStep].routes.length - 1">
                    <div class="icon">
                        <b-icon-clock class="mr-2"></b-icon-clock>
                    </div>
                    <div class="input ml-3">
                        <time-select-field
                            ref="destinationTime"
                            placeholder="Horário ida"
                            v-on:updateschedule="addStopPoint($event, 'time')"
                            :value="data.time"
                        >
                        </time-select-field>
                    </div>
                </div>

                <div class="mt-2 d-flex align-items-start justify-content-start vehicle-usage input-feedback"
                v-if="index == 0 || index > 0 && index != $store.state.customRouter.days[$store.getters.customRouterStep].routes.length - 1">
                    <button @click.prevent="newStopPoint"><b-icon-plus class="mr-2"></b-icon-plus>Ponto de embarque ou desembarque</button>
                </div>
            </b-col>
            <b-col xl="9" xs="12" class="route-item">
               <div class="d-flex align-items-center flex-column address-fields">
                    <b-icon-x-circle title="Remover este trecho" v-if="index != 0"  class="remove-point" @click="removeRoute(index)"></b-icon-x-circle>

                    <b-col xs="12" class="origin-address mb-2 pr-0">

                        <places
                            label="Origem"
                            placeholder="Insira uma rua, cidade ou estado"
                            :prop="'origin'"
                            :value="data.origin.address"
                            class="place-field"
                            @send-message="setOrigin"
                        />
                    </b-col>

                    <div v-if="data.stop_points && index == 0" class="w-100">
                        <b-col xs="12" class="stop-points mb-2 pr-0" v-for="(point, stopIndex) in data.stop_points">
                            <b-icon-x-circle
                            title="Remover ponto de embarque ou desembarque"
                            class="remove-point" @click="removeStopPoint(stopIndex)"></b-icon-x-circle>
                            <places
                                type="stop-point"
                                :label="`Ponto de embarque ou desembarque`"
                                placeholder="Insira uma rua, cidade ou estado"
                                :value="data.stop_points[stopIndex].address"
                                class="place-field"
                                :index="stopIndex"
                                @send-message="setPointAddress"
                            />
                        </b-col>
                    </div>

                    <b-col xs="12"
                    :class="[
                        true ? 'destination-address mb-2 pr-0' : '',
                        $store.state.customRouter.days[$store.getters.customRouterStep].routes.length > 1 && index == 0 ? 'hide-destination' : ''
                    ]">
                        <places
                            label="Destino"
                            placeholder="Insira uma rua, cidade ou estado"
                            :prop="'destination'"
                            :value="data.destination.address"
                            class="place-field"
                            @send-message="setDestination"
                            :isLastStep="index === $store.state.customRouter.days[$store.getters.customRouterStep].routes.length - 1"
                        />

                        <!-- <div class="map-suggest" v-if="index != 0 && index === $store.state.customRouter.days[$store.getters.customRouterStep].routes.length - 1">
                            <div class="options">
                                <div class="option" @click.prevent="backToOrigin()">Voltar para {{ $store.state.customRouter.days[$store.getters.customRouterStep].routes[0].origin.address }}</div>
                            </div>
                        </div> -->
                    </b-col>

                    <div v-if="data.stop_points && index != 0" class="w-100">
                        <b-col xs="12" class="stop-points mb-2 pr-0" v-for="(point, stopIndex) in data.stop_points">
                            <b-icon-x-circle
                            title="Remover ponto de embarque ou desembarque"
                            class="remove-point" @click="removeStopPoint(stopIndex)"></b-icon-x-circle>
                            <places
                                type="stop-point"
                                :label="`Ponto de embarque ou desembarque`"
                                placeholder="Insira uma rua, cidade ou estado"
                                :value="data.stop_points[stopIndex].address"
                                class="place-field"
                                :index="stopIndex"
                                @send-message="setPointAddress"
                            />
                        </b-col>
                    </div>
               </div>
            </b-col>
        </div>
    </div>
</template>

<script>
import TimeSelectField from '@/components/fields/TimeSelectField.vue'
import Places from '../../general/Places.vue'

export default {
  name: 'FrontendNewRoute',
  components: { TimeSelectField, Places },
  props: ['index', 'data', 'day', 'updateValue', 'count'],
  methods: {
    backToOrigin () {
      var lastIndex = this.$store.state.customRouter.days[this.day - 1].routes.length - 1
      var address = this.$store.state.customRouter.days[this.day - 1].routes[0].origin
      this.$store.commit('SET_CUSTOM_ROUTER_DAY_PROP', {
        value: address,
        prop: 'destination',
        route: lastIndex,
        day: this.day
      })
    },
    stopPointValue (index) {
      if (
        this.$store.state.customRouter.days[this.day] &&
                this.$store.state.customRouter.days[this.day].routes[this.index] &&
                this.$store.state.customRouter.days[this.day].routes[this.index].stop_points[index]
      ) {
        var result = this.$store.state.customRouter.days[this.day].routes[this.index].stop_points[index]
        return result.address
      } else {
        return ''
      }
    },
    getAddressData (addressObject) {
      let data = {
        address: addressObject.formatted_address,
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
        address_components: this.getAddressComponent(addressObject.address_components),
        name: addressObject.name
      }
      return data
    },
    getAddressField (data, field) {
      switch (field) {
        case 'state':
          let state = data.filter(item => item.types.includes('administrative_area_level_1'))
          return state.length > 0 ? state[0].long_name.replace('State of ', '') : ''
          break
        case 'state_short':
          let state_short = data.filter(item => item.types.includes('administrative_area_level_1'))
          return state_short.length > 0 ? state_short[0].short_name : ''
          break
        case 'route':
          let route = data.filter(item => item.types.includes('route'))
          return route.length > 0 ? route[0].long_name : ''
          break
        case 'city':
          let city = data.filter(item => item.types.includes('administrative_area_level_2'))
          return city.length > 0 ? city[0].long_name : ''
        case 'district':
          let district = data.filter(item => item.types.includes('sublocality_level_1'))
          return district.length > 0 ? district[0].long_name : ''
        case 'postal_code':
          let postal_code = data.filter(item => item.types.includes('postal_code'))
          return postal_code.length > 0 ? postal_code[0].long_name : ''
        default:
          break
      }
    },
    getAddressComponent (data) {
      return {
        route: this.getAddressField(data, 'route'),
        state: this.getAddressField(data, 'state'),
        state_short: this.getAddressField(data, 'state_short'),
        city: this.getAddressField(data, 'city'),
        district: this.getAddressField(data, 'district'),
        postal_code: this.getAddressField(data, 'postal_code')
      }
    },
    setPointAddress: function (addressData, addressObject, point) {
      let data = this.getAddressData(addressObject)
      this.$store.commit('SET_CUSTOM_ROUTER_DAY_STOP_POINT', {
        value: data,
        point: point,
        route: this.index,
        day: this.day
      })
      this.updateValue()
    },
    setOrigin: function (addressData, addressObject, index) {
      let data = this.getAddressData(addressObject)
      this.$store.commit('SET_CUSTOM_ROUTER_DAY_PROP', {
        value: data,
        prop: 'origin',
        route: this.index,
        day: this.day
      })
      this.$store.commit('CUSTOM_ROUTER_UPDATE_STEP')
      this.updateValue()
    },
    removeStopPoint (index) {
      this.$store.commit('REMOVE_CUSTOM_ROUTER_DAY_STOP_POINT', {
        route: this.index,
        day: this.day,
        index: index
      })
      this.updateValue()
    },
    newStopPoint () {
      this.$store.commit('ADD_CUSTOM_ROUTER_DAY_STOP_POINT', {
        route: this.index,
        day: this.day
      })
      this.updateValue()
    },
    getLastActiveDayRoutes (step) {
      var routes = []
      for (var count = step; count >= 0; count--) {
        if (this.$store.state.customRouter.days[count].active) {
          routes = this.$store.state.customRouter.days[count].routes
          break
        }
      }
      return routes
    },
    setDestination: function (addressData, addressObject, index) {
      let destinationData = this.getAddressData(addressObject)
      // let originData = {}
      // var step = this.$store.getters.customRouterStep
      // var lastRoutes = this.getLastActiveDayRoutes(step)

      // if (lastRoutes.length > 1) {
      //   originData = lastRoutes[lastRoutes.length - 1].origin
      // } else {
      //   originData = lastRoutes[0].origin
      // }
      if (this.index !== 0) {
        this.$store.commit('SET_CUSTOM_ROUTER_DAY_PROP', {
          value: destinationData,
          prop: 'origin',
          route: this.index,
          day: this.day
        })
      }

      this.$store.commit('SET_CUSTOM_ROUTER_DAY_PROP', {
        value: destinationData,
        prop: 'destination',
        route: this.index,
        day: this.day
      })
      this.updateValue()
    },
    addStopPoint (event, prop) {
      this.$store.commit('SET_CUSTOM_ROUTER_DAY_PROP', {
        value: event,
        prop: prop,
        route: this.index,
        day: this.day
      })
      this.updateValue()
    },
    removeRoute (index) {
      this.$store.commit('REMOVE_CUSTOM_ROUTE', {
        date: this.day,
        index: index
      })

      this.updateValue()
    }
    // addStopPoint() {
    //     this.$store.commit('CLEAN_CUSTOM_ROUTES', {
    //         date: this.date,
    //         index: this.index
    //     });
    // }
  }
}
</script>

<style lang="scss" scoped>

    .remove-point {
        position: absolute;
        right: 20px;
        top: 13px;
        z-index: 9;

        &:hover {
            cursor: pointer;
            opacity: .7;
        }

        * {
            fill: var(--default-color)
        }
    }

    @media screen and (max-width: 1024px) {
        .route-item {
            padding-right: 0;

            .origin-address, .destination-address {
                padding-left: 0;
            }
        }
    }

    .hide-destination  {
        &.destination-address {
            display: none;
        }
    }

    .destination-address {

        &:hover {
            .map-suggest {
                display: flex;
                .options {
                    .option{
                        &:hover {
                            cursor: pointer;
                            color: var(--default-color);
                        }
                    }
                }
            }
        }
        .map-suggest {
            position: absolute;
            width: 99%;
            left: 0;
            z-index: 999;
            display: none;
            .options {
                position: relative;
                background: white;
                width: 100%;
                left: 0;
                padding: 10px;
                border: 1px solid #e0e0e0;
                border-radius: 5px;
                z-index: 999;
                margin-top: 20px;
            }
        }
    }
    .new-route {
        position: relative;
        &.hide-destination  {
            .origin-address {
                display: none;
            }
        }

        .address-fields {
            position: relative;
            // .remove {
            //     border: none;
            //     background: none;
            //     font-size: 14px;
            //     padding: 0;
            //     color: var(--default-color);
            //     position: absolute;
            //     right: 10px;
            //     top: 9px;
            //     background-color: var(--default-color);
            //     border: 1px solid var(--default-color);
            //     color: white;
            //     font-size: 15px;
            //     cursor: pointer;
            //     z-index: 9;
            //     border-radius: 150px;
            //     width: 20px;
            //     height: 20px;
            //     display: none;
            //     align-items: center;
            //     justify-content: center;
            // }

            svg {
              background: white;
              z-index: 9;
              right: 10px;
            }
            &:hover {
                .remove {
                    display: flex!important;
                }
            }
        }
        .vehicle-usage {
            background: transparent;
            border: none!important;
            padding: 0;
            margin: 0;
            display: flex;

            @media screen and (max-width: 1024px) {
               margin-bottom: 10px;
            }

            button {
                border: none;
                background: none;
                font-size: 12px;
                padding: 0;
                color: var(--default-color);
            }

        }
        .row {

        }

        select {
            width: 100%;
            border: none;
        }

    }

</style>
