// index.js

import VueExcelEditor from './VueExcelEditor.vue'
import VueExcelColumn from './VueExcelColumn.vue'

export default {
  install (Vue) {

    Vue.component('fretatech-excel-editor', VueExcelEditor)
    Vue.component('fretatech-excel-column', VueExcelColumn)

    Vue.mixin({
      created() {
        // do something you like
      }
    })
  }
}
