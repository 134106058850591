<template>
    <div>
        <div id="itinerary-summary" :style="cssProps">
            <div v-for="(day, dayIndex) in itineraryDays" :key="dayIndex">
                <div class="day-routes" v-if="day.routes[0] && day.routes[0].origin.address != ''">
                    <div class="heading">
                        <div class="date">
                            {{ dayIndex + 1 }}º dia - {{ day.date}}
                        </div>
                    </div>
                    <div :class="[true ? 'itineraries' : '', !day.active ? 'no-routes' : '']">
                        <div v-for="(route, routeIndex) in day.routes" :key="routeIndex"  v-if="day.active">
                            <div class="route mb-1">
                                <div class="route-origin" v-if="routeIndex == 0">
                                    <div class="route-origin-address pr-0">
                                        <b-icon-circle-fill class="origin-icon"/>
                                        <div class="address">
                                            <span>{{ getOrigin(route) }}</span>
                                        </div>
                                        <div class="time">
                                            Horário de saída: {{ route.time }}h
                                        </div>
                                    </div>
                                </div>
                                <div class="route-origin mb-2 mt-1"  v-for="(point, pointIndex) in route.stop_points" :key="pointIndex"  v-if="route.stop_points && routeIndex === 0">
                                    <div class="route-stop-point pr-0 d-flex" v-if="point.address">
                                        <div class="icon">
                                            <svg
                                                class="stop-point-icon"
                                                version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                width="187.000000pt" height="206.000000pt" viewBox="0 0 187.000000 206.000000"
                                                preserveAspectRatio="xMidYMid meet">
                                                <g transform="translate(0.000000,206.000000) scale(0.100000,-0.100000)"
                                                fill="#000000" stroke="none">
                                                <path d="M1122 2039 c-130 -65 -130 -254 0 -320 32 -16 54 -20 94 -17 98 8
                                                164 79 164 177 0 138 -134 221 -258 160z"/>
                                                <path d="M982 1630 c-39 -24 -65 -71 -43 -78 7 -2 -33 -30 -90 -61 l-103 -56
                                                -73 -175 c-40 -96 -73 -184 -73 -196 0 -39 37 -74 79 -74 20 0 45 6 54 13 9 6
                                                44 77 77 157 34 80 67 150 73 156 7 7 44 28 82 49 l70 37 -53 -47 c-29 -26
                                                -58 -57 -64 -69 -23 -41 -37 -336 -26 -537 l10 -186 -101 -220 c-99 -216 -111
                                                -255 -91 -294 13 -24 61 -49 94 -49 49 0 80 47 191 288 l107 233 -6 127 c-4
                                                70 -9 156 -13 190 -5 63 -5 64 18 60 21 -3 30 -21 80 -168 l56 -165 22 -249
                                                c12 -136 26 -257 32 -267 12 -24 60 -49 93 -49 14 0 40 13 58 29 38 34 38 37
                                                8 366 -18 207 -22 228 -63 350 l-44 130 12 175 c7 96 13 176 14 178 1 1 13 -6
                                                29 -17 26 -19 289 -91 332 -91 32 0 70 44 70 81 0 56 -28 74 -174 110 l-131
                                                32 -57 66 c-51 57 -58 70 -58 107 0 51 -34 98 -83 113 -18 5 -88 12 -157 16
                                                -115 5 -128 4 -158 -15z"/>
                                                <path d="M545 824 c-32 -68 -62 -124 -67 -124 -5 0 -26 9 -48 20 -45 23 -79
                                                25 -102 8 -21 -17 -258 -532 -258 -562 0 -22 33 -66 50 -66 5 0 7 -14 3 -30
                                                -12 -60 60 -96 92 -46 14 22 19 23 50 13 59 -18 69 -3 204 293 l125 273 -17
                                                30 c-18 30 -18 30 41 157 l59 128 -37 15 -38 15 -57 -124z"/>
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="address">
                                            <span>{{ point.address }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="route-destination" v-if="routeIndex != 0 && day.routes.length > 1 || routeIndex == 0 && day.routes.length == 1 ">
                                    <div class="route-destination-address pr-0">

                                        <b-icon-circle-fill v-if="routeIndex != day.routes.length - 1" class="origin-icon"/>
                                        <svg
                                            v-else
                                            data-v-41be6633="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="geo alt fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="destination-icon bi-geo-alt-fill mx-auto b-icon bi">
                                            <g data-v-41be6633="">
                                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                                            </g>
                                        </svg>

                                        <div class="address">
                                            <span>{{ getDestination(route) }}</span>
                                        </div>
                                        <div class="time" v-if="routeIndex != day.routes.length - 1">
                                            Horário de saída: {{ route.time }}h
                                        </div>

                                    </div>
                                </div>

                                <div class="route-origin mb-2 mt-1"  v-for="(point, index) in route.stop_points" :key="index"  v-if="route.stop_points && routeIndex !== 0">
                                    <div class="route-stop-point pr-0 d-flex" v-if="point.address">
                                        <div class="icon">
                                            <svg
                                                class="stop-point-icon"
                                                version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                width="187.000000pt" height="206.000000pt" viewBox="0 0 187.000000 206.000000"
                                                preserveAspectRatio="xMidYMid meet">
                                                <g transform="translate(0.000000,206.000000) scale(0.100000,-0.100000)"
                                                fill="#000000" stroke="none">
                                                <path d="M1122 2039 c-130 -65 -130 -254 0 -320 32 -16 54 -20 94 -17 98 8
                                                164 79 164 177 0 138 -134 221 -258 160z"/>
                                                <path d="M982 1630 c-39 -24 -65 -71 -43 -78 7 -2 -33 -30 -90 -61 l-103 -56
                                                -73 -175 c-40 -96 -73 -184 -73 -196 0 -39 37 -74 79 -74 20 0 45 6 54 13 9 6
                                                44 77 77 157 34 80 67 150 73 156 7 7 44 28 82 49 l70 37 -53 -47 c-29 -26
                                                -58 -57 -64 -69 -23 -41 -37 -336 -26 -537 l10 -186 -101 -220 c-99 -216 -111
                                                -255 -91 -294 13 -24 61 -49 94 -49 49 0 80 47 191 288 l107 233 -6 127 c-4
                                                70 -9 156 -13 190 -5 63 -5 64 18 60 21 -3 30 -21 80 -168 l56 -165 22 -249
                                                c12 -136 26 -257 32 -267 12 -24 60 -49 93 -49 14 0 40 13 58 29 38 34 38 37
                                                8 366 -18 207 -22 228 -63 350 l-44 130 12 175 c7 96 13 176 14 178 1 1 13 -6
                                                29 -17 26 -19 289 -91 332 -91 32 0 70 44 70 81 0 56 -28 74 -174 110 l-131
                                                32 -57 66 c-51 57 -58 70 -58 107 0 51 -34 98 -83 113 -18 5 -88 12 -157 16
                                                -115 5 -128 4 -158 -15z"/>
                                                <path d="M545 824 c-32 -68 -62 -124 -67 -124 -5 0 -26 9 -48 20 -45 23 -79
                                                25 -102 8 -21 -17 -258 -532 -258 -562 0 -22 33 -66 50 -66 5 0 7 -14 3 -30
                                                -12 -60 60 -96 92 -46 14 22 19 23 50 13 59 -18 69 -3 204 293 l125 273 -17
                                                30 c-18 30 -18 30 41 157 l59 128 -37 15 -38 15 -57 -124z"/>
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="address">
                                            <span>{{ point.address }}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div v-else class="no-routes w-100 justify-content-start">
                            Sem trechos nesta data
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['data'],
  name: 'ItinerarySummary',

  data () {
    return {
      itineraryDays: this.data ? this.data : $store.state.customRouter.days
    }
  },

  mounted () {

  },

  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  },

  methods: {
    getOrigin (route) {
      var origin = ''
      origin = route.origin.address_components.district || route.origin.address_components.city
      return route.origin.name != '' && route.origin.name != origin && origin ? origin : route.origin.name != '' && route.origin.name != origin && !origin ? route.origin.name : origin
    },
    hasOrigin (routes) {
      $store.state.customRouter.days[day].routes
    },
    getDestination (route) {
      if (!route.destination.address_components) {
        return
      }

      var destination = ''

      if (route.destination.address_components.hasOwnProperty('district')) {
        destination = route.destination.address_components.district || route.destination.address_components.city
      } else {
        destination = route.destination.address_components.distrito || route.destination.address_components.cidade
      }

      return route.destination.name != '' && route.destination.name != destination && destination
        ? destination : route.destination.name != '' && route.destination.name != destination && !destination ? route.destination.name : destination
    }
  }
}
</script>

<style lang="scss" scoped>
#itinerary-summary {

    * {
        font-family: 'Inter', sans-serif;
    }

    .route-stop-point {
        * {
            fill: #2b2b2b !important;

        }
        .icon {
            height: 30px;
        }
        svg {
            height: 20px;
            width: 20px;
            background: white!important;
            padding: 2px;
        }
    }

    .itineraries {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 70%;
            top: 10%;
            background: transparent;
            z-index: 1;
            left: 8px;
            border: 1px dashed #d9d9d9;
        }

        &.no-routes {
            font-size: 12px;
            &:before {
                display: none;
            }
        }
    }
    .day-routes {
        margin-bottom: 20px;

        .heading {
            .date {
                font-size: 12px;
                border: 1px solid;
                border-color: var(--default-color);
                width: fit-content;
                padding: 0px 15px;
                border-radius: 30px;
                margin-bottom: 10px;
                color: var(--default-color);
            }
        }
        .route {
            .address {
                padding-left: 30px;
                display: flex;
                span {
                    padding-left: 0;
                    font-size: 14px;
                    font-weight: 600;
                    color: #000;
                }
            }

            .time {
                padding-left: 30px;
                font-size: 12px;
            }
        }

        svg {
            background: white;
            border-radius: 20px;
            padding: 2px;
            z-index: 9;
            padding-left: 2px;
            position: absolute;
        }
        .origin-icon {
            font-size: 18px;
            color: #D9D9D9!important;
            padding-left: 2px;

            * {
                color: #D9D9D9!important;
                fill: #D9D9D9!important;
            }
        }
        .destination-icon {
            font-size: 19px;
            color: #000;
        }
    }
}

</style>
