<template>
<!-- tripSearchFOrm -->
  <b-form id="search-form" class="search-form default-bordered trip-form" :style="cssProps">

    <b-row class="row-trip-info">
      <b-col lg="6" md="12">
        <h3>Origem</h3>
        <b-form-row>
          <b-form-group  :class="{'col-12 origin':true, 'has-feedback': errors.origin_address}">
            <b-input-group>
              <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"> <b-icon-geo-alt></b-icon-geo-alt></b-input-group-text>
              <div :class="{'form-control form-group p-0':true, }"  style="border:none;">
                <places @validate-data="validateAutoComplete"
                  placeholder="Rua, número, cidade"
                  @send-message="setOrigin"
                  @delete-data="handleDeleteAutocomplete"
                  :prop="'origin'"
                  :value="$store.state.origin.address"
                  classname="form-control input-feedback"
                  :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor, borderRadius: '50px' }"
                  @clear-errors="clearErrorInput()"
                  ></places>
              </div>
              <!-- <div class="invalid-feedback " v-if="errors.origin_address">{{errors.origin_address}}</div> -->
            </b-input-group>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group  :class="{'col-lg-6 form-control date':true, 'has-feedback': errors.origin_date}">
            <b-input-group>
              <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><b-icon-calendar3></b-icon-calendar3></b-input-group-text>
              <DatePicker
                @selected="setDate($event, 'origin')"
                name="originDate"
                ref="originDate"
                class="form-control input-feedback"
                placeholder="Data ida"
                format="dd/MM/yyyy"
                :value="$store.state.origin.date"
                :disabled-dates="reservationStartDate"
                :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
                :open-date="TripOpenDate"
                @focusin.native="clearErrorInput()"
              >
              </DatePicker>

              <!-- <div class="invalid-feedback " v-if="errors.origin_date">{{errors.origin_date}}</div> -->
            </b-input-group>
          </b-form-group>

          <b-form-group class="col-lg-6 time">
            <b-input-group>
              <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><b-icon-clock></b-icon-clock></b-input-group-text>
              <time-select-field
                v-on:updateschedule="setTime($event, 'origin')"
                ref="destinationTime"
                placeholder="Horário ida"
                :value="$store.state.origin.time"
                classes="form-control"
                :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
                @clear-errors="clearErrorInput()"
              >
              </time-select-field>
            </b-input-group>
          </b-form-group>
        </b-form-row>
        <b-form-group class="vehicle">
          <b-input-group>
            <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><img :src="this.busIcon" /></b-input-group-text>
            <vehicle-select :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }" />
          </b-input-group>
        </b-form-group>

      </b-col>

      <b-col lg="6" md="12">
        <h3>Destino</h3>
        <b-form-row>
          <b-form-group :class="{'col-12 destination':true, 'has-feedback': errors.destination_address}">
            <b-input-group>
              <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"> <b-icon-geo-alt></b-icon-geo-alt></b-input-group-text>
              <div class="form-control form-group p-0" style="border:none;">
                <places @validate-data="validateAutoComplete"
                placeholder="Rua, número, cidade"
                @send-message="setDestination"
                @delete-data="handleDeleteAutocomplete"
                :prop="'destination'"
                :value="$store.state.destination.address"
                classname="form-control input-feedback"
                :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor,  borderRadius: '50px'}"
                @clear-errors="clearErrorInput()"
                ></places>
              </div>
              <!-- <div class="invalid-feedback " v-if="errors.destination_address">{{errors.destination_address}}</div> -->
            </b-input-group>
          </b-form-group>
        </b-form-row>

        <b-form-row v-if="this.$store.state.is_round_trip">
          <b-form-group :class="{'col-lg-6 date':true, 'has-feedback': errors.destination_date}">
            <b-input-group>
              <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><b-icon-calendar3></b-icon-calendar3></b-input-group-text>
              <DatePicker
                @selected="setDate($event, 'destination')"
                name="destinationDate"
                ref="destinationDate"
                class="form-control form-group input-feedback"
                placeholder="Data volta"
                format="dd/MM/yyyy"
                :value="$store.state.destination.date"
                :disabled-dates="$store.state.destinationDisabledDates"
                :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
                :open-date="$store.state.origin.date"
                @focusin.native="clearErrorInput()"
              >
              </DatePicker>
              <!-- <div class="invalid-feedback " v-if="errors.destination_date">{{errors.destination_date}}</div> -->
            </b-input-group>
          </b-form-group>

          <b-form-group class="col-lg-6 time">
            <b-input-group>
              <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><b-icon-clock></b-icon-clock></b-input-group-text>
              <time-select-field
                v-on:updateschedule="setTime($event, 'destination')"
                ref="destinationTime"
                placeholder="Horário volta"
                :value="$store.state.destination.time"
                classes="form-control"
                :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
                @clear-errors="clearErrorInput()"
              >
              </time-select-field>
            </b-input-group>
          </b-form-group>
        </b-form-row>

        <b-form-row class="trip-type">
          <trip-type />
        </b-form-row>
      </b-col>
    </b-row>

    <b-form-row>
      <b-col class="col-12 col-xl-6">

          <b-form-row>
            <b-col cols="12">
              <h3>Deseja criar um roteiro?</h3>
            </b-col>

        </b-form-row>

        <b-form-row>

          <b-col cols="12">
            <button id="addUsage"  @click.prevent="openFixedTripDetails('custom_route')" class="preventCloseModal btn btn-block outline-btn" ><b-icon-map class="mr-2"></b-icon-map> Personalizar rota</button>
          </b-col>

        </b-form-row>

      </b-col>

        <b-col class="col-12 col-xl-6 d-flex justify-content-end align-items-end">

          <b-form-row class="w-100">

            <b-col cols="12">

                <button id="addTripStopPoint" @click.prevent="openFixedTripDetails('vehicle_usage')" class="btn btn-block outline-btn preventCloseModal">
                  <span>+</span> Outros usos do veículo
                </button>
            </b-col>

          </b-form-row>

        </b-col>
    </b-form-row>

    <!-- <b-form-row class="mt-3">
        <b-col cols="12" v-for="(item,index) in $store.state.tripDetails.useVehicle" :key="'item' + index">
           <use-vehicle-destination :info_data="item"  :index="index" @delete="removeUseAtDestination" />
        </b-col>
        <div class="col-md-12 text-left mb-2" style="color:red;" v-if="this.usage_destination_errors.empty_fields">
            {{usage_destination_errors.empty_fields}}
        </div>
    </b-form-row> -->

    <b-form-row class="mt-3">
      <b-form-group class="col-12 btn-wrapper">
        <b-input-group>
          <button type="button" :style="{ backgroundColor: primaryColor, borderColor: primaryColor }" @click="searchResults" class="btn btn-primary btn-block btn-search">Ver Orçamento</button>
        </b-input-group>
      </b-form-group>
    </b-form-row>

  </b-form>
</template>

<script>
import DatePicker from '@sum.cumo/vue-datepicker'
import VehicleSelect from '@/components/form/VehicleSelect.vue'
import TripType from '@/components/fields/TripType.vue'
import TimeSelectField from '@/components/fields/TimeSelectField.vue'
import Places from '@/components/general/Places.vue'
import UseVehicleDestination from '@/components/trip-details/UseVehicleDestination.vue'
import utils from '@/components/mixins/utils'

export default {
  name: 'TripSearchForm',
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    origin () {
      return this.$store.state.origin
    },
    destination () {
      return this.$store.state.destination
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.body_font_color
    },
    formFontColor () {
      return this.$store.state.companyDetails.whitelabel.form_font_color
    },
    formBackgroundColor () {
      return this.$store.state.companyDetails.whitelabel.form_background_color
    },
    disabledDates () {
      return { to: new Date(this.$store.state.origin.date), from: new Date(this.$store.state.destination.date) }
    }
  },
  mixins: [
    utils
  ],
  data () {
    return {
      reservationStartDate: {
        to: this.setReservationDate()
      },
      TripOpenDate: this.originTripOpenDate(),
      errors: false,
      usage_destination_errors: {},
      stops_inputs: [],
      busIcon: require('../../assets/icon/bus.svg'),
      search: {}
    }
  },
  components: { DatePicker, VehicleSelect, TripType, TimeSelectField, Places, UseVehicleDestination },
  methods: {
    openFixedTripDetails (type) {
      this.$emit('change', type)
    },
    setReservationDate (date = null) {
      let result = date || new Date()
      result.setDate(result.getDate() + Number(this.$store.state.companyDetails.reservationStartAt))
      return result
    },
    tawkToEvent (event, data) {
      if (this.$store.state.companyDetails.whitelabel.chats.tawkto !== null) {
        window.Tawk_API.addEvent(event, data, function (error) {
        })
      }
    },
    originTripOpenDate () {
      let date = this.$store.state.origin.date ? this.$store.state.origin.date : this.openDateOrigin()
      return date
    },
    cleanTripStates (types) {
      types.forEach((type) => {
        switch (type) {
          case 'trip':
            this.$store.commit('CLEAN_TRIP_INFO')
            break
          case 'daily':
            this.$store.commit('CLEAN_DAILY_INFO')
            break
          case 'transfer':
            this.$store.commit('CLEAN_TRANSFER_INFO')
            break
        }
      })
    },
    callErrorAlert (action, message) {
      this.errors = true
      this.$swal({
        text: message,
        showDenyButton: false,
        showConfirmButton: true,
        iconColor: action === 'error' ? '#e24d41' : this.$store.state.companyDetails.whitelabel.body_font_color,
        confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
        icon: action
      })
    },
    searchResults: function () {
      this.errors = false

      this.$store.commit('MUST_SAVE_SEARCH', true)

      if (this.$store.state.destination.date === '' && this.$store.state.is_round_trip) {
        this.callErrorAlert('error', 'Data de retorno é obrigatório')
      }

      if (this.$store.state.destination.address === '') {
        this.callErrorAlert('error', 'Local de destino é obrigatório')
      }

      if (this.$store.state.origin.date === '') {
        this.callErrorAlert('error', 'Data de partida é obrigatório')
      }

      if (this.$store.state.origin.address === '') {
        this.callErrorAlert('error', 'Local de partida é obrigatório')
      }

      if (this.$store.state.origin.address !== '' && this.$store.state.destination.address != null && this.$store.state.origin.address === this.$store.state.destination.address) {
        this.callErrorAlert('error', 'Local de partida não pode ser igual local de destino')
      }

      this.cleanTripStates(['daily', 'transfer'])
      this.$store.commit('SET_TYPE', 'viagem')
      this.search['type'] = 'trip'
      this.search['customer_id'] = this.$store.state.auth.userData !== null ? this.$store.state.auth.userData.id : null

      if (this.errors === false) {
        if (this.$router.currentRoute.name === 'search-results') {
          this.$router.go()
        } else {
          let serviceTrip = ''

          if (this.$store.state.serviceType === 'viagem') {
            serviceTrip = this.$store.state.is_round_trip ? ' ida e volta' : 'somente ida'
          }

          let eventData = {
            'servico': this.$store.state.serviceType + ' - ' + serviceTrip,
            'origem': this.$store.state.origin.address
          }

          if (this.$store.state.tripDetails.stop_points.length > 0) {
            this.$store.state.tripDetails.stop_points.forEach(function (point, index) {
              eventData['ponto-de-embarque-' + (index + 2)] = point.address
            })
          }

          eventData['data-ida'] = this.moment(this.$store.state.origin.date).format('DD/MM/YYYY')
          eventData['hora-ida'] = this.$store.state.origin.time
          eventData['destino'] = this.$store.state.destination.address
          eventData['data-volta'] = this.moment(this.$store.state.destination.date).format('DD/MM/YYYY')
          eventData['hora-volta'] = this.$store.state.destination.time

          if (this.$store.state.companyDetails.whitelabel.chats.tawkto !== null) {
            this.tawkToEvent('nova-pesquisa', eventData)
          }

          this.saveSearch(this.search)
          this.$router.push('search-results')
        }
      }
    },

    clearErrorInput (input) {
      this.errors = {}
    },
    getAddressField (data, field) {
      switch (field) {
        case 'state':
          let state = data.filter(item => item.types.includes('administrative_area_level_1'))
          return state.length > 0 ? state[0].long_name.replace('State of ', '') : ''
          break
        case 'state_short':
          let state_short = data.filter(item => item.types.includes('administrative_area_level_1'))
          return state_short.length > 0 ? state_short[0].short_name : ''
          break
        case 'route':
          let route = data.filter(item => item.types.includes('route'))
          return route.length > 0 ? route[0].long_name : ''
          break
        case 'city':
          let city = data.filter(item => item.types.includes('administrative_area_level_2'))
          return city.length > 0 ? city[0].long_name : ''
        case 'district':
          let district = data.filter(item => item.types.includes('sublocality_level_1'))
          return district.length > 0 ? district[0].long_name : ''
        case 'postal_code':
          let postal_code = data.filter(item => item.types.includes('postal_code'))
          return postal_code.length > 0 ? postal_code[0].long_name : ''
        default:
          break
      }
    },
    getAddressComponent (data) {
      return {
        route: this.getAddressField(data, 'route'),
        state: this.getAddressField(data, 'state'),
        state_short: this.getAddressField(data, 'state_short'),
        city: this.getAddressField(data, 'city'),
        district: this.getAddressField(data, 'district'),
        postal_code: this.getAddressField(data, 'postal_code')
      }
    },
    saveSearch: function (data) {
      new Promise((resolve, reject) => {
        this.$http.post(process.env.API_URL + 'search/save', {
          customer_id: data.customer_id,
          district_origin: this.getAddressField(data.origin.address_components, 'district'),
          route_origin: this.getAddressField(data.origin.address_components, 'route'),
          city_origin: this.getAddressField(data.origin.address_components, 'city'),
          state_origin: this.getAddressField(data.origin.address_components, 'state'),
          date_origin: this.$store.state.origin.date,
          district_destination: this.getAddressField(data.destination.address_components, 'district'),
          route_destination: this.getAddressField(data.destination.address_components, 'route'),
          city_destination: this.getAddressField(data.destination.address_components, 'city'),
          state_destination: this.getAddressField(data.destination.address_components, 'state'),
          date_destination: this.$store.state.destination.date,
          type: data.type
        }).then((r) => {
        })
      })
    },
    setOrigin: function (addressData, placeResultData) {
      this.search['origin'] = placeResultData
      this.updateAddress('origin', placeResultData)
    },
    handleDeleteAutocomplete: function (prop) {
      this.$store.commit('SET_PLACE', {
        prop,
        addr_info: {
          address: '',
          city: '',
          lat: '',
          lng: ''
        }
      })
    },
    validateAutoComplete: function (e) {
      if (e === 'origin' && this.$store.state.origin.address === '') {
        document.getElementById('origin').value = ''
      } else if (e === 'origin') {
        document.getElementById('origin').value = this.$store.state.origin.address
      } else if (e === 'destination' && this.$store.state.destination.address === '') {
        document.getElementById('destination').value = ''
      } else if (e === 'destination') {
        document.getElementById('destination').value = this.$store.state.destination.address
      }
    },
    setDestination: function (addressData, placeResultData) {
      this.search['destination'] = placeResultData
      this.updateAddress('destination', placeResultData)
    },
    updateAddress: function (prop, addressObject) {
      this.address = addressObject.formatted_address

      this.$store.commit('SET_PLACE', {
        prop,
        addr_info: {
          address: addressObject.formatted_address,
          city: addressObject.address_components[2].long_name,
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng(),
          address_components: this.getAddressComponent(addressObject.address_components)
        }
      })
    },
    setTime: function (time, prop) {
      this.$store.commit('SET_TIME', { prop, time })
    },
    updateDatepickerUI () {
      this.TripOpenDate = this.originTripOpenDate()
    },
    checkDiffBetweenDates (origin, destination) {
      let originDate = this.moment(origin)
      let destinationDate = this.moment(destination)
      return destinationDate.diff(originDate, 'days') < 0
    },
    setDate: function (date, prop) {
      this.updateDatepickerUI()
      if (prop === 'origin') {
        this.$store.commit('setDestinationDisabledDates', date)
        this.destinationDisabledDates = {
          to: date
        }
      } else {
        this.$store.commit('setOriginDisabledDates', date)
        this.originDisabledDates = {
          from: date
        }
      }

      date = this.dateFormatter(date)
      this.$store.commit('SET_DATE', { prop, date })
      // Remove round trip date, if going date is higher
      if (this.checkDiffBetweenDates(this.$store.state.origin.date, this.$store.state.destination.date)) {
        this.$store.state.destination.date = ''
      }
    },
    dateFormatter: function (date) {
      let year = this.moment(date).format('YYYY')
      let month = this.moment(date).format('MM')
      let day = this.moment(date).format('DD')
      date = new Date(year, (month - 1 < 0 ? 0 : month - 1), day)
      return this.moment(date).format('YYYY/MM/DD')
    },
    getVehicles: function () {
      /* eslint-disable no-new */
      new Promise((resolve, reject) => {
        this.$http.post(process.env.API_URL + 'calculate', {
          origin: this.$store.state.origin,
          destination: this.$store.state.destination,
          vehicles: this.$store.state.companyDetails.vehicles,
          is_round_trip: this.$store.state.is_round_trip,
          stop_points: this.$store.state.tripDetails.stop_points,
          vehicle_type: this.$store.state.selectedVehicleType,
          customerId: this.$store.state.auth.userData.id
        }).then((r) => {
          this.vehicles = r.data.vehicles
        })
      })
    },
    addStopPoint: function () {
      this.stops_inputs.push(Places)
    },
    setStopPoint: function (addressData, addressObject, index) {
      this.$store.commit('SET_STOPS', {
        index,
        addr_info: {
          address: addressObject.formatted_address,
          city: addressObject.address_components[2].long_name,
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng()
        }
      })
    },
    deleteStopPoint: function (prop, index) {
      this.stops_inputs.splice(index, 1)
      this.$store.commit('DELETE_STOPS', {
        index
      })
    },
    addUseAtDestination: function () {
      this.usage_destination_errors = {}

      if (this.$store.state.tripDetails.useVehicle.length > 0) {
        if (this.$store.state.tripDetails.useVehicle[this.$store.state.tripDetails.useVehicle.length - 1].date === '' ||
        this.$store.state.tripDetails.useVehicle[this.$store.state.tripDetails.useVehicle.length - 1].addr_info.address === '' ||
        this.$store.state.tripDetails.useVehicle[this.$store.state.tripDetails.useVehicle.length - 1].quote === '') {
          this.usage_destination_errors.empty_fields = 'Todos campos são obrigatório'
        }
      }

      if (Object.keys(this.usage_destination_errors).length === 0) {
        this.$store.commit('SET_USE_VEHICLE_INFO', {
          value: {
            time: '00:00',
            addr_info: {
              address: ''
            },
            date: '',
            quote: 0
          }
        })
      }
    },
    removeUseAtDestination: function (index) {
      this.$store.commit('DELETE_USE_VEHICLE_INFO', {
        index
      })
    }
  },
  async mounted () {
    let tripBackDisabledDates = this.$store.state.origin.date
    if (tripBackDisabledDates) {
      let year = this.moment(tripBackDisabledDates).format('YYYY')
      let month = this.moment(tripBackDisabledDates).format('MM')
      let day = this.moment(tripBackDisabledDates).format('DD')
      let date = new Date(year, (month - 1 < 0 ? 0 : month - 1), day)

      await this.$store.commit('setDestinationDisabledDates', date)
    }
  }
}
</script>

<style scoped>
  @import '~@sum.cumo/vue-datepicker/dist/Datepicker.css';

  .outline-btn {
    border-color: var(--default-color);
    color: var(--default-color);
    background-color: transparent;
    font-size: 12px;
    height: 46px;
    font-weight: bold;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0;
    margin-top: 1px;
    border-radius: 40px;
  }
  .outline-btn:hover {
    background-color: var(--default-color);
    color: white!important;
  }

  .trip-type div {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 0;
  }

  .trip-type {
    margin-right: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    height: 45px;
  }

  .search-form  .row-trip-info #addTripStopPoint {
    border-color: var(--default-color);
    color: var(--default-color);
    background: transparent;
    font-size: 12px;
    height: 46px;
    margin-top: 1px;
  }

  .search-form  .row-trip-info #addTripStopPoint:hover {
    background-color: var(--default-color);
    color: white;
  }

  @media only screen and (max-width: 600px){
    .btn-add-usage {
      display: block;
      width: 100%;
    }
  }
</style>
