<template>
  <div id="company-select" :style="cssProps" >
    <b-modal
    ref="company-select"
    title="Selecione a empresa"
    content-class=""
    v-model="show"
    @hide="closeModal"
    hide-footer
    >
      <div class="select-content" :style="cssProps">
        <div class="option" v-for="option in this.$store.state.companyDetails.company_group">
          <div :link="option.url" @click="goTo(option.url)" class="btn-outline-primary company-url btn-small mb-2 btn">{{ option.name }}</div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'CompanySelect',
  data () {
    return {
    }
  },
  props: ['show'],
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
  },
  mounted () {
  },
  methods: {
    ...mapActions('auth', ['sendLoginRequest']),
    ...mapActions('auth', ['sendRegisterRequest']),
    ...mapMutations(['SET_LOADING_STATE']),
    toggleLoading(value){
      this.SET_LOADING_STATE({ value: value })
    },
    closeModal () {
      this.$emit('close', false)
    },
    showModal () {
      this.show = !this.show
    },
    slugifyText(text){
      return String(text)
      .normalize('NFKD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
    },
    goTo(url) {
      window.location.href = url + '?company=' +  this.slugifyText(this.$store.state.companyDetails.fantasyName);
    }
  }
}
</script>

<style lang="scss">

  .select-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .option {
      width: 60%;
    }
    
    .btn {
      background: var(--default-color) !important;
      color: white;
      padding: 2px 40px;
      width: 100%;

      &:hover {
        color:var(--default-color)!important;
        border-color: var(--default-color)!important;
        background-color:  white!important;
      }
    }
  }
  .modal {
    .modal-dialog {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .menu-action {
    span {
      &:hover {
        &:not(:first-child){
          cursor: pointer;
          color:  var(--default-color)!important;
        }
      }
    }
    .btn-list {
      span {
        margin: 0 10px;
      }
    }
  }
  .btn-success  {
    background-color: var(--default-color)!important;
  }
  .modal-content{
    width: 100% !important;
  }
  .modal-content {
    background: whitesmoke!important;
    // width: 500px!important;
    margin: 0 auto;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .validate-input{
    margin-bottom: 20px;
  }

  .modal-open .modal{
    background: rgba(82, 82, 82, 0.58)!important;
  }

  .modal-footer,.modal-header  {
    border: none !important;
  }

  .modal-header .close {
    background: #dbdbdb;
    border-radius: 140px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px!important;
    opacity: 1!important;
    color: white;
    font-weight: 300;
    &:hover  {
      background: #605f5f;
      color: white!important;
    }
  }

  .container-login100-form-btn .back-btn:hover {
    border-color: var(--default-color)!important;
    color: var(--default-color)!important;
  }

  .modal-title {
    font-size: 22px!important;
    color: var(--default-color)!important;
    font-weight: 400;
    width: 100%;
    text-align: center;
    padding-left: 30px;
  }

  .password-group{
    position: relative;
  }
  .hide-show-btn{
    position: absolute;
    top: 12px;
    right: 10px;
    border: none;
    background: transparent;
  }

  @media (min-width: 576px) {
    .modal-dialog {
        max-width: 690px !important;
        margin: 1.75rem auto;
    }
  }

  @media(max-width: 767px) {
    #login-modal {
      a {
        font-size: 13px;
      }
    }
  }
</style>
