<template>
  <div class="certificates-wrapper mt-5 mb-5 p-0" v-if="getCertificates.length > 0" >
    <h2 class="section-title certificate-title">Empresa devidamente registrada nos órgãos reguladores</h2>
    <ul class="certificates">
      <li class="certificate mb-3" v-bind:key="certificate.name" v-for="(certificate) in getCertificates">
        <img :src="certificate.logo" :title="certificate.name" style="width:150px;object-fit:cover;">
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Ceritificates',
  computed: {
    getCertificates () {
      return this.$store.state.companyDetails.certificates
    }
  }
}
</script>

<style lang="scss" scoped>
  .certificates-wrapper {
    padding: 30px 15px;
    background-color: #ffffff;

    .certificate-title {
      color: black!important;
      line-height: 30px;
      margin: 0;
      font-size: 20px!important;
      max-width: 100%!important;
      text-align: center;
    }

    .certificates {
      list-style: none;
      padding: 0;

      .certificate {
        display: inline-block;
        padding: 0 20px;
      }
    }
  }
</style>
