<template>
  <div id="login-modal" :style="cssProps" >
    <div class="menu-action" v-if="this.type != 'search-form'">
      <div v-if="this.type == 'result-list'" class="btn-list">
        <a @click="show = !show, showRegisterForm = false, showLoginForm = true , isCodePassword = false">Acessar minha conta</a>
        <span>Ou</span>
        <a @click="show = !show, registerForm()">Cadastre-se</a>
      </div>
      <div v-else-if="this.checkoutProp" @click="show = !show">
        Esqueceu sua senha?
      </div>
      <div v-else>
        <span class="mr-2">Olá, Bem-vindo(a)</span>
        <span style="font-weight: bold" @click="show = !show, showRegisterForm = false, showLoginForm = true  , isCodePassword = false">Entrar</span> ou <span style="font-weight: bold" @click="show = !show, registerForm() , isCodePassword = false">Cadastrar</span>
      </div>
    </div>
    <b-modal
    ref="login-modal"
    id="login-modal"
    v-bind:title="title"
    content-class=""
    v-model="show"
    @hide="closeModal"
    hide-footer
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <div class="col-12 p-0">
          <div class="d-flex w-100 justify-content-between">
            <h5>{{title}}</h5>
            <button type="button" aria-label="Close" class="close" @click="close()">×</button>
          </div>
          <div class="d-flex">
            <p v-if="extra_data">{{ extra_data }}</p>
          </div>
        </div>
      </template>
      <form :style="cssProps" class="login100-form validate-form" v-on:submit.prevent="login" v-if="!showRegisterForm && showLoginForm">
        <div :class="{'form-group':true, 'has-feedback': errors.email}">
            <div class="wrap-input-card validate-input" data-validate="Enter username">
                <input
                    class="input-card form-control input-form"
                    type="text"
                    name="username"
                    placeholder="Login"
                    v-model="credentials.email"
                />
            </div>
            <div class="invalid-feedback " v-if="errors.email">
            {{ errors.email[0] }}
            </div>
        </div>
        <div :class="{'form-group':true, 'has-feedback': errors.password , 'password-group':true}">
            <div
            class="wrap-input-card validate-input"
            data-validate="Enter password"
            >
            <input
                class="input-card form-control input-form"
                :type="passwordFieldType"
                name="pass"
                placeholder="Senha"
                v-model="credentials.password"
            />
              <button class="hide-show-btn" v-if="this.passwordFieldType == 'password'" v-on:click.prevent="switchVisibility" type="password"><b-icon icon="eye"></b-icon></button>
              <button class="hide-show-btn" v-else v-on:click.prevent="switchVisibility" type="password"><b-icon icon="eye-slash
"></b-icon></button>
            </div>

            <div class="invalid-feedback " v-if="errors.password">
            {{ errors.password[0] }}
            </div>
            <div style="color:red " v-if="errorMsg">
              {{ this.errorMsg }}
            </div>
        </div>
        <div class="pt-3 text-center" >
            <button type="button" @click="forgetPassword" class="btn white forgot-pass">Esqueceu sua senha?</button>
        </div>
        <div class="login-actions">
            <div class="row">
              <div class="col-12">
                <button class="btn btn-success btn-block">ENTRAR</button>
              </div>
            </div>
        </div>

        <div class="col-12 text-center">
            <a class="register-account" href="javascript:void(0)"  @click.prevent="registerForm()">Não tem conta? Cadastre-se</a>
          </div>
      </form>
      <div v-else-if="isForgetPassword == false && showRegisterForm == true">
        <form :style="cssProps" class="login100-form validate-form" v-on:submit.prevent="register">
          <div :class="{'form-group':true, 'has-feedback': errors.name}">
            <div class="wrap-input100 validate-input" data-validate="Nome é obrigatório">
                <input
                class="form-control"
                type="text"
                name="name"
                v-model="userForm.name"
                placeholder="Nome completo"
                />

            </div>
            <div class="invalid-feedback " v-if="errors.name">
                {{ errors.name[0] }}
            </div>
          </div>
          <div :class="{'form-group':true, 'has-feedback': errors.tel}">
            <div class="wrap-input100 validate-input" data-validate="Telefone é obrigatório">
                  <the-mask :mask="['(##) ####-####', '(##) #####-####']"
                    class="form-control"
                    type="tel"
                    v-model="userForm.tel"
                    name="tel"
                    placeholder="Telefone"
                     />

              </div>
              <div class="invalid-feedback " v-if="errors.tel">
                {{ errors.tel[0] }}
            </div>
          </div>

          <div :class="{'form-group':true, 'has-feedback': errors.email}">
            <div
                class="wrap-input100 validate-input"
                data-validate="E-mail é obrigatório"
            >
                <input
                class="form-control"
                type="email"
                name="email"
                v-model="userForm.email"
                placeholder="E-mail"
                />

            </div>

            <div class="invalid-feedback " v-if="errors.email">
              {{ errors.email[0] }}
            </div>
          </div>

          <div :class="{'form-group':true, 'has-feedback': errors.password}">
            <div
                class="wrap-input100 validate-input"
                data-validate="Senha é obrigatório"
            >
                <input
                class="form-control"
                type="password"
                v-model="userForm.password"
                name="pass"
                placeholder="Senha"
                />

            </div>

            <div class="invalid-feedback " v-if="errors.password">
                {{ errors.password[0] }}
            </div>

          </div>

          <div class="login-actions">
            <div class="container-login100-form-btn" style="display:flex; justify-content:space-between">
              <button @click="back" type="button" class="btn btn-primary back-btn">Voltar</button>
              <button class="btn btn-success" :style="{background: primaryColor, border: primaryColor, color: '#fff'}">Enviar</button>
            </div>
          </div>

        </form>
      </div>
      <div v-if="isForgetPassword">
        <form :style="cssProps" class="login100-form validate-form" v-on:submit.prevent="sendCode()">

          <div :class="{'form-group':true, 'has-feedback': errors.email}">
            <div
                class="wrap-input100 validate-input"
                data-validate="E-mail é obrigatório"
            >
                <input
                class="form-control"
                type="email"
                name="email"
                v-model="restoreForm.email"
                placeholder="E-mail"
                />

            </div>

            <div class="invalid-feedback " v-if="errors.email">
              {{ errors.email[0] }}
            </div>
          </div>

          <div class="login-actions">
            <div class="container-login100-form-btn" style="display:flex; justify-content:space-between">
              <button @click="back" type="button" class="btn btn-primary back-btn">Voltar</button>
              <button class="btn btn-success" :style="{background: primaryColor, border: primaryColor, color: '#fff'}">Enviar</button>
            </div>
          </div>

        </form>
      </div>
      <!-- reset password -->
      <div v-if="isCodePassword">
        <form :style="cssProps" class="login100-form validate-form" v-on:submit.prevent="checkCode()">
          <div
              class="wrap-input100 validate-input"
              data-validate="Código é obrigatório"
          >
              <input
              class="form-control"
              type="number"
              name="code"
              v-model="restoreForm.code"
              placeholder="Código"
              />

          </div>

          <div class="invalid-feedback " v-if="errors.email">
            {{ errors.email[0] }}
          </div>
          <div class="login-actions">
            <div class="container-login100-form-btn" style="display:flex; justify-content:space-between">
              <button @click="isForgetPassword = true, isCodePassword = false" type="button" class="btn btn-primary back-btn">Voltar</button>
              <button class="btn btn-success" :style="{background: primaryColor, border: primaryColor, color: '#fff'}">Enviar</button>
            </div>
          </div>

        </form>
      </div>
      <!-- Reset forms -->
      <div v-if="isPasswordRestore">
        <form :style="cssProps" class="login100-form validate-form" v-on:submit.prevent="resetPassword()">
            <div
                class="wrap-input100 validate-input"
                data-validate="Senha é obrigatória"
            >
                <input
                class="form-control"
                type="password"
                name="password"
                v-model="restoreForm.password"
                placeholder="Nova senha"
                />

            </div>
            <div
                class="wrap-input100 validate-input"
                data-validate="Confirmação de senha é obrigatória"
            >
                <input
                class="form-control"
                type="password"
                name="password_confirmation"
                v-model="restoreForm.password_confirmation"
                placeholder="Confirmar nova senha"
                />

            </div>
          <div class="login-actions">
            <div class="container-login100-form-btn" style="display:flex; justify-content:space-between">
              <button @click="isForgetPassword = false, isCodePassword = true ,isPasswordRestore = false" type="button" class="btn btn-primary back-btn">Voltar</button>
              <button class="btn btn-success" :style="{background: primaryColor, border: primaryColor, color: '#fff'}">Enviar</button>
            </div>
          </div>

        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
export default {
  name: 'PrintOrSaveModal',
  props: ['checkout', 'navbar', 'text', 'type', 'extra_data', 'showLoginModal', 'showRegister', 'registerToContinue'],
  data () {
    return {
      passwordFieldType: 'password',
      checkoutProp: this.checkout,
      show: this.showmodal || false,
      showRegisterForm: !!this.checkout,
      requireRegister: this.registerToContinue,
      showLoginForm: false,
      successSearch: false,
      isForgetPassword: !!this.checkout,
      isCodePassword: !!this.checkout,
      isPasswordRestore: !!this.checkout,
      credentials: {
        email: '',
        password: ''
      },
      userForm: {
        name: '',
        email: '',
        password: '',
        status: 1,
        cnpj_cpf: '',
        tel: ''
      },
      restoreForm: {
        email: '',
        code: '',
        password: '',
        password_confirmation: ''
      },
      errorMsg: null
    }
  },
  computed: {
    ...mapGetters(['errors']),
    ...mapGetters('auth', ['user']),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.primaryColor || ''
    },
    title () {
      if(this.showRegisterForm && this.requireRegister ){
        return 'Finalize o seu cadastro para continuar'
      }
      else if (this.showRegisterForm && this.isForgetPassword === false) {
        return 'Cadastrar'
      } else if (this.isForgetPassword) {
        return 'Digite o email para recuperar a senha'
      } else if (this.isCodePassword) {
        return 'Digite o código de validação'
      } else if (this.isPasswordRestore) {
        return 'Digite a nova senha'
      }
      return 'Iniciar Sessão'

      // return this.showRegisterForm ? 'Cadastrar' : 'Login'
    },
    titleLink () {
      return this.text ? this.text : this.checkoutProp ? 'ESQUECEU SUA SENHA? CLIQUE AQUI!' : `Olá, Bem-vindo(a) <br> <span style="font-weight: bold">Entrar</span> ou <span style="font-weight: bold" @click="registerForm()">Cadastrar</span>`
    }
  },
  mounted () {
    if (this.type === 'search-form') {
      this.credentials.email = this.$store.state.auth.tmpUserData.email
      if (this.showLoginModal) {
        this.showLoginForm = true
        this.showModal()
      }
    }
    if(this.$store.state.callRegisterModal) {
      this.showModal()
      this.registerForm()
      if(this.registerToContinue){
        this.userForm.name = this.$store.state.auth.tmpUserData.name
        this.userForm.email = this.$store.state.auth.tmpUserData.email
        this.userForm.tel = this.$store.state.auth.tmpUserData.tel
      }
    }
  },
 
  methods: {
    ...mapActions('auth', ['sendLoginRequest']),
    ...mapActions('auth', ['sendRegisterRequest']),
    ...mapMutations(['SET_LOADING_STATE']),
    toggleLoading(value){
      this.SET_LOADING_STATE({ value: value })
    },
    async sayHello () {
      const timestamp = Date.now()
      const lastLogin = new Date(this.$store.state.lastLoginEvent)
      const actualTime = new Date(timestamp)

      const diffInHours = (actualTime.getTime() - lastLogin.getTime()) / (1000 * 60 * 60)
      if (this.$store.state.auth.userData && diffInHours > 2) {
        var component = this
        let userInfo = {}
        userInfo['mensagem'] = this.$store.state.auth.userData.name + ' acabou de acessar o site'
        userInfo['email'] = this.$store.state.auth.userData.email
        userInfo['telefone'] = this.$store.state.auth.userData.tel
        window.Tawk_API.addEvent('novo-acesso', userInfo, function (error) {
          if (error) {
          } else {
            let now = Date.now()
            component.$store.commit('LAST_LOGIN_EVENT', now)
          }
        })
      }
    },
    getTripType (type) {
      switch (type) {
        case 'onewaytrip':
          return 'Viagem somente ida'
          break
        case 'roundtrip':
          return 'Viagem ida e volta'
          break
        case 'customtrip':
          return 'Viagem com roteiro personalizado'
          break
        case 'hourtrip':
          return 'Viagem por hora'
          break
        default:
          return 'Viagem somente ida'
          break
      }
    },
    getCustomRoutes () {
      let customRoutes = []
      this.$store.state.customRouter.days.forEach(function (day, index) {
        customRoutes[index] = []
        customRoutes[index]['date'] = day.date
        day.routes.forEach(function (route, routeIndex) {
          customRoutes[index]['origin'] = route.origin.address
          customRoutes[index]['stop_points'] = []
          route.stop_points.forEach(function (point, pointIndex) {
            customRoutes[index]['stop_points'].push(point.address)
          })
          if (routeIndex == day.routes.length - 1) {
            customRoutes[index]['destination'] = route.destination.address
          }
        })
      })
      return customRoutes
    },
    fireTawkToEvent () {
      let eventData = {}
      let userData = {}
      let serviceTrip = ''
      let customRouter = this.$store.state.tripType === 'customtrip' ? this.getCustomRoutes() : false

      if (!this.$store.state.auth.userData) {
        userData['usuario'] = 'Novo Lead'
        userData['nome'] = this.$store.state.auth.tmpUserData.name
        userData['email'] = this.$store.state.auth.tmpUserData.email
        userData['telefone'] = this.$store.state.auth.tmpUserData.tel
      } else {
        userData['usuario'] = 'Usuário já cadastrado'
        userData['nome'] = this.$store.state.auth.userData.name
        userData['email'] = this.$store.state.auth.userData.email
        userData['telefone'] = this.$store.state.auth.userData.tel
      }

      eventData['servico'] = this.getTripType(this.$store.state.tripType)

      if (customRouter) {
        customRouter.forEach(function (day, index) {
          eventData['dia-' + (index + 1)] = day.date
          eventData['origem-dia-' + (index + 1)] = day.origin
          if (day.stop_points.length > 0) {
            day.stop_points.forEach(function (stopPoint, index) {
              eventData['ponto-de-embarque-' + (index + 2) + '-dia-' + (index + 1)] = stopPoint
            })
          }
          eventData['destino-dia-' + (index + 1)] = day.destination
        })
      } else {
        var originDate = this.moment(this.$store.state.simpleTrip.origin.date).format('DD/MM/YYYY')
        eventData['origem'] = '[' + originDate + '] ' + this.$store.state.simpleTrip.origin.address
        var stop_points = this.$store.state.simpleTrip.stop_points
        if (stop_points.length > 0) {
          eventData['ponto-de-embarque'] = 'Possui ' + stop_points.length + ' ponto(s) de embarque ou desembarque'
        }

        if (this.$store.state.tripType === 'roundtrip') {
          var destinationDate = this.moment(this.$store.state.simpleTrip.destination.date).format('DD/MM/YYYY')
          eventData['destino'] = '[' + destinationDate + '] ' + this.$store.state.simpleTrip.destination.address
        } else {
          eventData['destino'] = this.$store.state.simpleTrip.destination.address
        }

        if (this.$store.state.tripType === 'hourtrip') {
          eventData['franquia-de-horas'] = this.$store.state.simpleTrip.franchise + ' Horas'
        }
      }

      if (this.$store.state.companyDetails.whitelabel.chats.tawkto !== null) {
        // if (!this.$store.state.auth.userData) {
        //   window.Tawk_API.addTags(['usuário não registrado'], function (error) { console.log(error) })
        // }

        eventData = {
          ...userData,
          ...eventData
        }
        this.tawkToEvent('nova-pesquisa', eventData)
      }
    },
    tawkToEvent (event, data) {
      if (this.$store.state.companyDetails.whitelabel.chats.tawkto !== null) {
        window.Tawk_API.addEvent(event, data, function (error) {
          console.log(error)
        })
      }
    },
    async login () {
      this.sendLoginRequest(this.credentials).then(async (response) => {
        if (response.status !== 200) {
          this.errorMsg = response.data.message
        } else {
          this.toggleLoading(true)
          await this.sayHello()
          await this.fireTawkToEvent()
          if (this.$store.state.loginToViewResults) {
            this.$store.commit('LOGIN_TO_VIEW_RESULTS', false)
            this.showModal(false)
            this.$router.push('search-results')
          }
          this.toggleLoading(false)
          this.$store.commit('SET_BLOCK_UI', false)
        }
      })
    },
    cpfValidate () {
      var soma = 0
      var i
      var resto
      var inputCPF = this.userForm.cnpj_cpf
      if (!inputCPF) return false

      if (inputCPF === '00000000000') return false
      for (i = 1; i <= 9; i++) { soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i) }
      resto = (soma * 10) % 11
      if (resto === 10 || resto === 11) resto = 0
      if (resto !== parseInt(inputCPF.substring(9, 10))) return false

      soma = 0
      for (i = 1; i <= 10; i++) { soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i) }
      resto = (soma * 10) % 11

      if (resto == 10 || resto == 11) resto = 0
      if (resto != parseInt(inputCPF.substring(10, 11))) return false

      return true
    },
    async register () {
      this.toggleLoading(true)
      this.sendRegisterRequest(this.userForm).then((response) => {
        this.$store.commit('SET_LOADING_STATE', { value: true })
        if (response !== null && response.status === 200) {
          this.showRegisterForm = false
          this.isCodePassword = false
          this.showLoginForm = false
          this.isForgetPassword = false
          this.showRegisterForm = false
          this.isPasswordRestore = false
          this.show = false;

          this.$swal({
              title: 'Cadastrado com sucesso!',
              showDenyButton: false,
              showConfirmButton: false,
              icon: 'success',
              timer: 2500,
          })
    
          if(this.$store.state.callRegisterModal) {
            this.$store.commit('SET_LOADING_STATE', { value: false })
            this.$store.commit('CALL_REGISTER_MODAL', false)
            this.toggleLoading(false)
            
            this.$router.push('/search-results')
          }else{
            this.toggleLoading(false)
          }
        } else {
          this.$store.commit('SET_LOADING_STATE', { value: false })
          this.toggleLoading(false)
          this.$swal({
            title: 'Erro!',
            text: response.data.message,
            showDenyButton: false,
            showConfirmButton: true,
            confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            icon: 'warning'
          })
        }
      })
    },
    switchVisibility () {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    sendCode () {
      this.$store.commit('SET_LOADING_STATE', { value: true })
      let userData = {
        email: this.restoreForm.email
      }
      axios.post(process.env.API_URL + 'password/send-code', userData).then((response) => {
        if (response.status === 200) {
          return new Promise((resolve, reject) => {
            this.$store.commit('SET_LOADING_STATE', { value: false })
            this.$swal({
              title: 'Sucesso!',
              text: 'Código enviado para o email',
              showDenyButton: false,
              confirmButtonText: 'Digitar código',
              confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
              iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
              denyButtonColor: '#b5b5b5',
              icon: 'info'
            }).then((result) => {
              if (result.isConfirmed) {
                this.isCodePassword = true
                this.showLoginForm = false
                this.isForgetPassword = false
                this.showRegisterForm = false
                this.isPasswordRestore = false
                // this.show = !this.show;
              }
              resolve(result.isConfirmed)
            })
          })
        } else {
          this.$store.commit('SET_LOADING_STATE', { value: false })
          this.$swal({
            title: 'Erro!',
            html: response.data.message,
            showDenyButton: false,
            confirmButtonText: 'Ok',
            confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            icon: 'warning'
          })
        }
      })
    },
    checkCode () {
      let userData = {
        code: this.restoreForm.code
      }
      axios.post(process.env.API_URL + 'password/check-code', userData).then((response) => {
        if (response.status === 200) {
          this.isCodePassword = false
          this.showLoginForm = false
          this.isForgetPassword = false
          this.showRegisterForm = false
          this.isPasswordRestore = true
        }
      })
    },
    resetPassword () {
      let userData = {
        code: this.restoreForm.code,
        password: this.restoreForm.password,
        password_confirmation: this.restoreForm.password_confirmation
      }
      axios.post(process.env.API_URL + 'password/restore', userData).then((response) => {
        return new Promise((resolve, reject) => {
          this.$swal({
            title: 'Sucesso!',
            text: 'Sua senha foi alterada com sucesso!',
            showDenyButton: false,
            confirmButtonText: 'Fazer login',
            confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            denyButtonColor: '#b5b5b5',
            icon: 'info'
          }).then((result) => {
            if (result.isConfirmed) {
              this.isCodePassword = false
              this.showLoginForm = true
              this.isForgetPassword = false
              this.showRegisterForm = false
              this.isPasswordRestore = false
            }
            resolve(result.isConfirmed)
          })
        })
      })
    },
    registerForm () {
      this.showLoginForm = false
      this.showRegisterForm = true
    },
    closeModal () {
      this.$store.commit('LOGIN_TO_VIEW_RESULTS', false)
      this.$emit('close', false)
    },
    showModal () {
      this.show = !this.show
    },
    back () {
      this.showLoginForm = true
      this.showRegisterForm = false
      this.isForgetPassword = false
      this.isCodePassword = false
    },
    forgetPassword () {
      this.isForgetPassword = true // vai para esqueceu a senha
      this.showRegisterForm = true // retira o modal de login -> cadastrar
    },
    verifyPropCheckout () {
    }
  }
}
</script>

<style lang="scss">

  .menu-action {
    span {
      &:hover {
        &:not(:first-child){
          cursor: pointer;
          color:  var(--default-color)!important;
        }
      }
    }
    .btn-list {
      span {
        margin: 0 10px;
      }
    }
  }
  .btn-success  {
    background-color: var(--default-color)!important;
  }
  #login-modal .modal-content{
    width: 100% !important;
  }
  #login-modal .modal-content {
    background: whitesmoke!important;
    // width: 500px!important;
    margin: 0 auto;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .validate-input{
    margin-bottom: 20px;
  }

  #login-modal .modal-open .modal{
    background: rgba(82, 82, 82, 0.58)!important;
  }

  #login-modal .modal-footer,#login-modal .modal-header  {
    border: none !important;
  }

  #login-modal .modal-header .close {
    background: #dbdbdb;
    border-radius: 140px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px!important;
    opacity: 1!important;
    color: white;
    font-weight: 300;
    &:hover  {
      background: #605f5f;
      color: white!important;
    }
  }

  .container-login100-form-btn .back-btn:hover {
    border-color: var(--default-color)!important;
    color: var(--default-color)!important;
  }

  #login-modal .modal-title {
    font-size: 22px!important;
    color: var(--default-color)!important;
    font-weight: 400;
  }

  .password-group{
    position: relative;
  }
  .hide-show-btn{
    position: absolute;
    top: 12px;
    right: 10px;
    border: none;
    background: transparent;
  }

  @media (min-width: 576px) {
    #login-modal .modal-dialog {
        max-width: 690px !important;
        margin: 1.75rem auto;
    }
  }

  @media(max-width: 767px) {
    #login-modal {
      a {
        font-size: 13px;
      }
    }
  }
</style>
