<template>
    <div class="trip-information" :style="cssProps">
        <b-row class="mb-3">
            <b-col xl="6" xs="12">
                <Places
                    :id="'origin'"
                    :placeholder="'De onde você vai sair?'"
                    :required="true"
                    :disabled="false"
                    :value="$store.state.simpleTrip.origin.address"
                    @send-message="setProp"

                />
            </b-col>
            <b-col xl="6" xs="12">
                <Places
                    :id="'destination'"
                    :isLastStep="true"
                    :placeholder="'Para onde você está indo'"
                    :required="true"
                    :disabled="false"
                    :value="$store.state.simpleTrip.destination.address"
                    @send-message="setProp"

                />
            </b-col>
        </b-row>

        <b-row class="mb-3">
            <b-col xl="6" xs="12">

               <div class="input-with-icon place-field d-flex">
                    <div class="icon">
                        <b-icon-calendar3></b-icon-calendar3>
                    </div>
                    <div class="input ml-3 w-100">
                        <date-picker
                            :lang="lang"
                            icon-calendar=""
                            format="DD/MM/YYYY"
                            type="date"
                            placeholder="Data da ida"
                            v-model="$store.state.simpleTrip.origin.date"
                            :disabled-date="notBeforeToday"
                            :value="initialDate"
                            value-type="YYYY/MM/DD"
                            @change="setSimpleTripProp($event, 'date')"
                        ></date-picker>
                    </div>
               </div>

            </b-col>
            <b-col xl="6" xs="12">

                <div class="input-with-icon place-field d-flex">
                    <div class="icon">
                        <b-icon-clock></b-icon-clock>
                    </div>
                    <div class="input ml-3 w-100">
                        <time-select-field
                            v-on:updateschedule="setSimpleTripProp($event, 'time')"
                            ref="originTime"
                            type="origin"
                            placeholder="Horário da ida"
                        >
                        </time-select-field>
                    </div>
               </div>

            </b-col>
        </b-row>
        <div v-if="$store.state.simpleTrip.stop_points.length > 0">
            <b-row class="mb-3 position-relative" v-for="(stop_point, stopIndex) in $store.state.simpleTrip.stop_points" :key="(randomKey * stopIndex)">
                <b-col xl="12" xs="12">
                    <Places
                        type="stop-point"
                        id="stop_points"
                        :isLastStep="true"
                        :placeholder="'Ponto de embarque ou desembarque'"
                        :required="true"
                        :disabled="false"
                        :index="stopIndex"
                        :value="$store.state.simpleTrip.stop_points[stopIndex].address"
                        @send-message="setSimpleStopPoint"
                    />
                </b-col>
                <b-icon-x-circle class="remove-stop-point" @click="removeStopPoint(index)"></b-icon-x-circle>
            </b-row>
        </div>

        <div v-if="$store.state.tripType == 'roundtrip'">
            <b-row class="mb-3">
                <b-col xl="6" xs="12">

                <div class="input-with-icon place-field d-flex">
                        <div class="icon">
                            <b-icon-calendar3></b-icon-calendar3>
                        </div>
                        <div class="input ml-3 w-100">
                            <date-picker
                                @change="setRoundTripProp($event, 'date')"
                                :disabled-date="excludeOriginDates"
                                icon-calendar=""
                                format="DD/MM/YYYY"
                                type="date"
                                placeholder="Data da volta"
                                :default-value="$store.state.simpleTrip.origin.date"
                                value-type="YYYY/MM/DD"
                                v-model="$store.state.simpleTrip.destination.date"
                            ></date-picker>
                        </div>
                </div>

                </b-col>
                <b-col xl="6" xs="12">

                    <div class="input-with-icon place-field d-flex">
                        <div class="icon">
                            <b-icon-clock></b-icon-clock>
                        </div>
                        <div class="input ml-3 w-100">
                            <time-select-field
                                v-on:updateschedule="setRoundTripProp($event, 'time')"
                                ref="destinationTime"
                                type="destination"
                                placeholder="Horário da volta"
                                v-model="$store.state.simpleTrip.destination.time"
                            >
                            </time-select-field>
                        </div>
                    </div>

                </b-col>
            </b-row>
        </div>

        <div v-if="$store.state.tripType === 'hourtrip'">
            <b-row class="mb-3">
                <b-col xl="12" xs="12">
                    <div class="input-with-icon place-field d-flex">
                        <div class="icon">
                            <b-icon-clock-history></b-icon-clock-history>
                        </div>
                        <div class="input ml-3 w-100">
                            <hour-trip-select
                                v-on:updatefranchise="updateFranchise($event, 'time')"
                                placeholder="Veículo disponível por"
                            >
                            </hour-trip-select>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>

        <b-row class="mb-3" v-if="(!$store.state.auth.userData || !$store.state.auth.userData.name || !$store.state.auth.userData.tel || !$store.state.auth.userData.email)">
          <b-col xl="4" xs="12">
            <div class="input-with-icon user-field d-flex">
              <div class="icon">
                  <b-icon-person></b-icon-person>
              </div>
              <div class="input ml-3 w-100">
                  <input type="text" v-model="user.name" @change="($event) => updateUserField($event, 'name')" placeholder="Seu nome" name="user-name" id="">
              </div>
            </div>
          </b-col>

          <b-col xl="4" xs="12">
            <div class="input-with-icon user-field d-flex">
              <div class="icon">
                  <b-icon-envelope></b-icon-envelope>
              </div>
              <div class="input ml-3 w-100">
                  <input type="text" v-model="user.email" placeholder="Seu email" name="user-email" id="">
              </div>
            </div>
          </b-col>

          <b-col xl="4" xs="12">
            <div class="input-with-icon user-field d-flex">
              <div class="icon">
                  <b-icon-whatsapp></b-icon-whatsapp>
              </div>
              <div class="input ml-3 w-100">
                  <input type="text" placeholder="Seu telefone" v-model="user.tel" name="user-phone" id="">
              </div>
            </div>
          </b-col>

        </b-row>
    </div>

</template>

<script>

import Places from '../../general/Places.vue'
import HourTripSelect from './HourTripSelect.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/pt-br'
import TimeSelectField from '@/components/fields/TimeSelectField.vue'

export default {
  name: 'SimpleTrip',
  components: {
    Places,
    DatePicker,
    TimeSelectField,
    HourTripSelect
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  },
  data () {
    return {
      lang: {
        default: 'pt-br'
      },
      user: this.$store.state.auth.tmpUserData ? this.$store.state.auth.tmpUserData : {
        name: null,
        email: null,
        tel: null
      },
      randomKey: Math.round(Math.random() * 1000),
      reservationStartAt: parseInt(this.$store.state.companyDetails.reservationStartAt),
      initialDate: this.moment().add(this.reservationStartAt, 'd').format('YYYY-MM-DD')
    }
  },

  mounted () {
  },

  methods: {
    updateUserField (event, field) {
      this.$store.commit('auth/tmpUserData', { key: field, value: event.target.value })
    },
    excludeOriginDates (date, max) {
      return date < new Date(this.$store.state.simpleTrip.origin.date)
    },
    notBeforeToday (date) {
      return date < new Date().setDate(new Date().getDate() + this.reservationStartAt)
    },
    getAddressField (data, field) {
      switch (field) {
        case 'state':
          let state = data.filter(item => item.types.includes('administrative_area_level_1'))
          return state.length > 0 ? state[0].long_name.replace('State of ', '') : ''
          break
        case 'state_short':
          let state_short = data.filter(item => item.types.includes('administrative_area_level_1'))
          return state_short.length > 0 ? state_short[0].short_name : ''
          break
        case 'route':
          let route = data.filter(item => item.types.includes('route'))
          return route.length > 0 ? route[0].long_name : ''
          break
        case 'city':
          let city = data.filter(item => item.types.includes('administrative_area_level_2'))
          return city.length > 0 ? city[0].long_name : ''
        case 'district':
          let district = data.filter(item => item.types.includes('sublocality_level_1'))
          return district.length > 0 ? district[0].long_name : ''
        case 'postal_code':
          let postal_code = data.filter(item => item.types.includes('postal_code'))
          return postal_code.length > 0 ? postal_code[0].long_name : ''
        default:
          break
      }
    },
    getAddressComponent (data) {
      return {
        route: this.getAddressField(data, 'route'),
        state: this.getAddressField(data, 'state'),
        state_short: this.getAddressField(data, 'state_short'),
        city: this.getAddressField(data, 'city'),
        district: this.getAddressField(data, 'district'),
        postal_code: this.getAddressField(data, 'postal_code')
      }
    },
    removeStopPoint (index) {
      this.$store.commit('REMOVE_STOP_POINT', index)
      this.updateStep()
    },
    updateFranchise (value, prop) {
      this.$store.commit('SIMPLE_TRIP_PROP', { prop: 'franchise', value: value })
    },
    setSimpleTripProp (value, prop) {
      this.$store.commit('ORIGIN_SIMPLE_TRIP_PROP', { prop: prop, value: value })
    },
    setRoundTripProp (value, prop) {
      this.$store.commit('SIMPLE_ROUND_TRIP_PROP', { prop: prop, value: value })
    },
    setProp (prop, addressObject) {
      var addressComponents = this.getAddressComponent(addressObject.address_components)
      let tripData = {
        address: addressObject.formatted_address,
        address_components: addressComponents,
        city: addressComponents.city,
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
        date: this.$store.state.simpleTrip[prop].date || '',
        time: this.$store.state.simpleTrip[prop].time || '00:00'
      }

      this.$store.commit('SIMPLE_TRIP_PROP', { prop: prop, value: tripData })
    },
    setSimpleStopPoint (id, addressObject, index) {
      var data = {}
      data['address'] = addressObject.formatted_address
      data['city'] = addressObject.address_components[2].long_name
      data['lat'] = addressObject.geometry.location.lat()
      data['lng'] = addressObject.geometry.location.lng()
      data['address_components'] = this.getAddressComponent(addressObject.address_components)

      this.$store.commit('SET_SIMPLE_TRIP_DAY_STOP_POINT', {
        value: data,
        index: index
      })
    },
    updateStep () {
      this.randomKey = Math.round(Math.random() * 1000)
    }
  }
}
</script>

<style>
    .mx-datepicker, .mx-datepicker *:not(i) {
        width: 100%;
    }
    .mx-input {
        height: fit-content;
        border: none!important;
        background: transparent;
        box-shadow: none!important;
        margin: 0!important;
        padding: 0!important;
    }

    .mx-icon-calendar {
        display: none!important;
    }
</style>

<style lang="scss" scoped>

.trip-information {
    .place-field, .user-field {
        border: 1px solid #ced4da;
        border-radius: 150px;
        padding: 8px 15px;
    }

}
</style>
