<template>
  <div id="vehicles-not-found" :style="cssProps">
    <svg id="broken-bus" version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 605 375" width="766" height="475">
      <path id="Shape 8" class="s0" d="m248.3 24.8c0 0 5.1-7.3 14.3-11.6 1-0.5 269.5-0.8 277.8 0.7 8.3 1.5 13 15.1 13 15.1l-0.4 28h-305z"/>
      <path id="Shape 2" class="s1" d="m248 34c0-11 9-20 20-20h265c11 0 20 9 20 20v287c0 11-9 20-20 20h-265c-11 0-20-9-20-20z"/>
      <g id="Folder 2">
        <path id="Shape 11" class="s1" d="m17 342c0-1.1 0.9-2 2-2h89c1.1 0 2 0.9 2 2 0 1.1-0.9 2-2 2h-89c-1.1 0-2-0.9-2-2z"/>
        <path id="Layer 1" class="s2" d="m37 337l27-79 24 79z"/>
      </g>
      <g id="Folder 1">
        <path id="Shape 11 copy" class="s1" d="m129 313.6c0-0.8 0.6-1.5 1.4-1.5h64.2c0.8 0 1.4 0.7 1.4 1.5 0 0.8-0.6 1.4-1.4 1.4h-64.2c-0.8 0-1.4-0.6-1.4-1.4z"/>
        <path id="Layer 1 copy" class="s2" d="m143.4 310l19.5-57 17.3 57z"/>
      </g>
      <path id="Shape 3" class="s3" d="m211 83c0-5.5 4.5-10 10-10h8c5.5 0 10 4.5 10 10v30c0 5.5-4.5 10-10 10h-8c-5.5 0-10-4.5-10-10z"/>
      <path id="Shape 5" class="s3" d="m470 346c0-2.8 2.2-5 5-5h31c2.8 0 5 2.2 5 5v13c0 2.8-2.2 5-5 5h-31c-2.8 0-5-2.2-5-5z"/>
      <path id="Shape 3 copy" class="s3" d="m562 83c0-5.5 4.5-10 10-10h8c5.5 0 10 4.5 10 10v30c0 5.5-4.5 10-10 10h-8c-5.5 0-10-4.5-10-10z"/>
      <path id="Shape 4" class="s3" d="m288 346c0-2.8 2.2-5 5-5h32c2.8 0 5 2.2 5 5v12c0 2.8-2.2 5-5 5h-32c-2.8 0-5-2.2-5-5z"/>
      <path id="Shape 6" class="s3" d="m264 288.5c0-6.9 5.6-12.5 12.5-12.5h34c6.9 0 12.5 5.6 12.5 12.5 0 6.9-5.6 12.5-12.5 12.5h-34c-6.9 0-12.5-5.6-12.5-12.5z"/>
      <path id="Shape 6 copy" class="s3" d="m478 288.5c0-6.9 5.6-12.5 12.5-12.5h34c6.9 0 12.5 5.6 12.5 12.5 0 6.9-5.6 12.5-12.5 12.5h-34c-6.9 0-12.5-5.6-12.5-12.5z"/>
      <path id="Shape 7" class="s4" d="m239 83h9v9h-9z"/>
      <path id="Shape 7 copy" class="s4" d="m552 83h9v9h-9z"/>
      <text id="FORA DE SERVIÇO" style="transform: matrix(1,0,0,1,288,45)" >
        <tspan  x="0" y="0" class="t5">N</tspan>
        <tspan  y="0" class="t5">Ã</tspan>
        <tspan  y="0" class="t5">O</tspan>
        <tspan  y="0" class="t5">&nbsp;</tspan>
        <tspan  y="0" class="t5"> </tspan>
        <tspan  y="0" class="t5">E</tspan>
        <tspan  y="0" class="t5">N</tspan>
        <tspan  y="0" class="t5">C</tspan>
        <tspan  y="0" class="t5">O</tspan>
        <tspan  y="0" class="t5">N</tspan>
        <tspan  y="0" class="t5">T</tspan>
        <tspan  y="0" class="t5">R</tspan>
        <tspan  y="0" class="t5">A</tspan>
        <tspan  y="0" class="t5">D</tspan>
        <tspan  y="0" class="t5">O</tspan>
      </text>
      <text id="404" style="transform: matrix(1,0,0,1,379,302)" >
        <tspan x="0" y="0" class="t6">4</tspan><tspan  y="0" class="t6">0</tspan><tspan  y="0" class="t6">4
    </tspan>
      </text>
      <path id="Shape 1" class="s4" d="m246 258h309v3h-309z"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  }
}
</script>

<style >
#vehicles-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
}

#broken-bus { height: 240px!important;}

tspan { white-space:pre }
.s0 { fill: var(--default-color)!important;stroke: var(--default-color)!important;stroke-width: 2 }
.s1 { fill: none;stroke: var(--default-color)!important;stroke-width: 5 }
.s2 { fill: var(--default-color)!important;stroke: var(--default-color)!important;stroke-width: 5 }
.s3 { fill: none;stroke: var(--default-color)!important;stroke-width: 3 }
.s4 { fill: var(--default-color)!important }
.t5 { font-size: 24px;fill: #ffffff;font-weight: 700;font-family: "Poppins-Bold", "Poppins" }
.t6 { font-size: 24px;fill: var(--default-color)!important;font-weight: 700;font-family: "Poppins-Bold", "Poppins" }
</style>
