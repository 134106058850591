<template>
<div id="custom-trip-route" :style="cssProps">
    <login-modal :showLoginModal="showLoginModal"  :showRegisterModal="showLoginModal" :registerToContinue="true" type="search-form" :key="randomKey * 7" :extra_data="extra_data" />

    <b-container fluid>
        <b-row class="h-100">
            <div v-if="$store.state.tripType == 'customtrip'" class="d-lg-none d-flex open-itinerary" @click.prevent="toggleItinerary()">
                <b-icon-map></b-icon-map>
            </div>
            <div :class="[true ? ' h-100' : '', $store.state.tripType == 'customtrip' ? 'col-xl-8 col-12 ' : 'col-12']">
                <div :class="[true ? 'search-form default-bordered': '', $store.state.tripType == 'customtrip' ? 'customtrip': '']">
                    <div class="d-flex justify-content-center align-items-center">
                        <trip-type :key="randomKey * 25" :updateStep="() => updateStep()"></trip-type>
                    </div>
                    <div :class="[true ? 'route-steps' : '', step == 1 ? 'trip-period' : '']">
                        <div class="step" v-if="$store.state.tripType !== 'customtrip'">
                            <simple-trip :key="randomKey + 1" :updateStep="() => updateStep()"></simple-trip>
                        </div>
                        <div v-else>
                            <div class="step" step="0" v-if="step == 0">
                                <step-one></step-one>
                            </div>
                            <div class="step" step="1" v-if="step == 1">
                                <step-two :data="stepData" :updateStep="() => updateStep()" :key="randomKey + 2"></step-two>
                            </div>
                        </div>
                    </div>
                    <b-row class="form-footer simple" v-if="$store.state.tripType != 'customtrip'">
                        <b-col xl="6" xs="12" class="submit-action">
                            <div class="d-flex align-items-center justify-content-start">
                                <div class="addStopPoints" @click.prevent="addStopPoint">
                                    + Adicionar pontos de embarque ou desembarque
                                </div>
                            </div>
                        </b-col>
                        <b-col xl="6" xs="12" class="submit-action">

                        <div class="d-flex align-items-center justify-content-center justify-content-lg-end w-100">
                            <button class="submit-form" @click="searchResults">
                                Ver orçamento
                            </button>
                        </div>

                        </b-col>
                    </b-row>
                    <div class="form-footer customtrip" v-if="$store.state.tripType == 'customtrip'">
                        <div class="col-12 h-100 d-flex justify-content-end align-items-center desktop-actions actions d-none d-xl-block">
                            <div class="h-100 d-flex justify-content-end align-items-center" v-if="this.step === 0">
                                <button @click.prevent="nextStep">Definir rotas</button>
                            </div>

                            <div class="h-100 d-flex flex-column flex-lg-row justify-content-lg-end justify-content-center align-items-center actions w-100" v-else-if="$store.getters.customRouterStep == ($store.state.customRouter.tripDays - 1)">
                                <button class="col-12 mb-2 mb-lg-0 col-lg-3" @click.prevent="customRouterPrevStep">voltar</button>
                                <button class="submit-form col-12 col-lg-5"  @click="searchResults">Ver orçamento</button>
                            </div>

                            <div class="h-100 d-flex justify-content-lg-end justify-content-center align-items-center actions" v-else>
                                <button @click.prevent="customRouterPrevStep">voltar</button>
                                <button @click.prevent="customRouterNextStep">Próxima data</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$store.state.tripType == 'customtrip'"
            :class="{'summary col-xl-4 d-xl-block show-summary':true,'active':showItinerary}">
                <div class="route-summary p-3">
                    <div class="heading">
                        <h3>Resumo do roteiro</h3>
                        <div class="date d-flex mb-3" v-if="itineraryInitialDate">
                            <span v-if="itineraryInitialDate">{{ itineraryInitialDate }} -> <span v-if="itineraryEndDate">{{ itineraryEndDate }}</span></span>
                        </div>

                        <itinerary-summary :key="(randomKey * 3)" route="home" :data="$store.state.customRouter.days"></itinerary-summary>
                    </div>
                    <div class="no-routes" v-if="
                        $store.state.customRouter.days.length == 0 ||
                        $store.state.customRouter.days.length > 0 && $store.state.customRouter.days[0].routes.length == 0 || $store.state.customRouter.days.length > 0 && $store.state.customRouter.days[0].routes.length > 0 && $store.state.customRouter.days[0].routes[0].origin.address == ''
                    ">
                        Você ainda não definiu seu itinerário
                    </div>
                </div>
            </div>
        </b-row>

    </b-container>
    <div id="footer">

        <div class="col-12 d-flex justify-content-around mobile-actions actions d-block d-xl-none">
            <b-col cols="6">
                <div class="row action">
                    <button>
                        Resumo do roteiro
                    </button>
                </div>
            </b-col>
            <b-col cols="6">
                <div class="row action chat">
                    <div class="notification">1</div>
                    <button @click.prevent="openChat">
                        Falar com atendente
                    </button>
                </div>
            </b-col>
        </div>
    </div>
</div>
</template>

<script>
import ItinerarySummary from './ItinerarySummary.vue'
import StepOne from './Steps/StepOne.vue'
import StepTwo from './Steps/StepTwo.vue'
import TripType from './TripType.vue'
import SimpleTrip from './SimpleTrip.vue'
import LoginModal from '@/components/modals/LoginModal.vue'
import ResultList from '../../search-results/ResultList.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  props: ['customStep', 'update'],
  name: 'CustomTripRoute',
  components: { LoginModal, StepOne, StepTwo, ItinerarySummary, TripType, SimpleTrip },
  data: function () {
    return {
      showModal: false,
      extra_data: false,
      step: 0,
      isLastStep: false,
      initialDate: this.$store.state.customRouter.initialDate,
      routesStep: this.$store.state.customRouter.step,
      stepData: this.getRoutesByDate(),
      stateStep: this.$store.getters.customRouterStep,
      tripDays: this.$store.state.customRouter.tripDays,
      randomKey: Math.round(Math.random() * 1000),
      showItinerary: false
    }
  },
  computed: {
    ...mapState(['isLoading']),
    showLoading() {
      return this.isLoading
    },
    itineraryInitialDate () {
      var initialDate = this.$store.state.customRouter.initialDate
      return initialDate && initialDate != 'Invalid date' ? this.moment(this.$store.state.customRouter.initialDate).format('DD/MM/YYYY') : false
    },
    itineraryEndDate () {
      var endDate = this.$store.state.customRouter.endDate
      return endDate && endDate != 'Invalid date' ? this.moment(this.$store.state.customRouter.endDate).format('DD/MM/YYYY') : false
    },
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    showLoginModal () {
      return this.showModal
    }
  },
  mounted () {
    if (this.$router.currentRoute.name !== 'search-results' && this.$store.state.resetContent) {
      this.$store.commit('RESET_CUSTOM_ROUTES')
      this.$store.commit('RESET_SIMPLE_ROUTES')
      this.$store.commit('CUSTOM_ROUTER_UPDATE_STEP')
    }
    if(this.$store.state.resetContent) {
      if (this.customStep != undefined) {
        this.$store.commit('SET_CUSTOM_ROUTER_STEP', 0)
        this.step = this.customStep
        this.customRouterPrevStep()
      }
      if (!this.$store.state.customRouter.days) {
        this.$store.commit('RESET_CUSTOM_ROUTES')
        this.$store.commit('CUSTOM_ROUTER_UPDATE_STEP')
      }
    }
  },
  methods: {
    ...mapMutations(['SET_LOADING_STATE']),
    toggleLoading(value){
      this.SET_LOADING_STATE({ value: value })
    },
    toggleItinerary () {
      this.showItinerary = !this.showItinerary
    },
    callLoginModal() {
      this.showModal = true;
    },
    getTripType (type) {
      switch (type) {
        case 'onewaytrip':
          return 'Viagem somente ida'
          break
        case 'roundtrip':
          return 'Viagem ida e volta'
          break
        case 'customtrip':
          return 'Viagem com roteiro personalizado'
          break
        case 'hourtrip':
          return 'Viagem por hora'
          break
        default:
          return 'Viagem somente ida'
          break
      }
    },
    getCustomRoutes () {
      let customRoutes = []
      this.$store.state.customRouter.days.forEach(function (day, index) {
        customRoutes[index] = []
        customRoutes[index]['date'] = day.date
        day.routes.forEach(function (route, routeIndex) {
          customRoutes[index]['origin'] = route.origin.address
          customRoutes[index]['stop_points'] = []
          route.stop_points.forEach(function (point, pointIndex) {
            customRoutes[index]['stop_points'].push(point.address)
          })
          if (routeIndex == day.routes.length - 1) {
            customRoutes[index]['destination'] = route.destination.address
          }
        })
      })
      return customRoutes
    },
    fireTawkToEvent () {
      let eventData = {}
      let userData = {}
      let serviceTrip = ''
      let customRouter = this.$store.state.tripType === 'customtrip' ? this.getCustomRoutes() : false

      if (!this.$store.state.auth.userData || this.$store.state.auth.userData.type_people === 'lead') {
        userData['usuario'] = 'Novo Lead'
        userData['nome'] = this.$store.state.auth.tmpUserData.name
        userData['email'] = this.$store.state.auth.tmpUserData.email
        userData['telefone'] = this.$store.state.auth.tmpUserData.tel
      } else {
        userData['usuario'] = 'Usuário já cadastrado'
        userData['nome'] = this.$store.state.auth.userData.name
        userData['email'] = this.$store.state.auth.userData.email
        userData['telefone'] = this.$store.state.auth.userData.tel
      }

      eventData['servico'] = this.getTripType(this.$store.state.tripType)

      if (customRouter) {
        customRouter.forEach(function (day, index) {
          eventData['dia-' + (index + 1)] = day.date
          eventData['origem-dia-' + (index + 1)] = day.origin
          if (day.stop_points.length > 0) {
            day.stop_points.forEach(function (stopPoint, index) {
              eventData['ponto-de-embarque-' + (index + 2) + '-dia-' + (index + 1)] = stopPoint
            })
          }
          eventData['destino-dia-' + (index + 1)] = day.destination
        })
      } else {
        var originDate = this.moment(this.$store.state.simpleTrip.origin.date).format('DD/MM/YYYY')
        eventData['origem'] = '[' + originDate + '] ' + this.$store.state.simpleTrip.origin.address
        var stop_points = this.$store.state.simpleTrip.stop_points
        if (stop_points.length > 0) {
          eventData['ponto-de-embarque'] = 'Possui ' + stop_points.length + ' ponto(s) de embarque ou desembarque'
        }

        if (this.$store.state.tripType === 'roundtrip') {
          var destinationDate = this.moment(this.$store.state.simpleTrip.destination.date).format('DD/MM/YYYY')
          eventData['destino'] = '[' + destinationDate + '] ' + this.$store.state.simpleTrip.destination.address
        } else {
          eventData['destino'] = this.$store.state.simpleTrip.destination.address
        }

        if (this.$store.state.tripType === 'hourtrip') {
          eventData['franquia-de-horas'] = this.$store.state.simpleTrip.franchise + ' Horas'
        }
      }

      if (this.$store.state.companyDetails.whitelabel.chats.tawkto !== null) {
        // if (!this.$store.state.auth.userData) {
        //   window.Tawk_API.addTags(['usuário não registrado'], function (error) { console.log(error) })
        // }

        eventData = {
          ...userData,
          ...eventData
        }
        this.tawkToEvent('nova-pesquisa', eventData)
      }
    },

    tawkToEvent (event, data) {
      if (this.$store.state.companyDetails.whitelabel.chats.tawkto !== null) {
        window.Tawk_API.addEvent(event, data, function (error) {
          console.log(error)
        })
      }
    },
    getAddressField (data, field) {
      switch (field) {
        case 'state':
          let state = data.filter(item => item.types.includes('administrative_area_level_1'))
          return state.length > 0 ? state[0].long_name.replace('State of ', '') : ''
          break
        case 'state_short':
          let state_short = data.filter(item => item.types.includes('administrative_area_level_1'))
          return state_short.length > 0 ? state_short[0].short_name : ''
          break
        case 'route':
          let route = data.filter(item => item.types.includes('route'))
          return route.length > 0 ? route[0].long_name : ''
          break
        case 'city':
          let city = data.filter(item => item.types.includes('administrative_area_level_2'))
          return city.length > 0 ? city[0].long_name : ''
        case 'district':
          let district = data.filter(item => item.types.includes('sublocality_level_1'))
          return district.length > 0 ? district[0].long_name : ''
        case 'postal_code':
          let postal_code = data.filter(item => item.types.includes('postal_code'))
          return postal_code.length > 0 ? postal_code[0].long_name : ''
        default:
          break
      }
    },
    getAddressComponent (data) {
      return {
        route: this.getAddressField(data, 'route'),
        state: this.getAddressField(data, 'state'),
        state_short: this.getAddressField(data, 'state_short'),
        city: this.getAddressField(data, 'city'),
        district: this.getAddressField(data, 'district'),
        postal_code: this.getAddressField(data, 'postal_code')
      }
    },
    callErrorAlert (action, message) {
      this.errors = true
      this.$swal({
        text: message,
        showDenyButton: false,
        showConfirmButton: true,
        iconColor: action === 'error' ? '#e24d41' : this.$store.state.companyDetails.whitelabel.body_font_color,
        confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
        icon: action
      })
    },
    validateErrors: function () {
      this.errors = {}
      if (this.$store.state.tripType === 'customtrip') {

        if(this.routerDaysIsEmpty()){
          this.errors['routerDaysIsEmpty'] = 'É necessário adicionar ao menos um dia'
        }
        
      } else {
        if (this.$store.state.simpleTrip.origin.address === '') {
          this.errors['origin'] = 'O campo origem é obrigatório'
        }
        if (this.$store.state.simpleTrip.destination.address === '') {
          this.errors['destination'] = 'O campo destino é obrigatório'
        }
        if (this.$store.state.simpleTrip.origin.date === '') {
          this.errors['destination'] = 'O campo data é obrigatório'
        }

        if (this.$store.state.tripType === 'roundtrip' && this.$store.state.simpleTrip.destination.date === '') {
          this.errors['destination'] = 'O campo data é obrigatório'
        }

        if (this.$store.state.tripType === 'hourtrip' && !this.$store.state.simpleTrip.franchise) {
          this.errors['hourtrip'] = 'A franquia de horas é obrigatória'
        }

        if (!this.$store.state.auth.userData && this.tempUserDataIsImcomplete()) {
          this.errors['userData'] = 'Dados do usuário incompletos'
        }
        
      }
    },
    convertLeadToCustomer: async function () {
      this.toggleLoading(true)
      let result = true;
      let userData = this.$store.state.auth.tmpUserData
      await this.$store.dispatch('auth/convertLeadToCustomer', userData).then((response) => {
        if (response.data.ask_password) {
          this.extra_data = response.data.message
          this.showModal = true
          this.toggleLoading(false)
          result = false;
        }
      }).catch((error) => {
        console.log(error)
        result = false;
      })

      return result;
    },
    searchResults: async function () {
      this.$store.commit('TOGGLE_RESET_FORM', false )
      this.$store.commit('MUST_SAVE_SEARCH', true)
      
      this.errors = {}
      this.validateErrors()
   

      if (Object.keys(this.errors).length > 0) {
        this.callErrorAlert('error', 'Todos os campos são obrigarórios')
        return
      }

      if (!this.$store.state.auth.userData) {
        
        const leadRegister = await this.convertLeadToCustomer();
        if(!leadRegister) {
         
          this.$store.commit('LOGIN_TO_VIEW_RESULTS', true)
        
          this.randomKey = Math.round(Math.random() * 1500)
          this.callLoginModal()
          this.$forceUpdate()

          return;
        }
        
      }else{
        this.$store.commit('CALL_REGISTER_MODAL', false)
      }

      await this.fireTawkToEvent()
      if (this.$router.currentRoute.name === 'search-results') {
        this.$router.go()
      } else {
        this.$router.push('search-results')
      }
    },
    addStopPoint () {
      this.$store.commit('ADD_STOP_POINT')
      this.updateStep()
    },
    goTo (step) {
      this.step = step
    },
    updateStep () {
      this.randomKey = Math.round(Math.random() * 1000)
    },
    getRoutesByDate () {
      var step = this.$store.getters.customRouterStep
      return this.$store.state.customRouter.days[step]
    },
    openChat () {
      window.Tawk_API.toggle()
    },
    routerDaysIsEmpty() {
      if(
        this.$store.state.customRouter.initialDate == '' ||
        this.$store.state.customRouter.endData == ''
        ) {
        return true
      }else{
        return false
      }
    },
    validateFirstStep () {
      if (this.routerDaysIsEmpty()) {
        this.$swal({
          text: 'Você precisa definir as datas para continuar',
          showDenyButton: false,
          showConfirmButton: true,
          iconColor: '#e24d41',
          confirmButtonColor: '#e24d41',
          icon: 'error'
        })
        return false
      }
      return true
    },
    tempUserDataIsImcomplete () {
      let userData = this.$store.state.auth.tmpUserData
      if (!userData.name || !userData.email || !userData.tel) {
        return true
      }
      return false
    },
    hasUserData() {
      if (!this.$store.state.auth.userData && this.tempUserDataIsImcomplete()) {
        this.$swal({
          text: 'Preencha os dados pessoais para continuar',
          showDenyButton: false,
          showConfirmButton: true,
          iconColor: '#e24d41',
          confirmButtonColor: '#e24d41',
          icon: 'error'
        })
        return false
      }
      return true
    },
    async nextStep () {

      if(!this.validateFirstStep()){
        return
      }

      if(!this.hasUserData()){
        return
      }

      this.$store.commit('SET_CUSTOM_ROUTER_STEP', 0)
      this.step = this.step + 1
      this.updateStep()
      this.randomKey = Math.round(Math.random() * 1500)
      this.defineRoutes()
      this.$forceUpdate()
    },
    defineRoutes () {
      for (var count = 0; count < this.$store.state.customRouter.tripDays; count++) {
        if (this.$store.state.customRouter.days[count].routes.length === 0) {
          this.$store.commit('ADD_CUSTOM_ROUTE_DAY', {
            day: this.moment(this.$store.state.customRouter.initialDate).add(count, 'days').format('YYYY/MM/DD'),
            index: count
          })
        };
      }
    },
    previousStep () {
      this.step = this.step - 1
    },
    getLastActiveDayRoutes (step) {
      var routes = []
      for (var count = step; count >= 0; count--) {
        if (this.$store.state.customRouter.days[count].active) {
          routes = this.$store.state.customRouter.days[count].routes
          break
        }
      }
      return routes
    },
    async defineNextOriginPoint () {
      var step = this.$store.getters.customRouterStep

      var actualDayRoutes = this.getLastActiveDayRoutes(step)
      var actualDayLastRoute = this.lodash.cloneDeep(actualDayRoutes[actualDayRoutes.length - 1])

      var nextDayRoutes = this.$store.state.customRouter.days[step + 1].routes
      var nextDayFirstRoute = nextDayRoutes[0]

      if (nextDayRoutes.length > 0 && !this.lodash.isEqual(nextDayFirstRoute.origin, actualDayLastRoute.destination)) {
        this.$store.commit('SET_CUSTOM_ROUTER_DAY_PROP', {
          value: actualDayLastRoute.destination,
          prop: 'origin',
          route: 0,
          day: step + 2
        })
      }
    },
    validateStep (step) {
      var routes = this.$store.state.customRouter.days[step].routes
      var valid = true
      for (var count = 0; count < routes.length; count++) {
        if (
          count != (routes.length - 1) && !routes[count].hideDestination && routes[count].origin.address == '' || routes[count].destination.address == '' ||
                count == (routes.length - 1) && routes[count].destination.address == '') {
          valid = false
          break
        }
      }
      return valid
    },
    async customRouterNextStep () {
      var step = this.$store.getters.customRouterStep
      if (this.$store.state.customRouter.days[step].active && !this.validateStep(step)) {
        this.$swal({
          title: 'Erro',
          text: 'Você precisa preencher todos os campos antes de avançar',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Ok',
          icon: 'error'
        })
        return false
      };

      await this.defineNextOriginPoint()

      if ((this.routesStep + 1) > this.$store.state.customRouter.tripDays) {
        this.isLastStep = true
      } else {
        await this.$store.commit('CUSTOM_ROUTER_NEXT_STEP')
        this.routesStep = this.$store.state.customRouter.step
      }

      this.$forceUpdate()
    },
    async customRouterPrevStep () {
      if ((this.routesStep - 1) < -1) {
        this.step = 0
      } else {
        await this.$store.commit('CUSTOM_ROUTER_PREV_STEP')
        this.routesStep = this.$store.state.customRouter.step
      }

      this.$forceUpdate()
    }

  }
}

</script>

<style lang="scss">
    .step {
        position: relative;
    }
    .widget-visible {
        display: none !important;
    }

        
    .trip-information {
        .place-field, .user-field {
            border: 1px solid #ced4da;
            border-radius: 150px;
            padding: 8px 15px;
        }

    }

    .form-footer {
        .submit-action {
            @media (max-width: 1024px) {
                margin-bottom: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        &.customtrip {
            .actions {
                @media screen and (max-width: 1024px) {
                  width: 100%;

                  button {
                    flex: 1;
                  }
                }
                button {
                    margin-left: 10px;
                    border: none;
                    padding: 2px 20px;
                    border: 1px solid var(--default-color);
                    border-radius: 5px;
                    background: transparent;
                    color: var(--default-color);

                    &:hover {
                        background-color: var(--default-color);
                        color: white;
                    }
                }
            }

        }
        &.simple {
            padding-bottom: 20px;
            padding-right: 15px;
            margin-top: 10px;
            position: sticky;
            bottom: 0;
            background: white;
            padding-top: 10px;
            @media (max-width: 1024px) {
                position: relative;
            }
        }

        .addStopPoints {
            color: var(--default-color);
            font-size: 14px;

            &:hover {
                cursor: pointer;
                opacity: .7;
            }

        }

        .submit-form {
            background: var(--default-color)!important;
            color: white!important;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px;
            padding: 4px 50px!important;
            border-radius: 150px;
            border: none;

            &:hover {
                opacity: .8;
            }

            @media (max-width: 1024px) {
                padding: 10px 50px !important;
                width: 100%;
            }

        }
    }
    #custom-trip-route{
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999999;
        margin-bottom: -40vh;
        padding-top: 60px;

        
        .open-itinerary {
            position: fixed;
            right: 0;
            top: 20vh;
            background: var(--default-color);
            padding: 15px;
            z-index: 9999;
            font-size: 20px;
            color: white;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        @media (max-width: 1024px) {
            margin: 0;
        }

        .summary {
            transition: .8s ease-in-out;
            position: relative;
            right: -300vw;

            &.show-summary {
                right: 0;
                @media screen and (max-width: 1024px) {

                    right: -300vw;
                    height: 0;
                    position: fixed;
                    bottom: 0;
                    height: 85vh!important;

                    .route-summary {
                        height: 85vh;
                        max-height: inherit;
                        width: 100%;
                    }

                    &.active {
                        display: flex!important;
                        position: fixed;
                        z-index: 15;
                        width: 100vw!important;
                        flex-basis: 100%!important;
                        max-width: 100%!important;
                        bottom: 0;
                        right: 0;
                        height: 85vh;
                        max-height: inherit;
                    }

                }
            }
        }

        .container-fluid {
            max-height: 60vh;
            height: fit-content;
            padding: 0;
            transition: .5s ease-in-out;

            &.notLastStep {
                height: 100vh;
            }

            @media (max-width: 1024px) {
                max-height: inherit!important;
            }

        }

        * {
            font-family: 'Inter', sans-serif;
        }

        .form-control * {
            font-family: 'Poppins', sans-serif;
        }

        .pac-target-input {
            width: 100%;
        }

        .search-form {
            height: 100%;
            max-height: 60vh;
            overflow: auto;
            overflow-x: hidden;
            padding-bottom: 0;
            position: relative;

            &.customtrip {
                max-height: 60vh;
                height: 60vh;

                @media (max-width: 1024px) {
                    height: auto!important;
                    max-height: inherit !important;
                    padding-bottom: 20px;
                }
            }

            &::-webkit-scrollbar {
                width: 6px!important;
                height: 6px!important;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.184);
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            @media (max-width: 1024px) {
                height: auto;
                max-height: inherit!important;

                .place-field{
                    margin-bottom: 10px;
                }
            }

        }

        .route-steps {
            text-align: left;
            overflow-y: auto;
            overflow-x: hidden;
            height: 75%;
            padding-right: 15px;
            width: 100%;
            padding-top: 30px;

            &.trip-period {
                overflow-x: visible;
            }

            &::-webkit-scrollbar {
                width: 6px!important;
                height: 6px!important;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.184);
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            .heading {
                h2 {
                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 10px;
                }
            }

            .step {
                .heading {
                    h4 {
                        font-size: 18px;
                        margin-bottom: 20px;
                        font-family: 'Inter',sans-serif;
                        font-weight: 600;
                    }
                }

                .remove-stop-point {
                    position: absolute;
                    right: 30px;
                    top: 13px;

                    &:hover {
                        cursor: pointer;
                        opacity: .7;
                    }

                    * {
                        fill: var(--default-color)
                    }
                }
            }
        }

        .route-summary {
            background: white;
            text-align: left;
            border-radius: 8px;
            height: 100%;
            height: 60vh;
            overflow: auto;
            border-radius: 5px;
            padding-right: 5px;
            border: 2px solid #d8d8d8;

            .no-routes {
                height: 80%;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #aaaaaa;
            }

            &::-webkit-scrollbar {
                width: 6px!important;
                height: 6px!important;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.184);
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            .heading  {
                h3 {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 10px;
                    color: var(--default-color);
                }
                h4 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-right: 10px;
                    color: #3D3D3D;
                }
                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #686868;
                }
            }
        }
        #footer  {
            position: relative;
            bottom: 0;
            width: 100%;
            left: 0;
            height: calc(10vh);
            background: #ebebeb;
            padding: 0;
            display: none;

            .actions {
                gap: 10px;

                .action {
                    height: 30px;
                    text-align: center;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    background: #2742a3;
                    color: white;

                    &.chat {
                        position: relative;
                        background: #a3a3a3;

                        .notification {
                            position: absolute;
                            top: -5px;
                            right: -5px;
                            background: #a32727;
                            border-radius: 50%;
                            width: 20px;
                            height: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 10px;
                            color: white;
                        }
                    }

                    button {
                        background: none!important;
                        border: none!important;
                        color: white;
                    }
                }
            }

        }

    }
</style>
