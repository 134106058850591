import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faUser, faBusAlt, faMapSigns, faUpload, faLock, faAt, faPlus, faStar, faMinus, faLongArrowAltLeft, faTimes, faLongArrowAltRight, faFileInvoiceDollar, faTimesCircle,
  faLongArrowAltDown, faLongArrowAltUp, faCloudDownloadAlt, faAngleUp, faAngleDown, faCreditCard, faBarcode, faClone, faShoppingCart, faComments } from '@fortawesome/free-solid-svg-icons'
import { faClock, faCalendarCheck, faCircle, faCopy } from '@fortawesome/free-regular-svg-icons'

library.add(
  faCloudDownloadAlt,
  faCopy,
  faUser,
  faCircle,
  faTimes,
  faBusAlt,
  faClock,
  faCalendarCheck,
  faMapSigns,
  faUpload,
  faLock,
  faAt,
  faPlus,
  faStar,
  faMinus,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faFileInvoiceDollar,
  faTimesCircle,
  faAngleUp,
  faAngleDown,
  faCreditCard,
  faBarcode,
  faClone,
  faShoppingCart,
  faComments
)

Vue.component('font-awesome-icon', FontAwesomeIcon) // registered globally
