<template>
  <div class="use-vehicle-in-destination"  ref="use-vehicle-in-destination" :style="cssProps">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 destination">
            <b-input-group>
              <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"> <b-icon-geo-alt></b-icon-geo-alt></b-input-group-text>
               <div>
               <places
                        placeholder="Rua, número, cidade"
                        classname="form-control input-feedback"
                        :index="index"
                        :value="$store.state.tripDetails.useVehicle[index].addr_info.address"
                        :showDeleteButton="false"
                        @send-message="setAddress"
                        ></places>
                </div>
            </b-input-group>
          </div>
          <b-form-group class="col-md-6 date">
            <b-input-group>
              <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><b-icon-calendar3></b-icon-calendar3></b-input-group-text>
              <DatePicker
                @selected="setDate($event, 'date')"
                name="destinationDate"
                ref="destinationDate"
                class="form-control"
                placeholder="Data"
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                :value="$store.state.tripDetails.useVehicle[index].date"
                :open-date="$store.state.origin.date"
              >
              </DatePicker>
            </b-input-group>
          </b-form-group>
          <b-form-group class="col-md-6 time">
            <b-input-group>
              <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><b-icon-clock></b-icon-clock></b-input-group-text>
              <time-select-field
                ref="destinationTime"
                v-on:updateschedule="setTime($event, 'time')"
                placeholder="Horário"
                :value="$store.state.tripDetails.useVehicle[index].time"
                classes="form-control"
                :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"
              >
              </time-select-field>
            </b-input-group>
          </b-form-group>
          <b-form-group class="col-md-5 usage">
            <b-input-group>
              <!-- <b-input-group-text slot="prepend" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }"><font-awesome-icon :icon="['far', 'clock']"></font-awesome-icon></b-input-group-text> -->
              <usage-quotes @change="getSelectedQuote" :selectedQuote="$store.state.tripDetails.useVehicle[index].quote" :style="{ backgroundColor: formBackgroundColor, borderColor: formBackgroundColor, color: formFontColor }" />
            </b-input-group>
          </b-form-group>
          <b-col md="1">
            <button type="submit" class="remove-usage" @click.prevent="deleteUseAtDestination">
              <font-awesome-icon class="preventCloseModal" :icon="['fas','times']"></font-awesome-icon>
            </button>
          </b-col>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import DatePicker from '@sum.cumo/vue-datepicker'
import UsageQuotes from '@/components/fields/UsageQuotesField.vue'
import Places from '@/components/general/Places.vue'
import TimeSelectField from '@/components/fields/TimeSelectField.vue'

export default {
  name: 'UseVehicleDestination',
  props: ['info_data', 'index'],
  data () {
    return {
      selectedQuote: 0,
      time: ''
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    formFontColor () {
      return this.$store.state.companyDetails.whitelabel.form_font_color
    },
    formBackgroundColor () {
      return this.$store.state.companyDetails.whitelabel.form_background_color
    },
    disabledDates () {
      return { to: new Date(this.$store.state.origin.date), from: new Date(this.$store.state.destination.date) }
    }
  },
  mounted () {
    let quotes = this.$store.state.companyDetails.vehicleUsageQuotes
    let franchise = quotes[this.selectedQuote].hours + ' Horas e ' + quotes[this.selectedQuote].km + ' Quilômetros'
  },
  components: { DatePicker, UsageQuotes, Places, TimeSelectField },
  methods: {
    getSelectedQuote: function (response) {
      this.selectedQuote = response.value
      this.$store.commit('SET_USAGE_DESTINATION_PROP', {
        prop: 'quote', index: this.index, quote: response.value
      })

      this.$store.commit('SET_USAGE_DESTINATION_PROP', {
        prop: 'franchise', index: this.index, franchise: response.franchise
      })

      this.$store.commit('SET_USAGE_DESTINATION_PROP', {
        prop: 'selectedIndex', index: this.index, selectedIndex: response.selectedIndex
      })
    },
    setDate: function (date, prop) {
      date = this.moment(date).format('YYYY/MM/DD')
      this.$store.commit('SET_USAGE_DESTINATION_PROP', {
        prop: 'date', date: date, index: this.index
      })
    },
    deleteUseAtDestination: function () {
      this.$emit('delete', this.index)
    },
    setTime: function (time, prop) {
      this.$store.commit('SET_USAGE_DESTINATION_PROP', {
        prop: prop, index: this.index, time: time
      })
    },
    setAddress: function (addressData, addressObject) {
      this.$store.commit('SET_USAGE_DESTINATION_PROP', {
        prop: 'addr_info',
        index: this.index,
        addr_info: {
          address: addressObject.formatted_address,
          city: addressObject.address_components[2].long_name,
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .use-vehicle-destination {
    z-index: 2;
  }

  .remove-usage {
    width: 100%; 
    height: 46px; 
    background-color: transparent!important; 
    border:none!important;
    color: var(--default-color); 
    line-height: 35px; 
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;;

  }
  .price-wrapper {
    text-align: right;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .price {
      font-weight: bold;
      text-align: right;

      @media (max-width: 980px) {
        text-align: center;
      }

      .value {
        font-size: 18px;
        margin-left: 3px;
        font-weight: 500;
      }
    }
  }

  .input-group-text {
    color: #fff;
  }

  .mark.active {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 32px;
    background-color: #4ca92b;
    color: #fff;
    border-radius: 100px;
    text-transform: uppercase;
  }
</style>
