<template>
    <div :style="cssProps">

        <carousel class="dayStepper"
            :autoplay="false"
            :perPageCustom="[[522, 2], [768, 3], [1024, 5]]"
            :navigationEnabled="true"
            navigationNextLabel="▶"
            navigationPrevLabel="◀"
            :paginationEnabled="false"
        >
            <slide class="day-step" v-for="day in $store.state.customRouter.tripDays">
                <div class="day-step-content"
                    :class="(day - 1) == $store.getters.customRouterStep ? 'active' : ''"
                    @click="changeStep(day - 1)"
                >
                        {{ $store.state.customRouter.days[day - 1].date}}
                </div>
            </slide>
        </carousel>

        <div v-for="day in $store.state.customRouter.tripDays">
            <div class="day-trip-routes"
            :class="[checkStatusStep(day) ? 'active' : 'inactive',
            $store.state.customRouter.days[$store.getters.customRouterStep].active ? 'in-use' : 'not-in-use']"
            >
                <div class="heading mb-3">
                    <!-- <h4>O que você vai fazer no dia {{$store.state.customRouter.days[$store.getters.customRouterStep].date}}</h4> -->

                    <div v-if="day != 1 && day != $store.state.customRouter.tripDays" class="my-3 vehicle-use">
                        <div class="col-12">
                          <div class="row align-items-center">
                            <h6 class="title mb-0 mr-2">Você vai utilizar o veículo neste dia?</h6>
                            <span class="mr-2">Não</span>
                            <b-form-checkbox
                                @change="updateStatus"
                                :checked="$store.state.customRouter.days[$store.getters.customRouterStep].active"
                                :v-model="$store.state.customRouter.days[$store.getters.customRouterStep].active" name="status" switch>

                            </b-form-checkbox>
                            <span>Sim</span>
                          </div>
                        </div>

                    </div>
                </div>
                <b-row class="route-content mt-4">
                    <div class="not-use-vehicle-tag" v-if="!$store.state.customRouter.days[$store.getters.customRouterStep].active">
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <h4 class="text-center">
                                O veículo permanecerá parado em: <b>{{ lastRouteAddress() }}</b> neste dia.
                            </h4>
                        </div>
                    </div>
                    <b-col cols="12"
                        class="position-relative"
                        :class="[
                            true ? 'position-relative' : '',
                            $store.state.customRouter.days[$store.getters.customRouterStep].routes.length > 1 ? 'route-item--multiple' : '',
                        ]">
                        <div
                        class="route-item" v-for="(route, index) in $store.state.customRouter.days[$store.getters.customRouterStep].routes" :key="index">
                            <new-route
                            :updateValue="() => updateValue()" :key="randomKey" :index="index" :data="route" :day="day"></new-route>
                        </div>
                    </b-col>
                    <b-col lg="9" cols="12" offset-lg="3" class="new-route mb-3 mb-lg-0">
                        <div class="d-flex align-items-center input-feedback">
                            <button class="w-100" @click.prevent="addNewRoute"><b-icon-plus class="mr-2"></b-icon-plus> Adicionar novo destino</button>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from '@sum.cumo/vue-datepicker'
import NewRoute from '../NewRoute.vue'
export default {
  name: 'StepTwo',
  props: ['data', 'updateStep'],
  components: { DatePicker, NewRoute },

  data () {
    return {
      dayData: this.$store.state.customRouter.days[this.$store.getters.customRouterStep],
      randomKey: Math.round(Math.random() * 1000)
    }
  },
  async created () {
    // for( var count = 0; count < this.tripDays(); count++ ){
    //     // if(this.$store.state.customRouter.days[count].routes.length !== 0) return;
    //     await this.$store.commit('ADD_CUSTOM_ROUTE_DAY', {
    //         day: this.moment(this.$store.state.customRouter.initialDate).add(count, 'days').format('DD/MM/YYYY'),
    //         index: count
    //     })
    // }

    await this.$store.commit('ADD_CUSTOM_ROUTE_DAY', this.tripDays())

    this.$store.commit('SET_CUSTOM_ROUTER_PROP', {
      value: this.tripDays(),
      prop: 'tripDays'
    })
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  },
  methods: {
    updateValue () {
      this.$store.commit('CUSTOM_ROUTER_UPDATE_STEP')
      this.randomKey = Math.round(Math.random() * 1000)
      this.updateStep()
    },
    async changeStep (step) {
      var currentStep = this.$store.getters.customRouterStep

      if (step > currentStep && this.$store.state.customRouter.days[currentStep].active && !this.validateStep(currentStep)) {
        this.$swal({
          title: 'Erro',
          text: 'Você precisa preencher todos os campos antes de avançar',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Ok',
          icon: 'error'
        })
        return false
      } else {
        await this.defineNextOriginPoint(step)
        this.$store.commit('SET_CUSTOM_ROUTER_STEP', step)
      }
    },
    checkStatusStep (day) {
      return (day - 1) == this.$store.getters.customRouterStep
    },
    lastRouteAddress () {
      var step = this.$store.getters.customRouterStep
      var lastRoutes = this.getLastActiveDayRoutes(step)
      var result = lastRoutes[lastRoutes.length - 1].destination.name
      return result
    },
    updateStatus (status) {
      var data = {
        status: status,
        index: this.$store.getters.customRouterStep
      }
      this.$store.commit('UPDATE_CUSTOM_ROUTE_DAY_STATUS', data)
      // this.$store.commit('RECALCULATE_CUSTOM_ROUTER_DAYS');
    },
    getStatus (status) {
      return status == 'active'
    },
    validateStep (step) {
      var routes = this.$store.state.customRouter.days[step].routes
      var valid = true
      for (var count = 0; count < routes.length; count++) {
        if (
          count != (routes.length - 1) && !routes[count].hideDestination && routes[count].origin.address == '' || routes[count].destination.address == '' ||
                    count == (routes.length - 1) && routes[count].destination.address == '') {
          valid = false
          break
        }
      }
      return valid
    },
    async defineNextOriginPoint (actualStep) {
      if (actualStep == 0) return
      var step = this.$store.getters.customRouterStep

      var actualDayRoutes = this.getLastActiveDayRoutes(step)
      var actualDayLastRoute = this.lodash.cloneDeep(actualDayRoutes[actualDayRoutes.length - 1])

      var nextDayRoutes = this.$store.state.customRouter.days[step + 1].routes
      var nextDayFirstRoute = nextDayRoutes[0]

      if (nextDayRoutes.length > 0 && !this.lodash.isEqual(nextDayFirstRoute.origin, actualDayLastRoute.destination)) {
        this.$store.commit('SET_CUSTOM_ROUTER_DAY_PROP', {
          value: actualDayLastRoute.destination,
          prop: 'origin',
          route: 0,
          day: step + 2
        })
      }
    },
    getLastActiveDayRoutes (step) {
      var routes = []
      for (var count = step; count >= 0; count--) {
        if (this.$store.state.customRouter.days[count].active) {
          routes = this.$store.state.customRouter.days[count].routes
          break
        }
      }
      return routes
    },
    async addNewRoute () {
      var step = this.$store.getters.customRouterStep

      var routes = this.$store.state.customRouter.days[step].routes
      var valid = true
      for (var count = 0; count < routes.length; count++) {
        if (
          count != (routes.length - 1) && !routes[count].hideDestination && routes[count].origin.address == '' || routes[count].destination.address == '' ||
                    count == (routes.length - 1) && routes[count].destination.address == '') {
          this.$swal({
            title: 'Erro',
            text: 'Todos os campos são obrigatórios',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',
            icon: 'error'
          })
          return
        }
      }

      var actualDayRoutes = this.$store.state.customRouter.days[step].routes
      var actualDayLastRoute = this.lodash.cloneDeep(actualDayRoutes[actualDayRoutes.length - 1])

      var isFirstRoute = actualDayRoutes.length === 1

      this.$store.commit('HIDE_DESTINATION', {
        route: actualDayRoutes.length - 1,
        day: step + 1
      })

      await this.$store.commit('ADD_CUSTOM_ROUTE', {
        day: this.$store.getters.customRouterStep
      })

      this.$store.commit('SET_CUSTOM_ROUTER_DAY_PROP', {
        value: actualDayLastRoute.destination,
        prop: 'origin',
        route: actualDayRoutes.length - 1,
        day: step + 1
      })

      if (isFirstRoute) {
        this.$store.commit('SET_CUSTOM_ROUTER_DAY_PROP', {
          value: actualDayLastRoute.destination,
          prop: 'destination',
          route: actualDayRoutes.length - 1,
          day: step + 1
        })

        // await this.$store.commit('ADD_CUSTOM_ROUTE', {
        //     day: this.$store.getters.customRouterStep
        // })
      }

      this.$store.commit('CUSTOM_ROUTER_UPDATE_STEP')

      this.updateStep()

      if (isFirstRoute) {
        this.$store.commit('HIDE_DESTINATION', {
          route: actualDayRoutes.length - 1,
          day: step + 1
        })

        await this.$store.commit('ADD_CUSTOM_ROUTE', {
          day: this.$store.getters.customRouterStep
        })

        step = this.$store.getters.customRouterStep
        var lastDestination = actualDayLastRoute.destination
        actualDayRoutes = this.$store.state.customRouter.days[step].routes

        this.$store.commit('SET_CUSTOM_ROUTER_DAY_PROP', {
          value: lastDestination,
          prop: 'origin',
          route: actualDayRoutes.length - 1,
          day: step + 1
        })

        // this.updateStep();
      }
    },
    tripDays () {
      let days = 0
      let start = this.moment(this.$store.state.customRouter.initialDate)
      let end = this.moment(this.$store.state.customRouter.endDate)
      days = end.diff(start, 'days') + 1

      return days
    }
  }
}
</script>

<style lang="scss" scoped>
.stepper {
    height: 50px;
}

.dayStepper {
    display: flex;
    margin-bottom: 30px;
    .day-step-content {
        background: #ffffff;
        padding: 4px 20px;
        border-radius: 4px;
        font-size: 13px;
        border: 1px solid #d1d1d1;
        margin-right: 2px;
        text-align: center;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
            color: var(--default-color) !important;
            border-top: 3px solid;
            font-weight: 500;
        }

        &:hover {
            background: #ffffff!important;
            color: var(--default-color)!important;
            border-color: var(--default-color)!important;
            cursor: pointer;
        }
    }
}
.step {
    position: relative;

    .destination-address {
        &.hide-destination {
            display: none;
        }
    }
    .day-trip-routes {
        position: absolute;
        width: 100%;
        padding-right: 0px;
        transition: .8s ease-in-out;
        margin-top: 50px;

        .vehicle-use {

            span {
                font-size: 12px;
                text-transform: lowercase;
                color: #939393;
            }
            .title {
                font-weight: 500;
                font-size: 14px;
                color: var(--default-color);
            }
        }

        .new-route {
            button {
                border: none;
                text-align: left;
                background: none;
                font-size: 14px;
                color: var(--default-color);
            }
        }
        &.active {
            top: 0;
            left: 0;
            @media screen and (max-width: 1024px) {
                position: relative;
            }
        }
        &.inactive {
            top: 0;
            left: -200vw;
        }

        &.not-in-use {
            .route-content {
                position: relative;

                .not-use-vehicle-tag  {
                    z-index: 9999999;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    b {
                        font-weight: 800;
                    }

                    h4{
                        background: var(--default-color);
                        padding: 5px 30px;
                        font-size: 16px;
                        border-radius: 30px;
                        color: white;
                        font-weight: 300;
                    }
                }

                &:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    content: '';
                    height: 100%;
                    background: #f7f7f9;
                    z-index: 99999;
                    opacity: .7;
                    cursor: not-allowed;
                }
            }
        }
    }
}
 .outline-btn {
    border-color: var(--default-color);
    color: var(--default-color);
    background-color: transparent;
    font-size: 12px;
    height: 35px;
    font-weight: bold;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0;
    margin-top: 1px;
    border-radius: 10px;
  }
  .outline-btn:hover {
    background-color: var(--default-color);
    color: white!important;
    opacity: 1;
  }

</style>
