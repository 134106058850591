import Vue from 'vue'
import uuidv4 from '../helpers/uuid'

function getVehicleType (type) {
  switch (type) {
    case 'onibus':
      return 'bus'
    case 'van':
      return 'van'
    case 'micro-onibus':
      return 'micro'
    case 'carro' :
      return 'car'
    default:
      return 'bus'
  }
};

function getVehicleTotalPrice (details) {
  let subtotal = 0;
  let extraCost = 0;
  let trip = details.data;
  let vehicle = details.data.selectedVehicle;

  subtotal = parseFloat(vehicle.priceCents)
  
  return {
    subtotal: subtotal,
    total: subtotal + extraCost
  }
}

function calculateVehicleUsageTotals(vehicleType, trip, usage) {
  var total = 0;
  if(usage) {
    Object.entries(trip.useVehicle).forEach(entry => {
      const [key, value] = entry;
      total += parseFloat(usage[value.selectedIndex][vehicleType])
    });
  }
  return total;
}

export default {
  namespaced: true,

  state: {
    quoteList: [],
    quote: {
      coupon: null,
      customer: [],
      discount: null,
      installments: null,
      items: [],
      payment_method: 'bank_slip',
      subtotal: 0,
      total: 0,
      tripDetails: {},
      uuid: ''
    }
  },

  getters: {
    response: state => state.response
  },
  mutations: {

      calculateQuoteTotals (state) {
        let subtotal = 0;
        let extraCost = 0;
       
        state.quote.items.forEach((el, index) => {
          let trip = el.tripDetails;
          let vehicle = el.selectedVehicle;
          if (el.type === 'customtrip') {
            // extraCost += calculateVehicleUsageTotals( vehicle.type, el.tripDetails, details.data.companyDetails.vehicleUsageQuotes ) + trip.driverExpenses.driverExpensesValue;
            subtotal += parseFloat(el.selectedVehicle.priceCents)
          } else {
            subtotal += parseFloat(el.selectedVehicle.priceCents)
          }
  
          if(el.driverExpenses.enable){
            extraCost += parseFloat(el.driverExpenses.driverExpensesValue);
          }
        })
  
        state.quote.subtotal = subtotal
        state.quote.total = subtotal + extraCost - state.quote.discount
    },
    setQuoteItem (state, details) {
      let lastUUID = uuidv4()
      let quoteItem = details.data
      let extraCost = quoteItem.type === 'viagem' ? quoteItem.tripDetails.driverExpenses.driverExpensesValue : 0
      let priceTotals = getVehicleTotalPrice(details)
      let quoteDiscounts = state.quote.discount ? state.quote.discount : 0
      quoteItem['subtotal'] = priceTotals.subtotal - quoteDiscounts
      quoteItem['total'] = priceTotals.total
      quoteItem['uuid'] = lastUUID
      state.quote['uuid'] = lastUUID
      state.quote.items['vehicle_' + details.index] = quoteItem
      Vue.set(state.quote.items, details.index, quoteItem)
      this.commit('quote/addQuoteItemList', quoteItem)
      this.commit('quote/calculateQuoteTotals', {}) // Passivel de refatoração
      this.commit('quote/cleanEmptyItems', {})
    },
    cleanEmptyItems(state, data){
      state.quote.items = Vue.lodash.filter(state.quote.items, Vue.lodash.size);
    },
    resetQuoteItems (state , data){
      state.quote.items = []
      state.quote.subtotal = 0
      state.quote.total = 0
    },
    addQuoteItemList (state, data) {
      if (state.quoteList.length === 0 || Object.keys(state.quoteList).length === 0) {
        state.quoteList = []
      }
      state.quoteList.push(data)
    },
    removeQuoteItemList (state, data) {
      state.quoteList.splice(data, 1)
    },
    clear (state, data) {
      state.quote.items = []
    }
  }
}
