<template>
  <div class="home" id="home" :style="cssProps">
    <div class="col-12 p-0">

      <div v-if="this.$store.state.companyDetails.company_group != null && this.$store.state.companyDetails.company_group.length > 0 && !this.$store.state.hasCompanySelected">
        <company-select :style="{}" :show="!showCompanySelect" :showRegister="false" @close="closeModal" />
      </div>

      <div class=""
        :class="{
        'masked' : Boolean(parseInt(this.$store.state.companyDetails.whitelabel.default_banner_mask)),
        'form-wrapper pt-5 pb-5' : true
        }"
        :style="homeBackground">

        <div class="container">
          <h1 class="welcome_message" v-html="this.$store.state.companyDetails.whitelabel.welcome_message">
          </h1>
          <div class="row flex-column flex-sm-row">
            <!-- <div class="col-12 col-lg-4">
              <div class="cta-wrapper">
                <div class="cta-title d-flex justify-content-end">
                  <h2 class="section-title" :style="sectionTitle" v-html="this.$store.state.companyDetails.whitelabel.welcome_message"></h2>
                </div>
              </div>
            </div> -->

            <div class="col-12">
              <custom-trip-route :key="randomKey" :update="this.updateStep"></custom-trip-route>
              <!-- <search-form></search-form> -->
            </div>

            </div>
          </div>
      </div>

      <div class="container">
        <div class="certificates-wrapper default-bordered secondary mb-5">
           <certificates />
        </div>
      </div>

      <div v-if="this.$store.state.companyDetails.company_group != null && this.$store.state.companyDetails.company_group.length > 0">
        <div class="container">
          <div class="default-bordered secondary mb-5">
            <div class="heading text-left">
              <h3>{{ this.$store.state.companyDetails.company_group_title}}</h3>
              <p>{{ this.$store.state.companyDetails.company_group_description}}</p>
            </div>
            <div class="company-list p-0 text-left">
              <li v-for="garage in this.$store.state.companyDetails.company_group">
                <a :href="`${garage.url}?company=${slugifyText($store.state.companyDetails.fantasyName)}`">{{ garage.name }}</a>
              </li>
            </div>
          </div>
        </div>
      </div>
   
    </div>
  </div>
</template>

<script>
import SearchForm from '@/components/general/SearchFormWrapper.vue'
import Certificates from '@/components/general/Certificates.vue'
import CustomTripRoute from '../components/modals/CustomTripRoute/CustomTripRoute.vue'
import CompanySelect from '../components/modals/CompanySelect.vue'

export default {
  name: 'WhiteLabel',
  props: ['theme'],
  data: function () {
    return {
      showCompanySelect: false,
      randomKey: Math.round(Math.random() * 1000),
      pageTitle: `${this.$store.state.companyDetails.name}` || 'Motor de reservas',
      homeBackground: {
        backgroundImage: this.$store.state.companyDetails.whitelabel.banner_img.length > 0 ? `url(${this.$store.state.companyDetails.whitelabel.banner_img})` : `url(${require('@/assets/img/homebg.jpg')})`
      },
      sectionTitle: {
        backgroundColor: `${this.$store.state.companyDetails.whitelabel.body_font_color}ad`
      },
      origin: {
        address: '',
        city: '',
        lat: 0,
        lng: 0,
        date: '',
        time: ''
      },
      destination: {
        address: '',
        city: '',
        lat: 0,
        lng: 0,
        date: '',
        time: ''

      }
    }
  },
  mounted () {
    
    if (!this.theme) {
      this.$store.commit('resetState', {})

      let result = new Date()

      result.setDate(result.getDate() + Number(this.$store.state.companyDetails.reservationStartAt))

      this.$store.commit('setReservationDateParsed', result)
      this.$store.commit('setOriginDisabledDates', result)
      this.$store.commit('setDestinationDisabledDates', result)
    }

    if(this.$store.state.companyDetails.company_group && !this.$store.state.companyGroup.options.length > 0 && !this.$store.state.companyGroup.selected){
      this.$store.commit('UPDATE_COMPANY_GROUP', {prop: 'isGroup', value: true})
      this.$store.commit('UPDATE_COMPANY_GROUP', {prop: 'options', value: this.$store.state.companyDetails.company_group})
    }

    if(this.$store.state.companyGroup.isGroup && !this.$store.state.companyGroup.selected){
      var query = this.getQueryParams(document.location.search)
      if(!query.company){
        this.showCompanySelect = true
      }
    }
  },
  computed: {

    cssProps () {
      return {
        '--default-color':  this.$store.state.companyDetails.whitelabel.body_font_color,
      }
    },
 
    resetForm () {
      let resetSearch = {}

      this.$store.commit('SET_PLACE', resetSearch)

      return resetSearch
    },
    backgroundColor () {
      return this.$store.state.companyDetails.whitelabel.body_background_color || ''
    },
    bodyFontColor () {
      return this.$store.state.companyDetails.whitelabel.body_font_color || ''
    }
  },
  methods: {
    
    slugifyText(text){
      return String(text)
      .normalize('NFKD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
    },
    updateStep () {
      this.randomKey = Math.round(Math.random() * 1000)
    },
    tawkToEvent (event, data) {
      if (this.$store.state.companyDetails.whitelabel.chats.tawkto !== null) {
        window.Tawk_API.addEvent(event, data, function () {
        })
      }
    },
    getQueryParams (qs) {
      qs = qs.split('+').join(' ')

      var params = {}
      var tokens
      var re = /[?&]?([^=]+)=([^&]*)/g

      while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2])
      }
      return params
    },
    closeModal () {
      this.showCompanySelect = false
    },
  },
  components: {
    SearchForm,
    Certificates,
    CompanySelect,
    CustomTripRoute
  }
}
</script>

<style lang="scss">

#home {

  .heading {
    h3 {
      font-size: 20px;
      font-family: 'Inter', sans-serif;
    }

    p {
      font-size: 12px;
      font-family: 'Inter', sans-serif;
    }
  }

  .company-list {
    li {
      list-style: none;
      margin-bottom: 15px;
      a {
        color: var(--default-color) !important;
        border: 1px solid var(--default-color) !important;
        border-radius: 30px;
        padding: 2px 10px;
        font-size: 14px;

        &:hover {
          background-color: var(--default-color) !important;
          color: #fff !important;
          text-decoration: none;
        }
      }
    }
  }
  .welcome_message {
    color: white;
    z-index: 9;
    position: relative;
    font-size: 34px;
    width: 50%;
    margin: 0 auto;

    @media (max-width: 980px) {
      width: 100%;
      font-size: 28px;
    }

  }
}

  .form-wrapper {
    background-size: cover;
    min-height: calc(80vh - 85px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;
    margin-bottom: 40vh;

    @media(max-width: 990px) {
      padding: 0 10px;
      max-width: 100%;
      overflow: hidden;
      min-height: 100vh;
      margin-bottom: 0;
    }
  }

   @import "../assets/scss/pages/_white-label.scss";

</style>
